.vacancy-index-province {

    &__province-list {
        list-style-type: none;
    }

    &__province {
        color: var(--c-grey-dark);
        line-height: var(--vacancy-index-line-height);
    }
}
