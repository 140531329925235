.error-page-content {

    &__loader-wrapper {
        display: flex;
        justify-content: center;
    }

    &__title {
        font-size: 5rem;
    }

    &__description {
        font-size: 1.5rem;
        color: var(--c-grey-dark);
    }

    &__button {
        margin-top: 2.5rem;
    }
}
