.application-match-card {

    &__description {
        margin-top: 1rem;
    }

    &__button {
        margin-top: 1.5rem;
    }
}
