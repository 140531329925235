.vacancy-applicants-filter {
    padding: 1.5rem;
    background-color: var(--c-white);

    &__selected-vacancy-skeleton {
        max-width: 24rem;
        font-size: 1.125rem;
    }

    &__selected-vacancy {
        font-size: 1.125rem;
        color: var(--c-grey-dark);
    }

    &__vacancy-input {
        max-width: 36rem;
        margin-top: 1rem;
    }

    &__vacancy-input-results {
        position: absolute;
        z-index: 10;
        width: 100%;
        border-color: var(--c-grey-light);
        background-color: var(--c-white);
        box-shadow: var(--shadow-medium);
    }
}
