@import "src/styles";

.application-detail {

    &__wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__main {
        width: 100%;
    }

    &__sidebar {
        width: 100%;
        margin-top: 2rem;

        @include for-size(tablet-landscape-up) {
            max-width: 24rem;
            margin-top: 0;
            margin-left: 2rem;
        }
    }

    &__card {

        & + & {
            margin-top: 2rem;
        }
    }
}
