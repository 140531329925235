.blog-detail {
    --blog-detail-wrapper-width: 58rem;
    --blog-detail-wrapper-width-wide: 74rem;

    padding-bottom: 0;
    background-color: var(--c-white);

    &__related-blog-articles {
        margin-top: 6rem;
    }

    &__registration-call-to-action {
        margin-top: 6rem;
    }
}
