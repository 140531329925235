@import "src/styles";

.skills-card {
    line-height: 1.5;

    &__skeleton,
    &__null-state {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    &__null-state-button {
        @extend %button--add;

        margin-top: 2rem;
    }

    &__tag-list {
        display: flex;
        flex-wrap: wrap;
        gap: var(--flex-tag-gap);
        margin-top: 1.5rem;
    }

    &__tag-item {
        list-style-type: none;
    }

    &__modal-card {
        display: flex;
        flex-direction: column;
        max-width: 64rem;
        padding: 0;

        @include for-size(tablet-landscape-up) {
            flex-direction: row-reverse;
        }
    }

    &__form-wrapper {
        width: 100%;
        padding: 2.5rem;
    }

    &__form-title {
        padding-right: 2rem;
        font-size: 1.5rem;
        color: var(--c-primary);
    }

    &__form {
        margin-top: 2rem;
    }

    &__form-tips {
        width: 100%;

        @include for-size(tablet-landscape-up) {
            max-width: 20rem;
        }
    }
}
