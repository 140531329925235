@import "src/styles";

.input {
    width: 100%;
    padding: 0 1rem;
    font-size: 1rem;
    line-height: 2.75;
    color: var(--c-text);
    border: 1px solid var(--c-grey-light);
    box-shadow: var(--shadow-medium);
    background-color: var(--c-white);

    &[type="search"] {
        -webkit-appearance: none;
    }

    @include dark-mode {
        border: none;
        background-color: var(--c-background);
    }

    &:focus {
        box-shadow: none;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &::placeholder {
        color: var(--c-grey);
    }

    &--has-error {
        border: 2px solid var(--c-negative);
    }

    &--number {
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        &[type=number] {
            -moz-appearance: textfield;
        }
    }
}
