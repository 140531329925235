@import "src/styles";

.image-cropper {
    width: 100%;

    &__cropper {
        width: 100%;
        background: var(--c-grey-light);
    }

    &__control-buttons {
        padding: 1rem;
        background-color: var(--c-primary);
    }

    &__control-button {
        background-color: var(--c-grey-light);

        &--crop {
            background-color: var(--c-secondary);
        }
    }

    &__result-wrapper {
        @extend %flex-align-center;

        flex-direction: column;
        margin-top: 3rem;
    }

    &__result-title {
        font-size: 1.125rem;
        line-height: 1.5;
    }

    &__result {
        min-width: 4rem;
        max-width: 48rem;
        min-height: 4rem;
        max-height: 24rem;
        object-fit: contain;
        margin-top: 1rem;
        box-shadow: var(--shadow-medium);
    }

    &__action-buttons {
        margin-top: 4rem;
    }

    &__cancel-button {
        @extend %button--transparent-background;

        color: var(--c-text);
    }
}
