.application-form {
    line-height: 1.5;

    &__title {
        padding-right: 2rem;
        font-size: 1.5rem;
        color: var(--c-primary);
    }

    &__subtitle {
        font-size: 1rem;
        color: var(--c-grey-dark);
    }

    &__input-wrapper,
    &__salutation-wrapper,
    &__greeting-wrapper,
    &__attachments-wrapper {
        margin-top: 2rem;
    }

    &__salutation-wrapper {
        display: flex;
        justify-content: space-between;
    }

    &__author {
        margin-top: 1rem;
    }

    &__action-buttons {
        position: relative;
        z-index: 10;
        margin-top: 4rem;
    }
}
