@import "src/styles";

$component: 'competency-option';

.#{$component} {
    display: flex;
    position: relative;
    padding: 1rem;
    line-height: 1.5;

    &__checkbox {
        display: block;
        transform: translateY(.125rem);

        &:focus-within ~ .#{$component}__text-wrapper .#{$component}__name {
            text-decoration: underline;
        }
    }

    &__checkbox-button {
        font-size: 0;
        padding: 0;
        opacity: 0;

        &::after {
            @extend %click-extend;

            z-index: 10;
            content: "";
        }
    }

    &__text-wrapper {
        margin-left: 1rem;
    }

    &__name {
        font-size: 1rem;
    }

    &__description {
        margin-top: .5rem;
        color: var(--c-grey-dark);
    }

    &__information-button {
        @extend %button--transparent-background;

        position: relative;
        z-index: 20;
        margin-top: 1rem;
        padding: .5rem 1rem;
        color: var(--c-link);
    }
}
