.toast-list {
    position: fixed;
    z-index: 30;
    pointer-events: none;

    &__list {
        margin: 0;
        padding: 0;
    }

    &__item {
        list-style-type: none;

        & + & {
            margin-top: 1.5rem;
        }
    }

    &__toast {
        position: relative;
        pointer-events: all;
    }
}
