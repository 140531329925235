.vacancy-sbb-fields-of-study {

    &__title-wrapper {
        display: flex;
        align-items: center;

        &::after {
            content: "";
            flex: 1;
            display: block;
            height: 1px;
            background-color: var(--c-grey-light);
        }
    }

    &__title {
        margin-right: 1rem;
        font-size: 1rem;
        font-weight: var(--fw-bold);
        color: var(--c-grey-dark);
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: var(--flex-tag-gap);
        margin-top: 1rem;
    }

    &__item {
        list-style-type: none;
    }
}
