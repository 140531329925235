@import "src/styles";

.vacancy-matches-filter-mobile {
    font-size: .875rem;

    &__input {

        & + & {
            margin-top: 1.75rem;
        }
    }

    &__employment-preference-slider {
        max-width: 100%;

        @include for-size(tablet-portrait-up) {
            max-width: 50%;
        }
    }

    &__travel-distance {
        max-width: 100%;

        @include for-size(tablet-portrait-up) {
            max-width: 50%;
        }
    }

    &__button-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 3rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    &__button-reset {
        @extend %button--transparent-background;

        color: var(--c-text);
    }
}
