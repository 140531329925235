.confirm-email-address-change {
    padding: 1.5rem;
    line-height: 1.5;

    &__title {
        font-size: 1.5rem;
    }

    &__description {
        margin-top: 1rem;
    }

    &__loader {
        margin-top: 1.5rem;
    }

    &__result {
        margin-top: 1.5rem;
    }
}
