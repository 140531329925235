.my-applications {

    &__title {
        font-size: 1.75rem;
        font-weight: var(--fw-bold);
        color: var(--c-text);
    }

    &__null-state,
    &__overview {
        margin-top: 1.5rem;
    }
}
