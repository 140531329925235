.step-progress-indicator {
    --step-progress-indicator-width: 0%;

    width: 100%;
    background-color: var(--c-grey-light);

    &__progress {
        width: var(--step-progress-indicator-width);
        height: .625rem;
        background-color: var(--c-secondary);
    }
}
