.vacancy-index-column-list {
    --vacancy-index-column-amount: 3;
    --vacancy-index-column-width: calc(var(--vacancy-index-category-list-width) / var(--vacancy-index-column-amount));

    display: flex;
    flex-wrap: wrap;
    gap: 2rem 1rem;

    & > li {
        width: calc(var(--vacancy-index-column-width) - 1rem);
        list-style-type: none;
    }
}
