.sbb-person-recognitions {
    padding: .25rem 0;
    font-size: .875rem;
    line-height: 1.5;

    &__title {
        font-weight: var(--fw-bold);
    }

    &__list {
        margin: .5rem 0 0;
        padding: 0;
    }

    &__item {
        list-style-type: none;

        &::before {
            content: "- ";
        }
    }
}
