@import "src/styles";

.home-call-to-action {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--c-white);

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        min-height: 24rem;
    }

    &__image-wrapper {
        position: relative;
        height: 20rem;

        @include for-size(tablet-portrait-up) {
            position: absolute;
            bottom: 0;
            left: 0;
            height: initial;
        }
    }

    &__image {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 20;
        width: 16rem;

        @include for-size(tablet-portrait-up) {
            width: initial;
        }
    }

    &__circle-wrapper {
        @include square(17rem);

        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        overflow: hidden;

        @include for-size(tablet-portrait-up) {
            @include square(20rem);
        }
    }

    &__circle {
        @include circle(18rem);

        position: absolute;
        bottom: -2rem;
        left: -1rem;
        overflow: hidden;
        background: linear-gradient(90deg, var(--c-white) -25%, var(--c-secondary));

        @include for-size(tablet-portrait-up) {
            @include circle(21rem);
        }

        &::after {
            @extend %click-extend;
            @include grain-background;

            content: "";
        }
    }

    &__content-wrapper {
        max-width: 30rem;
        padding: 2rem;

        @include for-size(tablet-portrait-up) {
            max-width: 24rem;
            padding: 0;
            padding-right: 1rem;
        }

        @include for-size(tablet-landscape-up) {
            max-width: 30rem;
            padding-right: 2.5rem;
        }
    }

    &__title {
        font-size: 2rem;
        font-weight: var(--fw-bold);
        line-height: 1.5;
        color: var(--c-primary);

        @include for-size(tablet-landscape-up) {
            font-size: 2.5rem;
        }
    }

    &__button {
        margin-top: 2rem;
    }
}
