@import "src/styles";

$component: 'job-experience-form';

.#{$component} {

    & > * + * {
        margin-top: 1.5rem;
    }

    &__input-wrapper {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }

        .#{$component}__input + .#{$component}__input {
            margin-top: 1.5rem;

            @include for-size(tablet-portrait-up) {
                margin-top: 0;
                margin-left: 1.5rem;
            }
        }
    }

    &__input {
        width: 100%;

        &--start-date {
            position: relative;
            z-index: 30;
        }

        &--end-date {
            position: relative;
            z-index: 20;
        }
    }

    &__checkbox {
        width: auto;
    }

    &__action-buttons {
        position: relative;
        z-index: 10;
        margin-top: 2.5rem;
    }
}
