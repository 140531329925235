@import "src/styles";

.company-contact-card {

    &__skeleton-wrapper {
        margin-top: 2rem;
    }

    &__skeleton {

        & + & {
            margin-top: 1rem;
        }
    }

    &__description {
        margin-top: 2rem;
    }

    &__chat-button {
        @extend %button--outline;

        margin-top: 1.5rem;
    }
}
