@import "src/styles";

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(var(--c-black-rgb), .5);
    transition: opacity var(--modal-transition-duration) ease;
    opacity: 0;

    &--revealed {
        opacity: 1;
    }

    &__card {
        z-index: 20;
        width: 95%;
        max-width: 44rem;
        margin: 4rem auto;
        padding: 2.5rem 1rem;

        @include for-size(tablet-portrait-up) {
            padding: 2.5rem;
        }
    }

    &__close-button {
        @extend %button--control;

        position: absolute;
        top: 0;
        right: 0;
        z-index: 30;
        margin: 1.75rem;
        color: var(--c-text);
    }
}
