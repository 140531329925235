@import "src/styles";

.vacancy-header {

    &__information-wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 1.5rem;
        background-color: var(--c-white);

        @include for-size(tablet-portrait-up) {
            flex-wrap: nowrap;
            padding: 1.5rem 2.5rem 2rem;
        }
    }

    &__avatar {
        @include square(6rem);

        font-size: 1.75rem;
    }

    &__text-wrapper {
        order: 1;
        width: 100%;
        margin-top: 1rem;
        line-height: 1.5;

        @include for-size(tablet-portrait-up) {
            order: initial;
            margin-top: 0;
            margin-left: 1.25rem;
        }
    }

    &__title {
        font-size: 1.375rem;
        font-weight: var(--fw-bold);

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__company-link {
        font-weight: var(--fw-bold);
        color: var(--c-grey-dark);
        text-decoration: none;
    }

    &__publication-date {
        margin-top: .25rem;
        color: var(--c-grey-dark);
    }

    &__controls {
        flex: 1;
    }

    &__control-button {
        @extend %button--control;

        &:hover,
        &:focus {
            color: var(--c-primary);
            background-color: var(--c-grey-light);
        }

        & + & {
            margin-left: 1rem;
        }
    }
}
