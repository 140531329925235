@import "src/styles";

.employee-data-form {
    animation: fadeIn ease-out 0.5s forwards;

    &__title {
        @extend %form-subtitle;
    }

    &__intro {
        @extend %paragraph;

        margin-bottom: 2rem;
        color: var(--c-grey-dark);
    }

    &__form-row {
        @extend %form-row;
    }

    &__firstname-insertion-row,
    &__lastname-gender-row {

        @include for-size(phone-only) {
            flex-direction: column;
        }
    }

    &__first-name-input,
    &__last-name-input {
        margin-bottom: 1rem;
        margin-right: 1rem;

        @include for-size(phone-only) {
            margin-right: 0;
        }
    }

    &__last-name-input,
    &__gender-list {
        width: 50% !important;

        @include for-size(phone-only) {
            width: 100% !important;
        }
    }
}
