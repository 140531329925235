@import "src/styles";

$component: 'recent-vacancies-list';

.#{$component} {

    &__title-skeleton {
        max-width: 32rem;
        height: 2rem;
        margin: 0 auto;

        @include for-size(tablet-portrait-up) {
            height: 1.75rem;
        }
    }

    &__title {
        font-size: 1.75rem;
        font-weight: var(--fw-bold);
        line-height: 1;
        text-align: center;
        color: var(--c-primary);

        @include for-size(tablet-portrait-up) {
            font-size: 2rem;
        }

        &--is-milestone {

            .#{$component}__title-amount {
                @extend %span-underline;
            }

            &::after {
                content: "🎉";
                display: inline-block;
                margin-left: 1rem;
                transform: scale(0);
                transform-origin: bottom left;
                animation: scale-in .3s ease forwards;
            }
        }
    }

    &__title-amount {
        position: relative;
        margin: 0 .25em;
    }

    &__list-wrapper {
        margin-top: 4rem;
    }

    &__controls {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__role-title {
        font-size: 1.5rem;
        font-weight: var(--fw-bold);
        color: var(--c-grey-dark);

        @include for-size(tablet-portrait-up) {
            font-size: 1.125rem;
        }
    }

    &__role-button {
        @extend %button--no-background;

        margin-top: 1rem;

        @include for-size(tablet-portrait-up) {
            margin: 0;
            padding: .75rem 0;
        }
    }

    &__loader {
        margin-top: 1.25rem;
    }

    &__vacancies {
        margin-top: 1.25rem;
    }
}
