@import "src/styles";

.range-input-marks {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;

    &__mark {
        @extend %flex-align-center;

        width: 0;
        flex-direction: column;
        font-size: 1em;
        list-style-type: none;

        &:first-child {
            transform: translateX(-50%);
        }

        &:last-child {
            transform: translateX(50%);
        }

        &::before {
            content: "";
            display: block;
            width: 1px;
            height: .5em;
            margin-bottom: .25rem;
            background-color: currentColor;
        }
    }
}
