@import "src/styles";

.chat-room {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    &__event-list {
        flex: 1;
        transition: opacity .1s ease;

        &--is-reconnecting {
            opacity: .5;
        }
    }

    &__reconnect-wrapper {
        position: absolute;
        left: 50%;
        top: calc(var(--message-overview-header-height) + 1rem);
        padding: .5rem 1rem;
        white-space: nowrap;
        pointer-events: none;
        transition: opacity .1s ease;
        opacity: 0;
        transform: translateX(-50%);

        &--is-visible {
            pointer-events: all;
            opacity: 1;
        }
    }

    &__reconnect-message {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: .5rem 1rem;
        border-radius: 2rem;
        background-color: var(--c-grey-light);
    }

    &__reconnect-button {
        @extend %button--no-background;

        padding: 0;
        text-decoration: underline;
        color: currentColor;
    }

    &__footer {
        padding: 1.5rem;
        border-top: 1px solid var(--c-grey-light);
        background-color: var(--c-white);
    }

    &__disclaimer-wrapper {
        display: flex;
        gap: .625rem;
        margin-top: 1rem;
        color: var(--c-grey-dark);
    }

    &__disclaimer-icon {
        height: 1.125rem;
        transform: translateY(.125rem);
    }

    &__disclaimer-text {
        flex: 1;
        font-size: .875rem;
    }
}
