@import "src/styles";

.footer-link-list {
    margin: 0;

    &__item {
        list-style-type: none;

        & + & {
            margin-top: 1rem;
        }
    }

    &__link {
        text-decoration: none;
        color: currentColor;

        @include for-size(tablet-portrait-up) {
            opacity: .7;
            transition: opacity .1s ease-out;

            &:hover,
            &:focus {
                opacity: 1;
            }
        }
    }
}
