.sbb-recognition-table {

    &__head {
        font-weight: var(--fw-bold);
    }

    &__cell {

        &:last-of-type {
            max-width: 2rem;
        }

        &--crebo {
            max-width: var(--sbb-recognition-table-crebo-width);
        }

        &--title {
            max-width: var(--sbb-recognition-table-title-width);
        }

        &--level {
            max-width: var(--sbb-recognition-table-level-width);
        }

        &--date {
            max-width: var(--sbb-recognition-table-date-width);
        }
    }
}
