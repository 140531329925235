@import "src/styles";

.pagination {

    .pagination__ellipsis {
        width: 1rem;

        @include for-size(tablet-portrait-up) {
            width: 1.5rem;
        }
    }

    .pagination-input__label {
        padding: .5rem;
        font-size: 1rem;
        font-weight: 600;
        text-decoration: none;
        color: var(--c-grey);

        @include for-size(tablet-portrait-up) {
            margin: 0 .25rem;
            font-size: 1rem;
        }

        &--active {
            color: var(--c-always-black);
            background-color: var(--c-secondary);
            box-shadow: var(--shadow-medium);
        }

        &:not(.pagination-input__label--active) {

            &:hover, &:focus {
                color: var(--c-grey-dark);
                background-color: var(--c-grey-light);
            }
        }
    }

    .pagination-direction-button {
        width: var(--pagination-block-size);
        color: var(--c-text);
        text-decoration: none;

        &--disabled {
            background: none;
        }

        &__text {
            display: none;
        }

        svg {
            fill: currentColor;
        }
    }

    @include for-size(phone-only) {
        .pagination-input--side-number {
            display: none;

            & + .pagination__ellipsis {
                display: none;
            }
        }

        .pagination__controls {
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .pagination__list {
            justify-content: center;
            margin-bottom: 1.5rem;
            width: 100%;
            order: 0;
        }

        .pagination-direction-button {
            border-radius: 2px;
            justify-content: center;
            width: 50%;
            padding: 1rem;
            line-height: 2;
            font-size: .75rem;
            color: var(--c-text);
            opacity: 1;

            svg {
                height: .75rem;
                min-width: .75rem;
            }

            &__text {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 600;
            }

            &--prev, &--next {
                width: calc(50% - .5rem);
            }

            &--prev {
                order: 1;
                background-color: var(--c-grey-light);
            }

            &--next {
                order: 2;
                color: var(--c-always-black);
                background-color: var(--c-secondary);
            }
        }

        &.pagination--start {
            .pagination-direction-button--prev {
                display: none;
            }

            .pagination-direction-button--next {
                width: 100%;
            }
        }

        &.pagination--end {
            .pagination-direction-button--next {
                display: none;
            }

            .pagination-direction-button--prev {
                width: 100%;
            }
        }
    }
}
