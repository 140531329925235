@import "src/styles";

.expandable-filter {
    position: relative;
    z-index: initial;

    &__button {
        position: relative;
        z-index: 10;
    }

    &__card {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 20;
        min-width: 20rem;
        padding: 0;
        pointer-events: none;
        border: 1px solid var(--c-grey-light);
        opacity: 0;
        transform: translateY(calc(100% - 2rem));
        transition: opacity .1s ease, transform .1s ease;

        &--is-expanded {
            pointer-events: all;
            opacity: 1;
            transform: translateY(calc(100% + .5rem));
        }
    }

    &__filter-wrapper {
        padding: 1.25rem;
    }

    &__card-button-wrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 1.25rem;
        border-top: 1px solid var(--c-grey-light);
    }

    &__save-button {
        @extend %button--small;

        text-transform: none;
    }

    &__cancel-button {
        @extend %button--small;
        @extend %button--transparent-background;

        text-transform: none;
        color: var(--c-grey-dark);
    }
}
