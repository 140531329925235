.vacancy-index-breadcrumb {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-right: .75rem;
    font-weight: var(--fw-medium);
    color: var(--c-grey);
    list-style-type: none;

    &:last-child &__icon {
        display: none;
    }

    &__link {
        font-weight: var(--fw-medium);
    }

    &__icon {
        height: .625rem;
        margin-left: .75rem;
        color: var(--c-grey-dark);
    }
}
