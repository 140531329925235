.error-label {
    margin-top: .25rem;
    font-size: .875rem;
    line-height: 1.5;
    color: var(--c-negative);

    &:first-letter {
        text-transform: uppercase;
    }
}
