@import "src/styles";

.competencies-selector {

    &__option-list {
        margin-top: 1rem;
        border: 1px solid var(--c-grey-light);

        @include for-size(tablet-portrait-up) {
            max-height: 36rem;
            overflow-y: auto;
        }
    }

    &__option {

        & + & {
            border-top: 1px solid var(--c-grey-light);
        }
    }

    &__no-results {
        margin-top: 1rem;
        padding: 1rem;
        color: var(--c-grey-dark);
        border: 1px solid var(--c-grey-light);
    }

    &__loader,
    &__selected-options {
        margin-top: 1.5rem;
    }
}
