@import "src/styles";

.my-applications-null-state {

    &__description {
        max-width: 40rem;
    }

    &__button-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
        margin-top: 2.5rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: center;
        }
    }
}
