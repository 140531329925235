.chat-report-form {

    &__disclaimer {
        margin-top: 1.5rem;
    }

    &__action-buttons {
        margin-top: 4rem;
    }
}
