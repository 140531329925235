.vacancy-applicants {

    &__title {
        font-size: 1.75rem;
        font-weight: var(--fw-bold);
        color: var(--c-text);
    }

    &__filters {
        position: relative;
        z-index: 20;
        margin-top: 1.5rem;
    }

    &__null-state,
    &__overview,
    &__matches-cta {
        position: relative;
        z-index: 10;
        margin-top: 2rem;
    }

    &__matches-cta {
        margin-top: 4.5rem;
    }
}
