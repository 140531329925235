@import "src/styles";

.matching-animation {
    position: relative;
    width: 100%;
    max-width: 29.5rem;
    margin: 0 auto;

    @include for-size(tablet-portrait-up) {
        transform: translateX(-7.5%);
    }

    &__sizer {
        position: relative;
        width: 100%;
        padding-bottom: calc((100% / 472) * 208);

        @supports (aspect-ratio: 472 / 208) {
            aspect-ratio: 472 / 208;
            padding-bottom: 0;
        }
    }

    &__video, &__img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
    }
}
