@import "src/styles";

.vacancy-index-page {
    line-height: 1.5;
    background-color: var(--c-white);

    &__wrapper {
        max-width: 90rem;
        margin: 0 auto;
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 0 2rem;
        }
    }
}
