@import "src/styles";

.application-status-waiting {

    &__description {
        margin-top: 1rem;
    }

    &__button-wrapper {
        margin-top: 1.5rem;
    }

    &__button {

        &--for-rejection {
            @extend %button--transparent-background;

            color: var(--c-text);
        }

        & + & {
            margin-top: 1rem;
        }
    }
}
