.vacancy-index-link {
    color: var(--c-grey);
    word-wrap: break-word;

    & + & &__link {
        color: var(--c-link);
    }

    &__link {
        color: var(--c-grey-dark);
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}
