@import "src/styles";

.blog-content-skeletons {

    &__breadcrumbs {
        width: 100%;
        max-width: var(--blog-detail-wrapper-width-wide);
        margin: 0 auto;
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 0 2rem;
        }
    }

    &__header {
        position: relative;
        z-index: 10;
        margin-top: 2.5rem;
    }

    &__body {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        margin-top: 2rem;
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 0 2rem;
        }
    }

    &__renderer {
        width: 100%;
        max-width: 46rem;
    }
}
