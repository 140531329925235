@import "src/styles";

.blog-content {
    position: relative;

    &__scroll-progress {
        position: fixed;
        top: var(--navigation-menu-height);
        z-index: 20;
    }

    &__breadcrumbs {
        width: 100%;
        max-width: var(--blog-detail-wrapper-width-wide);
        margin: 0 auto;
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 0 2rem;
        }
    }

    &__header {
        position: relative;
        z-index: 10;
        margin-top: 2.5rem;
    }

    &__body {
        margin-top: 2rem;
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 0 2rem;
        }
    }

    &__content-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        max-width: var(--blog-detail-wrapper-width);
        margin: 0 auto;

        @include for-size(tablet-landscape-up) {
            align-items: center;
        }
    }

    &__renderer {
        width: 100%;
        max-width: 46rem;
        min-height: 16rem;
    }

    &__share {
        margin-top: 1.5rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;
            margin-top: 0;
        }
    }

    &__share-item {

        & + & {
            margin-left: 1rem;

            @include for-size(tablet-landscape-up) {
                margin-left: 0;
                margin-top: 1.5rem;
            }
        }
    }
}
