.employment-preference-form {

    &__input {

        & + & {
            margin-top: 1rem;
        }
    }

    &__fixed-hours-input {
        width: 16rem;
    }

    &__action-buttons {
        margin-top: 4rem;
    }
}
