.video-appointment-skeletons {

    &__title {
        height: 1.5rem;
    }

    &__appointment-time {
        max-width: 40%;
        margin-top: 1.5rem;
    }

    &__video {
        width: 100%;
        height: 70vh;
        margin-top: 1.5rem;
        background-color: var(--c-grey-light);
    }
}
