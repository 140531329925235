@import "src/styles";

.company-data-form {
    animation: fadeIn ease-out 0.5s forwards;

    &__title {
        @extend %form-subtitle;
    }

    &__intro {
        @extend %paragraph;

        margin-bottom: 2rem;
        color: var(--c-grey-dark);
    }

    &__form-row {
        @extend %form-row;
    }
}
