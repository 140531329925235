@import "src/styles";

.skills-to-learn-card {
    line-height: 1.5;

    &__skeleton,
    &__null-state {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    &__null-state-button {
        @extend %button--add;

        margin-top: 2rem;
    }

    &__tag-list {
        display: flex;
        flex-wrap: wrap;
        gap: var(--flex-tag-gap);
        margin-top: 1.5rem;
    }

    &__tag-item {
        list-style-type: none;
    }
}
