@import "src/styles";

.company-usp-card {
    padding: 1.5rem 1rem;
    line-height: 1.5;

    @include for-size(tablet-landscape-up) {
        padding: 2.5rem;
    }

    &__title {
        font-size: 1.5rem;
        font-weight: var(--fw-bold);
    }

    &__description-wrapper {
        display: flex;
        align-items: center;
        margin-top: 1.5rem;
    }

    &__image {
        height: 8rem;
    }

    &__description {
        margin-left: 2rem;
        font-size: 1rem;
        color: var(--c-grey-dark);
    }

    &__list {
        display: flex;
        flex-direction: column;
        margin-top: 1.25rem;
    }

    &__item {
        display: flex;
        align-items: center;
        list-style-type: none;
        padding: .75rem 0;

        & + & {
            border-top: 1px solid var(--c-grey-light);
        }
    }

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(1.5rem);

        background-color: var(--c-secondary);
    }

    &__icon {
        height: .625rem;
    }

    &__label {
        margin-left: 1rem;
        font-size: 1rem;
        font-weight: var(--fw-bold);
    }
}
