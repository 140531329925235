.application-selector {

    &__application-wrapper {
        margin-top: 1.5rem;
    }

    &__result-wrapper {
        max-height: 18rem;
        overflow: auto;
        border: 1px solid var(--c-grey-light);
        border-top: none;
    }

    &__no-results {
        padding: 1rem;
    }

    &__option {

        & + & {
            border-top: 1px solid var(--c-grey-light);
        }
    }

    &__footnote {
        margin-top: .75rem;
        font-size: .875rem;
        font-style: italic;
        color: var(--c-grey-dark);
    }
}
