.vacancy-index-occupation-letter {

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem 1.25rem;
        margin: 0;
        padding: 0;
    }

    &__letter {
        list-style-type: none;
    }
}
