.vacancy-detail-error {
    padding-top: 4rem;

    &__title {
        font-size: 3rem;
    }

    &__description {
        margin-top: 2rem;
        font-size: 1.125rem;
        font-weight: var(--fw-medium);
        color: var(--c-grey-dark);
    }

    &__button {
        margin-top: 2rem;
    }
}
