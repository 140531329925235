@import "src/styles";

.competency-highlight {

    &__back-button {
        @extend %button--transparent-background;

        color: var(--c-link);
    }

    &__title {
        margin-top: 1.5rem;
    }

    &__description {
        margin-top: 1.5rem;
    }

    &__example-list {
        margin-top: 1rem;
        border: 1px solid var(--c-grey-light);
    }

    &__example-item {
        list-style-type: none;
        padding: .75rem 1rem;

        & + & {
            border-top: 1px solid var(--c-grey-light);
        }
    }
}
