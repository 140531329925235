@import "src/styles";

.carousel {
    --carousel-column-amount: 1;
    --carousel-item-amount: 1;
    --carousel-current-slide: 1;
    --carousel-column-width: calc(100% / var(--carousel-column-amount));
    --carousel-slide-width: calc((100% / var(--carousel-item-amount) * var(--carousel-column-amount)));

    width: 100%;

    &__header {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__title {
        font-size: 1.5rem;
        color: var(--c-primary);
        font-weight: var(--fw-bold);
        line-height: 1.5;

        @include for-size(tablet-portrait-up) {
            font-size: 2rem;
        }
    }

    &__arrow-controls {
        margin-top: 1rem;
        margin-left: auto;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
        }
    }

    &__slide-wrapper {
        width: 100%;
        margin-top: 2rem;
        overflow: hidden;

        @include for-size(tablet-portrait-up) {
            margin-top: 3rem;
        }
    }

    &__slide-content {

        & > ul {
            display: flex;
            column-gap: initial;
            width: calc(var(--carousel-column-width) * var(--carousel-item-amount));
            margin: 0;
            padding: 0;
            transform: translateX(negative(calc(var(--carousel-slide-width) * #{var(--carousel-current-slide)})));
            transition: transform .3s ease;

            & > li {
                width: 100%;
                list-style-type: none;
            }
        }
    }

    &__bullet-controls {
        margin-top: 2rem;

        @include for-size(tablet-portrait-up) {
            margin-top: 3rem;
        }
    }
}
