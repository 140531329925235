@import "src/styles";

.cookie-consent-list {
    display: flex;
    flex-direction: column;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        align-items: center;
    }

    &__checkbox {
        white-space: nowrap;

        & + & {
            margin-top: .5rem;

            @include for-size(tablet-portrait-up) {
                margin-top: 0;
                margin-left: 1rem;
            }
        }
    }
}
