.files-to-upload-list {
    width: 100%;
    border: 1px solid var(--c-grey-light);

    &__item {

        & + & {
            border-top: 1px solid var(--c-grey-light);
        }
    }
}
