$component: 'expandable-navigation';

.#{$component} {
    position: absolute;
    width: 16rem;
    margin: 0;
    padding: 0;
    pointer-events: none;
    transform: translateY(-1rem);
    opacity: 0;
    transition: transform .1s ease, opacity .1s ease;

    &--is-expanded {
        pointer-events: all;
        transform: translateY(0);
        opacity: 1;
    }

    &__list {
        box-shadow: var(--shadow-medium);
    }

    &__item {
        list-style-type: none;

        & + & {
            border-top: 1px solid var(--c-grey-light);
        }

        &:first-of-type {

            .#{$component}__button {
                border-radius: var(--border-radius) var(--border-radius) 0 0;
            }
        }

        &:last-of-type {

            .#{$component}__button {
                border-radius: 0 0 var(--border-radius) var(--border-radius);
            }
        }
    }

    &__button {
        width: 100%;
        padding: 1.5rem 2rem;
        text-transform: none;
        font-weight: var(--fw-semi-bold);
        color: var(--c-grey-dark);
        border-radius: 0;
        background-color: var(--c-white);

        &:disabled {
            color: var(--c-grey);
        }
    }
}
