.youtube-form {

    &__preview {
        margin-top: 2rem;
    }

    &__disclaimer {
        margin-top: 2rem;
    }

    &__action-buttons {
        position: relative;
        z-index: 10;
        margin-top: 4rem;
    }
}
