@import "src/styles";

.disclaimer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem;
    font-size: .875rem;
    color: var(--c-grey-dark);
    border-radius: var(--border-radius);
    border: 1px solid var(--c-grey-light);

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
    }

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(2.5rem);

        color: var(--c-primary);
        background-color: var(--c-secondary);

        &--is-serious {
            color: var(--c-white);
            background-color: var(--c-negative);
        }
    }

    &__icon {
        height: 1.25rem;
        transform: translateY(-.125rem);
    }

    &__text-wrapper {
        margin-top: 1rem;
        padding-top: .25rem;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &__description {
        margin-top: 1rem;
    }
}
