.sbb-education-card {
    --sbb-recognition-table-crebo-width: 12rem;
    --sbb-recognition-table-title-width: 32rem;
    --sbb-recognition-table-level-width: 8rem;
    --sbb-recognition-table-date-width: 12rem;

    padding: 2rem 2.5rem;

    &__title {
        font-size: 1.1875rem;
        font-weight: var(--fw-bold);
        line-height: 1.5;
    }

    &__table {
        margin-top: 1rem;
    }
}
