@import "src/styles";

.appointments-input {

    &__input {

        & + & {
            margin-top: 1.5rem;
            padding-top: 1.5rem;
            border-top: 1px solid var(--c-grey-light);

            @include for-size(tablet-portrait-up) {
                margin-top: 1.5rem;
                padding-top: 0;
                border: none;
            }
        }
    }

    &__error-label {
        margin-top: 1rem;
    }

    &__add-button {
        @extend %button--transparent-background;

        margin-top: 1.5rem;
        color: var(--c-text);
    }
}
