@import "src/styles";

.vacancy-meta-card {

    &__skeleton {
        margin-top: .5rem;
    }

    &__skeleton-list,
    &__meta-list {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            margin-top: 2rem;
        }
    }

    &__skeleton-item,
    &__meta-item {
        @extend %flex-align-center;

        flex-direction: column;
        width: 100%;
        padding: 1.75rem;
        list-style-type: none;
        line-height: 1.5;

        & + & {
            border-top: 1px solid var(--c-grey-light);

            @include for-size(tablet-portrait-up) {
                border-top: none;
                border-left: 1px solid var(--c-grey-light);
            }
        }
    }

    &__skeleton-icon,
    &__icon {
        width: 1.25rem;
        color: var(--c-grey-dark);
    }

    &__value {
        margin-top: 1rem;
        font-weight: var(--fw-semi-bold);
        color: var(--c-primary);
    }

    &__label {
        color: var(--c-grey-dark);
    }
}
