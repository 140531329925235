@import "src/styles";

.chat-selector {
    display: flex;
    flex-direction: column;
    position: relative;

    &__search-wrapper {
        @extend %flex-align-center;

        z-index: 20;
        height: var(--message-overview-header-height);
        padding: 0 1.5rem;
        border-bottom: 1px solid var(--c-grey-light);
        background-color: var(--c-white);

        @include for-size(tablet-portrait-up) {
            position: sticky;
            top: var(--navigation-menu-height);
        }
    }

    &__search-input {
        width: 100%;
    }

    &__null-state {
        padding: 1.5rem;
        color: var(--c-grey-dark);
    }

    &__preview-list {
        flex: 1;
        position: relative;
        z-index: 10;
        overflow: auto;
    }
}
