.chat-selector-skeletons {
    margin: 0;
    padding: 0;

    &__item {
        padding: 1.5rem;
        list-style-type: none;
        border-bottom: 1px solid var(--c-grey-light);
    }

    &__message {
        margin-top: 1.5rem;
    }

    &__line {

        & + & {
            margin-top: .5rem;
        }
    }
}
