@import "src/styles";

.button-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin: 0;
    padding: 0;

    &--align-center {
        align-items: center;
    }

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        align-items: center;

        &--align-center {
            justify-content: center;
        }

        &--align-right {
            flex-direction: row-reverse;
        }
    }

    &__button {
        list-style-type: none;
    }
}
