@import "src/styles";

.profile-progress {
    --c-progress-theme: var(--c-warning);

    padding: 1.5rem;
    box-shadow: none;

    @include for-size(tablet-portrait-up) {
        padding: 2rem 2.5rem;
    }

    &--is-satisfactory {
        --c-progress-theme: var(--c-positive);
    }

    &__title {
        font-size: 1.125rem;
        font-weight: var(--fw-bold);
        line-height: 1.5;
    }

    &__step-indicator {
        margin-top: 2rem;
    }

    &__call-to-action {
        margin-top: 2rem;
    }
}
