%button {
    display: block;
    margin: 0;
    padding: .75rem 2rem;
    font-family: var(--f-primary);
    font-size: 1rem;
    font-weight: var(--fw-semi-bold);
    color: var(--c-always-black);
    cursor: pointer;
    border-radius: var(--border-radius);
    border: none;
    background-color: var(--c-secondary);

    &:disabled {
        color: var(--c-grey);
        cursor: not-allowed;
        background-color: var(--c-grey-light);
    }
}

%button--small {
    padding: .5rem 1.25rem;
    font-size: .875rem;
}

%button--no-background {
    background: none;

    &[href]:hover,
    &[href]:focus {
        background: none;
    }

    &:enabled:hover,
    &:enabled:focus {
        background: none;
    }

    &:disabled {
        background: none;
    }
}

%button--transparent-background {
    @extend %button--no-background;

    position: relative;

    &::after {
        @extend %click-extend;

        content: "";
        border-radius: var(--border-radius);
        background-color: currentColor;
        opacity: .1;
    }

    &[href]:hover,
    &[href]:focus {

        &::after {
            opacity: .15;
        }
    }

    &:enabled:hover,
    &:enabled:focus {

        &::after {
            opacity: .15;
        }
    }
}

%button--negative {
    color: var(--c-always-white);
    background-color: var(--c-negative);

    &:enabled:hover,
    &:enabled:focus {
        background-color: rgba(var(--c-negative-rgb), .85);
    }
}

%button--add {
    @extend %button--no-background;

    padding: 0;
    text-decoration: underline;
    color: var(--c-link);
}

%button--control {
    @extend %flex-align-center;
    @include circle(2.5rem);

    padding: 0;
    color: var(--c-grey);
    background-color: var(--c-white);

    &:enabled:hover,
    &:enabled:focus {
        color: var(--c-primary);
        background-color: var(--c-grey-light);
    }
}

%button--outline {
    @extend %button--no-background;

    border: 1px solid var(--c-grey-light);
}
