@import "src/styles";

.confirm-modal {

    &__button-list {
        margin-top: 2.5rem;
    }

    &__button {

        &--is-negative {
            @extend %button--negative;
        }

        &--for-cancel {
            @extend %button--transparent-background;

            color: var(--c-text);
        }
    }
}
