.email-address-change-success {
    padding-top: 1.5rem;
    border-top: 1px solid var(--c-grey-light);

    &__title {
        font-size: 1.25rem;
    }

    &__description {
        margin-top: 1rem;
    }

    &__button {
        margin-top: 1.5rem;
    }
}
