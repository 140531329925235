@import "src/styles";

.talent-matching-call-to-action {
    @extend %flex-align-center;

    flex-direction: column;
    position: relative;
    padding: 1.5rem;
    border-radius: var(--border-radius);
    background-color: var(--c-white);

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        padding: 0;
        background: none;
    }

    &__button {
        display: flex;
        align-items: center;
        height: 3.5rem;
        color: var(--c-white);
        background-color: var(--c-primary);

        @include for-size(tablet-portrait-up) {
            margin-left: 1rem;
        }
    }

    &__icon {
        height: 1.5rem;
        color: var(--c-secondary);
    }

    &__label {
        margin-left: 1.25rem;
    }

    &__quote {
        margin-top: 1rem;
        transform: rotate(-5deg);

        @include for-size(tablet-portrait-up) {
            order: -1;
            margin: 0;
            transform: none;
        }
    }
}
