@import "src/styles";

$component: 'new-block-radio-list';

// TODO: Rename to 'block-radio-list', once the old component has been eradicated
.#{$component} {
    display: block;

    &--is-vertical {

        .#{$component}__list {
            flex-direction: column;
        }

        .#{$component}__item + .#{$component}__item{
            border-left: none;
            border-top: 1px solid var(--c-grey-light);
        }
    }

    &__list {
        display: flex;
        overflow: hidden;
        border-radius: var(--border-radius);
        border: 1px solid var(--c-grey-light);

        &--has-error {
            border-color: var(--c-negative);
        }
    }

    &__item {
        @extend %flex-align-center;

        width: 100%;
        height: 100%;

        & + & {
            border-left: 1px solid var(--c-grey-light);
        }
    }
}
