.company-information-card {

    &__skeleton-wrapper {
        margin-top: 2rem;
    }

    &__skeleton {

        & + & {
            margin-top: 1rem;
        }
    }

    &__item {
        margin-top: 2rem;
    }

    &__item-title {
        font-size: 1rem;
        color: var(--c-grey-dark);
    }

    &__tag-list {
        margin-top: 1rem;
        padding: 0;
    }

    &__tag-item {
        list-style-type: none;

        & + & {
            margin-left: 1rem;
        }
    }

    &__tag-link {
        display: inline-block;
        padding: .5rem 1rem;
        font-size: .875rem;
        text-decoration: none;
        color: var(--c-grey-dark);
        border-radius: var(--border-radius);
        border: 1px solid var(--c-grey-light);
    }
}
