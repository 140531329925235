.table-row {
    --table-row-height: 3.5rem;

    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: var(--table-row-height);
    padding: .5rem 0;
    background-color: inherit;

    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: -.625rem;
        width: calc(100% + 1.25rem);
        height: 1px;
        background-color: var(--c-grey-light);
    }
}
