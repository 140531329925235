.salary-input {
    font-size: 1rem;

    &__cta {
        display: flex;
        align-items: center;
        padding: 1.5rem;
        border: 1px solid var(--c-grey-light);
        background-color: var(--c-background);
    }

    &__cta-icon {
        width: 3.75rem;
    }

    &__cta-text {
        margin-left: 1.25rem;
    }

    &__timeframe-input {
        max-width: 18rem;
        margin-top: 1.5rem;
    }

    &__min-max-input {
        margin-top: 1.5rem;
    }
}
