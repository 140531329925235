.progress-emoticon {
    min-width: 4.5rem;
    max-width: 4.5rem;

    &__face {
        fill: var(--c-progress-theme);
    }

    &__star {
        fill: var(--c-grey-light);

        &--has-color {
            fill: var(--c-progress-theme);
        }
    }
}
