@import "src/styles";

.vacancy-matches-filter {

    &__vacancy-selector-wrapper {
        position: relative;
        z-index: 20;
        padding: 1.5rem;
        background-color: var(--c-white);
    }

    &__vacancy-input {
        max-width: 36rem;
        margin-top: 1rem;
    }

    &__vacancy-input-results {
        position: absolute;
        width: 100%;
        border-color: var(--c-grey-light);
        background-color: var(--c-white);
        box-shadow: var(--shadow-medium);
    }

    &__desktop-filter-wrapper {
        position: relative;
        z-index: 10;
        margin-top: 1.5rem;
        padding: 1.5rem;
        border-radius: var(--border-radius);
        background-color: var(--c-white);
    }

    &__heading-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 1rem;
        border-bottom: 1px solid var(--c-grey-light);
    }

    &__title-skeleton {
        max-width: 24rem;
        font-size: 1.125rem;
    }

    &__title {
        font-size: 1.125rem;
        color: var(--c-grey-dark);
    }

    &__reset-button {
        margin-left: 1.5rem;
    }

    &__desktop-filter-form {
        margin-top: 1.25rem;
    }

    &__results-title,
    &__results-title-skeleton {
        max-width: 24rem;
        margin-top: 1.5rem;
        font-size: 1.75rem;
        font-weight: var(--fw-bold);

        @include for-size(tablet-portrait-up) {
            margin-top: 2.5rem;
        }
    }
}
