.application-status-invite-sent {

    &__description {
        margin-top: 1rem;
    }

    &__appointment-list {
        margin-top: 1.5rem;
        padding: 0;
    }

    &__appointment-item {
        display: flex;
        align-items: center;

        & + & {
            margin-top: 1rem;
        }
    }

    &__appointment-label {
        flex: 1;
        margin-left: .75rem;
        font-style: italic;
    }
}
