.application-match-form {

    &__mbo-disclaimer,
    &__archive-disclaimer,
    &__archive-checkbox {
        margin-top: 1.5rem;
    }

    &__action-buttons {
        position: relative;
        z-index: 10;
        margin-top: 4rem;
    }
}
