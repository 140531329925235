@import "src/styles";

.vacancy-card {
    position: relative;
    line-height: 1.5;

    &::after {
        @extend %click-extend;

        content: "";
        z-index: 1;
        background-color: rgba(var(--c-black-rgb), .1);
        box-shadow: var(--shadow-medium);
        transition: opacity .1s ease;
        opacity: 0;
    }

    &:hover::after,
    &:focus-within::after {
        opacity: .5;
    }

    &:hover &__card,
    &:focus-within &__card {
        transform: translateY(-.25rem);
    }

    &__card {
        display: flex;
        flex-direction: column;
        z-index: 2;
        height: 100%;
        padding: 1.5rem;
        transform: translateY(0);
        transition: transform .1s ease;

        &--is-small {
            padding: 1rem;
        }

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__content-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__logo {
        @include square(4.5rem);

        font-size: 1.125rem;
    }

    &__text-wrapper {
        margin-top: 1rem;
        word-wrap: break-word;

        @include for-size(tablet-landscape-up) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &__link {
        text-decoration: none;

        &::after {
            @extend %click-extend;

            content: "";
            z-index: 30;
        }
    }

    &__title {
        display: inline-block;
        max-width: 85%;
        font-size: 1.25rem;
        font-weight: var(--fw-bold);
        line-height: 1.2;
        color: var(--c-text);

        &::first-letter {
            text-transform: uppercase;
        }

        @include for-size(tablet-landscape-up) {
            max-width: 90%;
        }
    }

    &__company {
        font-weight: var(--fw-medium);
        color: var(--c-grey-dark);
    }

    &__date {
        font-size: .875rem;
        font-weight: var(--fw-regular);
        color: var(--c-grey-dark);
    }

    &__property-tags {
        margin-top: 1rem;
    }

    &__sbb-fields-of-study {
        margin-top: 1rem;
    }

    &__controls {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 40;
        margin-left: auto;
        padding: 0;

        @include for-size(tablet-landscape-up) {
            position: relative;
        }
    }

    &__control-button {
        @extend %button--control;

        & + & {
            margin-left: .5rem;
        }
    }

    &__meta {
        margin-top: 1rem;

        @include for-size(tablet-landscape-up) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }
}
