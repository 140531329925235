// Sadly the way react-select works does not place our className first
// this is why our own style get overwritten by react-select
// Thats why you will find lots of !important
.select {
    position: relative;
    z-index: 20;
    width: 100%;
    font-size: 1rem;

    &--error {
        border: 2px solid var(--c-negative);
    }

    &:focus {
        outline: 1px solid var(--c-primary);
    }

    &__control {
        padding: 0 0 0 1rem;
        border-radius: 0 !important;
        border: 1px solid var(--c-grey-light) !important;
        box-shadow: var(--shadow-medium);

        &--is-focused, &:hover {
            border: 1px solid var(--c-primary) !important;
            box-shadow: inherit !important;
        }
    }

    &__single-value {
        margin-left: 0 !important;
    }

    &__placeholder {
        margin-left: 0 !important;
        color: var(--c-grey) !important;
    }

    &__input {
        width: auto;
    }

    &__indicator-separator {
        border-color: var(--c-grey-light);
    }

    &__value-container {
        padding: .625rem 0 !important;
        cursor: pointer;

        &--is-multi {
            padding: 1.25rem 0 .25rem !important;
        }

        &div:last-child {
            margin: 0;
            padding: 0;
        }
    }

    &__indicator {
        padding: 0 .9375rem !important;
    }

    &__menu {
        z-index: 2;
        margin-top: 1px;
        font-weight: var(--fw-medium);
        color: var(--c-grey-dark);
        border-radius: 0 !important;
        border: 1px solid var(--c-grey-light) !important;
        box-shadow: var(--shadow-medium) !important;
    }

    &__multi-value {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0 1rem 1rem 0 !important;
        padding: .25rem 0 .25rem 1rem !important;
        overflow: hidden;
        cursor: pointer;
        border: 1px solid var(--c-primary) !important;
        border-radius: 1.5625rem !important;
        background-color: var(--c-primary) !important;
        transition: color 0.15s ease-out, border 0.15s ease-out, background-color 0.15s ease-out;

        &:focus {
            outline: none;
        }

        &:last-child {
            margin-right: 0 !important;
        }
    }

    &__multi-value__label {
        margin: 0;
        padding: 0 !important;
        font-size: .875rem !important;
        line-height: 1.25;
        color: var(--c-white) !important;
        white-space: normal !important;
    }

    &__multi-value__remove {
        width: auto;
        height: 1rem;
        margin: 0 0 0 auto !important;
        transition: background-color 0.15s ease-out;

        svg {
            width: 1.125rem;
            height: 1.125rem;
            fill: var(--c-white);
            margin: 0;
            padding-right: 0;
        }

        &:hover {
            background-color: var(--c-primary) !important;
            opacity: 0.5;

            svg {
                fill: var(--c-white);
            }
        }

        &:last-child {
            padding: 0 .625rem;
            border-radius: 0;
        }
    }
}
