@import "src/styles";

.internship-vacancy-skills-step {
    line-height: 1.5;

    &__card {

        @include for-size(tablet-portrait-up) {
            padding: 2.5rem 3rem;
        }

        & + & {
            margin-top: 1.5rem;
        }
    }

    &__description {
        margin-top: 1.5rem;
    }

    &__sub-title-wrapper {
        display: flex;
        align-items: center;
    }

    &__sub-title-icon {
        height: 2rem;
    }

    &__sub-title {
        margin-left: 1rem;
        color: var(--c-primary);
    }

    &__skills,
    &__skills-to-learn,
    &__competencies,
    &__competencies-to-learn {
        margin-top: 2rem;

        & + & {
            padding-top: 2rem;
            border-top: 1px solid var(--c-grey-light);
        }
    }
}
