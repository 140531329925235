@import "src/styles";

.recent-role-vacancies-list {

    &__title {
        font-size: 2rem;
        font-weight: var(--fw-bold);
        line-height: 1;
        text-align: center;
        color: var(--c-primary);
    }

    &__list-wrapper {
        margin-top: 4rem;
    }

    &__controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__role-title {
        font-size: 1.125rem;
        font-weight: var(--fw-bold);
        color: var(--c-grey-dark);
    }

    &__role-button {
        @extend %button--no-background;

        padding: .75rem 0;
    }

    &__vacancies {
        margin-top: 1.25rem;
    }
}
