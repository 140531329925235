@import "src/styles";

.company-header-skeletons {

    &__cover {
        height: 20rem;
        background-color: var(--c-grey-light);
    }

    &__information-wrapper {
        display: flex;
        padding: 1.5rem 2.5rem 2rem;
        background-color: var(--c-white);
    }

    &__avatar {
        @include square(6rem);

        background-color: var(--c-grey-light);
    }

    &__text-wrapper {
        width: 16rem;
        margin-left: 1.25rem;
    }

    &__name {
        width: 100%;
        height: 1.25rem;
    }

    &__city,
    &__address {
        margin-top: 1rem;
    }
}
