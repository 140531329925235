.date-input {
    width: 100%;

    .react-date-picker {

        &__wrapper {
            border: none;
        }

        &__inputGroup {
            padding-left: 0;
        }

        &__inputGroup__input {
            font-size: 1rem;
            text-align: center;
            background: none !important;

            &::placeholder {
                color: var(--c-grey) !important;
            }
        }
    }

    &__input {
        width: 100%;
        height: 2.8125rem;
        padding: 0 .9375rem;
        font-size: 1rem;
        line-height: 2.75rem;
        border: 1px solid var(--c-grey-light);
        background-color: var(--c-white);
        box-shadow: var(--shadow-medium);

        &--error {
            border: 2px solid var(--c-negative);
        }
    }

    &__icon {
        height: .875rem;
        color: var(--c-grey-dark);

        &--calendar {
            height: 1.125rem;
        }
    }

    &__errors {
        font-size: .75rem;
        color: var(--c-negative);

        &--hidden {
            display: none;
        }
    }
}
