@import "src/styles";

.blog-overview {

    &__header {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__title {
        font-size: 1rem;
        font-weight: var(--fw-bold);
        line-height: 1.5;
        color: var(--c-primary);

        &::first-letter {
            text-transform: uppercase;
        }

        @include for-size(tablet-landscape-up) {
            font-size: 2rem;
        }
    }

    &__category-filter-buttons {
        margin-top: 2rem;

        @include for-size(tablet-landscape-up) {
            margin-top: 0;
        }
    }

    &__list {
        margin-top: 1.5rem;

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(2, 1fr);
            margin-top: 3.5rem;
        }
    }

    &__pagination {
        margin-top: 3.5rem;
    }

    &__category-list {
        margin-top: 2.5rem;

        @include for-size(tablet-landscape-up) {
            margin-top: 4.5rem;
        }
    }
}
