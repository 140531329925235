@import "src/styles";

.application-company-information {
    padding: 1.5rem;
    box-shadow: none;

    @include for-size(tablet-portrait-up) {
        padding: 2rem 2.5rem;
    }

    &__company-wrapper {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: currentColor;
    }

    &__company-logo {
        @include square(4.5rem);
    }

    &__company-text-wrapper {
        flex: 1;
        margin-left: 1rem;
    }

    &__company-name {
        font-size: 1.25rem;
    }

    &__company-city {
        margin-top: .5rem;
    }

    &__company-link-icon {
        height: 1rem;
        margin-left: 1rem;
    }

    &__employee-description {
        margin-top: 1.5rem;
        padding-top: 1rem;
        border-top: 1px solid var(--c-grey-light);
    }

    &__chat-button {
        @extend %button--outline;

        margin-top: 1rem;
    }
}
