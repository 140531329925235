.vacancy-properties-card {

    &__skeleton-list,
    &__list {
        margin-top: 2rem;
    }

    &__skeleton-item {

        & + & {
            margin-top: 1.5rem;
        }
    }

    &__skeleton {

        & + & {
            margin-top: .5rem;
        }
    }

    &__item {
        list-style-type: none;

        & + & {
            margin-top: 1.5rem;
        }
    }
}
