@import "src/styles";

.blog-category-list {

    &__title {
        font-size: 2rem;
        font-weight: var(--fw-bold);
        color: var(--c-primary);
    }

    &__list {
        margin-top: 1.5rem;
    }

    &__item {
        list-style-type: none;

        & + & {
            border-top: 1px solid var(--c-grey-light);
        }
    }

    &__button {
        @extend %button--no-background;

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 0;
        text-transform: none;
    }

    &__button-label {

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__info-wrapper {
        display: flex;
        align-items: center;
    }

    &__counter {
        display: none;
        margin-right: 1.5rem;

        @include for-size(tablet-landscape-up) {
            display: initial;
        }
    }
}
