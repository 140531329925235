.blog-renderer-skeletons {

    &__paragraph {

        & + & {
            margin-top: 2.5rem;
        }
    }

    &__subtitle {
        height: 1.5rem;
    }

    &__text {
        margin-top: 1rem;

        & + & {
            margin-top: .5rem;
        }
    }
}
