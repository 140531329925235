@import "src/styles";

.searchable-value-input-option {
    list-style-type: none;

    &__button {
        @extend %button--no-background;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 1rem;
        text-align: left;
        text-transform: none;
        color: var(--c-grey-dark);
        border-radius: 0;

        &:enabled:hover,
        &:enabled:focus {
            background-color: var(--c-grey-light);
        }
    }

    &__label {
        font-weight: var(--fw-regular);
    }

    &__query {
        color: var(--c-text);
        font-weight: var(--fw-semi-bold);
    }

    &__secondary-label {
        margin-top: .25rem;
        font-size: .875rem;
        text-transform: uppercase;
        color: var(--c-grey);
    }
}
