@import "src/styles";

.testimonials {
    position: relative;
    padding: 0 1rem;
    line-height: 1.5;

    @include for-size(tablet-landscape-up) {
        padding: 0 2rem;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 2;
        max-width: 74rem;
        margin: 0 auto;
        padding: 2rem 0;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            padding: 3rem 0;
        }
    }

    &__card-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;

        @include for-size(tablet-landscape-up) {
            justify-content: flex-start;
            max-width: 30rem;
        }
    }

    &__card {
        max-width: 30rem;
    }

    &__text-wrapper {
        width: 100%;
        margin-top: 4rem;

        @include for-size(tablet-landscape-up) {
            margin-top: 0;
            margin-left: 6rem;
            padding-top: 4.5rem;
        }
    }

    &__title {
        font-size: 2rem;
        font-weight: var(--fw-bold);
        color: var(--c-primary);
    }

    &__description {
        margin-top: 2rem;
        font-size: 1rem;
        color: var(--c-grey-dark);
    }

    &__button {
        margin-top: 2rem;
    }

    &__backdrop {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 10rem;
        background-color: var(--c-background);
    }
}
