.share-list {
    display: flex;

    &__item {
        list-style-type: none;
    }

    &__button {

        &:hover {
            cursor: pointer;
        }
    }
}
