@import "src/styles";

.search-input {

    &__wrapper {
        display: flex;
        border: 1px solid var(--c-grey-light);
        box-shadow: var(--shadow-medium);

        &--has-error {
            border: 2px solid var(--c-negative);
        }

        @include dark-mode {
            border: none;
        }
    }

    &__icon-wrapper {
        @extend %flex-align-center;

        padding: 1rem;
        background-color: var(--c-white);

        @include dark-mode {
            background-color: var(--c-background);
        }
    }

    &__icon {
        color: var(--c-grey-dark);
    }

    &__input {
        border-color: transparent;
        background-color: var(--c-white);
        box-shadow: none;
    }

    &__error {
        margin-top: .5rem;
    }
}
