@import "src/styles";

.blog {
    background-color: var(--c-white);

    &__content-wrapper {
        width: 100%;
        max-width: 74rem;
        margin: 0 auto;
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 0 2rem;
        }
    }

    &__breadcrumbs {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    &__title {
        font-size: 2.5rem;
        font-weight: var(--fw-bold);
        line-height: 1.5;
        color: var(--c-primary);
    }

    &__featured-wrapper {
        margin-top: 4rem;
    }

    &__blog-overview {
        margin-top: 1.5rem;

        @include for-size(tablet-landscape-up) {
            margin-top: 3.5rem;
        }
    }
}
