@import "src/styles";

.toast {
    display: flex;
    position: fixed;
    max-width: 36rem;
    padding: 1rem;
    padding-right: 5rem;
    line-height: 1.5;
    color: var(--c-text);
    border-radius: var(--border-radius);
    border-left: .5rem solid var(--c-grey);
    background-color: var(--c-white);
    box-shadow: var(--shadow-medium);
    opacity: 0;
    transition: transform var(--toast-transition-duration) ease, opacity var(--toast-transition-duration) ease;

    @include for-size(phone-only) {
        padding-right: 3rem;
    }

    &--position-top {
        top: calc(var(--navigation-menu-height) + 1rem);
    }

    &--position-bottom {
        bottom: 1rem;

        @include for-size(tablet-landscape-up) {
            bottom: 2rem;
        }
    }

    &--position-left {
        left: 1rem;
        margin-right: 1rem;
        transform: translateX(negative(calc(100% + 1rem)));

        @include for-size(tablet-landscape-up) {
            left: 2rem;
            margin-right: 2rem;
            transform: translateX(negative(calc(100% + 2rem)));
        }
    }

    &--position-right {
        right: 1rem;
        margin-left: 1rem;
        transform: translateX(calc((100% + 1rem)));

        @include for-size(tablet-landscape-up) {
            right: 2rem;
            margin-left: 2rem;
            transform: translateX(calc((100% + 2rem)));
        }
    }

    &--positive {
        border-color: var(--c-positive);
    }

    &--warning {
        border-color: var(--c-warning);
    }

    &--negative {
        border-color: var(--c-negative);
    }

    &--is-revealed {
        transform: translateX(0);
        opacity: 1;
    }

    &__icon-wrapper {
        display: none;
        align-items: center;
        margin-right: 1rem;

        @include for-size(tablet-portrait-up) {
            display: flex;
        }
    }

    &__title {
        font-size: 1.25rem;
    }

    &__description {
        margin-top: .5rem;
    }

    &__icon {
        height: 1rem;

        &--positive {
            color: var(--c-positive);
        }

        &--warning {
            color: var(--c-warning);
        }

        &--negative {
            color: var(--c-negative);
        }
    }

    &__close-button {
        @extend %button--no-background;

        position: absolute;
        top: 0;
        right: 0;
        padding: 1rem;
        color: inherit;
    }

    &__close-icon {
        height: 1rem;
    }
}
