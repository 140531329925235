@import "src/styles";

.vacancy-header-cover {
    @include profile-placeholder-background;

    position: relative;
    height: 20rem;
    background-color: var(--c-grey-light);
    background-size: clamp(4rem, 10vw, 16rem);
    background-position: -50%;

    &__play-button-wrapper {
        @extend %flex-align-center;
        @extend %click-extend;

        z-index: 1;
    }

    &__play-button {
        @extend %button--control;
        @include circle(5rem);

        color: var(--c-primary);
        box-shadow: var(--shadow-medium);
    }

    &__play-icon {
        transform: scale(1.25) translateX(.15rem);
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
