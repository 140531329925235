@import "src/styles";

$component: 'radio';

.#{$component} {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: var(--fw-medium);
    color: var(--c-grey-dark);
    cursor: pointer;

    &--is-disabled {
        opacity: .5;
        cursor: not-allowed;
    }

    &__input {
        position: absolute;
        opacity: 0;

        &:focus:not(:focus-visible) ~ .#{$component}__label {
            text-decoration: none;
        }

        &:focus ~ .#{$component}__label {
            text-decoration: underline;
        }

        &:checked  ~ .#{$component}__box::after {
            transform: scale(1);
        }
    }

    &__box {
        @extend %flex-align-center;
        @include circle(1.25rem);

        border: 1px solid var(--c-grey-dark);

        &::after {
            @include circle(.875rem);

            content: "";
            background-color: var(--c-text);
            transform: scale(0);
            transition: transform .1s ease;
        }
    }

    &__label {
        margin-left: .75rem;
    }
}
