@import "src/styles";

.related-blog-articles {

    &__wrapper {
        max-width: var(--blog-detail-wrapper-width-wide);
        margin: 0 auto;
    }

    &__carousel {
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 0 2rem;
        }
    }

    &__list {

        & > li {

            @include for-size(tablet-portrait-up) {

                &:nth-child(odd) {
                    margin-right: 1rem;
                }

                &:nth-child(even) {
                    margin-left: 1rem;
                }
            }
        }
    }
}
