@import "src/styles";

.vacancy-detail-page {
    position: relative;
    padding-top: 0;

    &__wrapper {
        width: 100%;
        max-width: 90rem;
        margin: 0 auto;
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 0 2rem;
        }
    }
}
