@import "src/styles";

.form-action-buttons {

    &__button {

        &--cancel {
            @extend %button--transparent-background;

            color: var(--c-text);
        }
    }
}
