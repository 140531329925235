.scroll-progress {
    --scroll-progress-percentage: 0%;

    position: relative;
    width: 100%;
    height: .5rem;
    overflow: hidden;
    background-color: var(--c-grey-light);

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
        background-color: var(--c-secondary);
        transform: translateX(calc(-100% + #{var(--scroll-progress-percentage)}));
    }
}
