.newsletter-form {

    &__input {
        box-shadow: none;
    }

    &__submit-button {
        margin-top: 1.25rem;
    }
}
