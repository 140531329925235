.location-check {
    position: relative;

    &__result {
        position: relative;
        margin-bottom: 1rem;
        padding: 1.5rem;
        background-color: var(--c-background);

        &--has-error {
          background-color: var(--c-negative);
        }
    }
}
