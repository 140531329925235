@import "src/styles";

.chat-room-header {
    @extend %flex-align-center;

    justify-content: flex-start;
    position: relative;
    z-index: 20;
    height: var(--message-overview-header-height);
    padding: 0 1.5rem;
    padding-left: .5rem;
    border-bottom: 1px solid var(--c-grey-light);
    background-color: var(--c-white);

    @include for-size(tablet-portrait-up) {
        position: sticky;
        top: var(--navigation-menu-height);
        padding-left: 1.5rem;
    }

    &__chat-selector-button {
        @extend %button--no-background;

        padding: 1rem;

        @include for-size(tablet-portrait-up) {
            display: none;
            visibility: hidden;
        }
    }

    &__skeletons {
        margin-right: auto;
    }

    &__title-wrapper {
        flex: 1;
    }

    &__title {
        font-size: 1.125rem;
    }

    &__profile-link {
        @extend %button--no-background;

        padding: 0;
        font-weight: var(--fw-medium);
        color: var(--c-link);
    }

    &__actions-button {
        @extend %button--no-background;

        margin-left: 1rem;
        padding-left: 0;
        padding-right: 0;
    }

    &__actions-navigation {
        top: calc(100% + .5rem);
        right: 1.5rem;
        width: 16.5rem;
    }
}
