@import "src/styles";

.candidate-profile-page {
    position: relative;

    &__breadcrumbs {
        margin-bottom: 1.5rem;
    }

    &__wrapper {
        width: 100%;
        max-width: 90rem;
        margin: 0 auto;
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 0 2rem;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-landscape-up) {
             flex-direction: row;
        }
    }

    &__header-card {
        width: 100%;
        box-shadow: none;

        & + & {
            margin-top: 1.5rem;

            @include for-size(tablet-landscape-up) {
                margin-top: 0;
                margin-left: 1.5rem;
            }
        }
    }

    &__progress {
        margin-top: 2rem;
    }

    &__card-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__main {
        width: 100%;
    }

    &__sidebar {
        width: 100%;
        margin-top: 2rem;

        @include for-size(tablet-landscape-up) {
            max-width: 24rem;
            margin-top: 0;
            margin-left: 2rem;
        }
    }

    &__card {

        & + & {
            margin-top: 2rem;
        }
    }
}
