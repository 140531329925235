@import "src/styles";

.application-delete-card {

    &__delete-button {
        @extend %button--negative;

        margin-top: 2rem;
    }
}
