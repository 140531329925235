@import "src/styles";

.application-status-accepted {

    &__description {
        margin-top: 1rem;
    }

    &__appointment {
        display: flex;
        align-items: center;
        margin-top: 1.5rem;
    }

    &__appointment-label {
        flex: 1;
        margin-left: .75rem;
        font-style: italic;
    }

    &__video-meeting-wrapper {
        margin-top: 1.5rem;
    }

    &__video-meeting-button {
        margin-top: 1rem;
    }

    &__or-divider {
        @extend %divider;

        margin-top: 1.5rem;
    }

    &__button-wrapper {
        margin-top: 1.5rem;
    }

    &__button {
        @extend %button--transparent-background;

        color: var(--c-text);

        & + & {
            margin-top: 1rem;
        }
    }
}
