.searchable-value-input {
    position: relative;

    &__result-list {
        max-height: 22em;
        margin: 0;
        padding: 0;
        overflow: auto;
        border: 1px solid var(--c-grey-light);
        border-top: none;
        background-color: var(--c-white);
    }

    &__result-option {

        & + & {
            border-top: 1px solid var(--c-grey-light);
        }
    }
}
