.sbb-people-card {
    padding: 2rem 2.5rem;

    &__title {
        font-size: 1.1875rem;
        font-weight: var(--fw-bold);
        line-height: 1.5;
    }

    &__table {
        margin-top: 1rem;
    }

    &__table-head {
        font-weight: var(--fw-bold);
    }

    &__table-cell {

        &:first-of-type {
            font-weight: var(--fw-bold);
        }

        &--phone {
            max-width: 12rem;
        }
    }
}
