@import "src/styles";

.company-header {

    &__details {
        padding: 1.5rem 2.5rem 2rem;
        background-color: var(--c-white);
    }

    &__information-wrapper {
        display: flex;
    }

    &__avatar-form,
    &__avatar {
        @include square(6rem);

        font-size: 1.75rem;
    }

    &__text-wrapper {
        margin-left: 1.25rem;
        line-height: 1.5;
    }

    &__company-name {
        font-size: 1.375rem;
        font-weight: var(--fw-bold);
    }

    &__city {
        font-weight: var(--fw-bold);
        color: var(--c-grey-dark);
    }

    &__address {
        margin-top: .25rem;
        color: var(--c-grey-dark);
    }

    &__error-label {
        margin-top: 1.5rem;
    }
}
