.loader {
    display: flex;
    align-items: center;
    color: var(--c-primary);

    &__text {
        margin-left: 1rem;
        font-size: 1rem;
        color: var(--c-primary);
    }
}
