@import "src/styles";

.chat-room-preview {
    position: relative;
    list-style-type: none;
    border-bottom: 1px solid var(--c-grey-light);

    &__select-button {
        display: flex;
        gap: 1rem;
        width: 100%;
        padding: 1rem 1.5rem;
        font-weight: initial;
        color: var(--c-grey-dark);
        text-align: initial;
        border-radius: 0;
        background-color: var(--c-white);

        &:hover,
        &:focus {
            background-color: var(--c-background);
        }

        &--is-active {
            color: var(--c-text);
            background-color: rgba(var(--c-secondary-rgb), .1);

            &:hover,
            &:focus {
                background-color: rgba(var(--c-secondary-rgb), .1);
            }
        }

        &--is-unread {
            color: var(--c-text);
        }

        &--is-reported {
            border-left: .25rem solid var(--c-negative);
        }
    }

    &__avatar {
        @include circle(3rem);

        font-size: .875rem;
    }

    &__text-wrapper {
        flex: 1;
    }

    &__title-wrapper {
        display: flex;
        justify-content: space-between;
    }

    &__title {
        font-size: 1rem;
        font-weight: var(--fw-semi-bold);
    }

    &__unread-indicator {
        @include circle(.875rem);

        background-color: var(--c-secondary);
    }

    &__message {
        @include line-clamp(2);

        margin-top: .5rem;
    }
}
