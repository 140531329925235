@import "src/styles";

.range-input {
    --range-input-width: 0px;
    --range-input-min: 0;
    --range-input-max: 100;
    --range-input-handle-size: 2rem;

    display: block;
    position: relative;
    z-index: 1;
    width: 100%;

    @include for-size(tablet-portrait-up) {
        --range-input-handle-size: 1.5rem;
    }

    &__label {
        margin-bottom: 2.5rem;
    }

    &__input-wrapper {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: .75rem;

        &--is-disabled {
            cursor: not-allowed;
            filter: grayscale(1);
            opacity: .5;
        }
    }

    &__handle {

        &--min {
            transform: translateX(calc(#{var(--range-input-width)} * var(--range-input-min)));
        }

        &--max {
            transform: translateX(calc(#{var(--range-input-width)} * var(--range-input-max)));
        }
    }

    &__marks {
        font-size: .875rem;
        opacity: .5;
    }

    &__error {
        margin-top: 1rem;
    }
}
