@import "src/styles";

.expandable-filter-button {
    @extend %button--no-background;

    display: flex;
    align-items: center;
    position: relative;
    padding: .75rem 1rem;
    text-transform: none;
    border: 1px solid var(--c-grey-light);

    &::after {
        @extend %click-extend;

        content: "";
        z-index: 10;
        background-color: var(--c-secondary);
        opacity: 0;
        transition: opacity .1s ease;
    }

    &--is-active {
        border-color: var(--c-secondary);

        &:disabled {
            border-color: var(--c-grey-light);

            &::after {
                background-color: var(--c-grey-light);
            }
        }

        &::after {
            opacity: 1;
        }
    }

    &__label {
        position: relative;
        z-index: 20;
    }

    &__icon {
        position: relative;
        z-index: 20;
        width: .75rem;
        margin-left: .75rem;
        transition: transform .1s ease;

        &--is-expanded {
            transform: rotateX(180deg);
        }
    }
}
