.label {
    display: inline-block;
    max-width: 100%;
    padding: .375rem 1rem;
    font-size: .875rem;
    font-weight: var(--fw-bold);
    text-transform: uppercase;
    letter-spacing: .075em;
    line-height: 1;
    color: var(--c-primary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 2rem;
    background-color: var(--c-secondary);

    &--is-dark {
        color: var(--c-white);
        background-color: var(--c-primary);
    }

    &--is-small {
        font-size: .75rem;
    }
}
