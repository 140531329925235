.vacancy-index-city {

    &__city-list {
        list-style-type: none;
    }

    &__city {
        color: var(--c-grey-dark);
        line-height: var(--vacancy-index-line-height);
    }
}
