@import "src/styles";

.information-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem;
    color: var(--c-grey-dark);
    border-radius: var(--border-radius);
    border: 1px solid var(--c-grey-light);

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
    }

    &__icon {
        height: 1.25rem;
    }

    &__text-wrapper {
        margin-top: .5rem;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &__title {
        margin-bottom: 1rem;
        font-size: 1rem;
    }

    &__description {

        & + & {
            margin-top: 1rem;
        }
    }
}
