@import "src/styles";

.candidate-overview-page {
    padding-top: 0;
    line-height: 1.5;

    &__wrapper {
        max-width: 90rem;
        margin: 2rem auto 0;
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 0 2rem;
        }
    }
}
