@import "src/styles";

.vacancy-detail {
    position: relative;

    &__breadcrumb-wrapper {
        margin-top: 2rem;
    }

    &__content-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 80vh;
        margin-top: 2rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__main {
        width: 100%;
    }

    &__sidebar {
        width: 100%;
        margin-top: 2rem;

        @include for-size(tablet-landscape-up) {
            max-width: 24rem;
            margin-top: 0;
            margin-left: 2rem;
        }
    }

    &__card {

        & + & {
            margin-top: 2rem;
        }
    }

    &__vacancy-overview {
        margin-top: 4rem;
    }
}
