@import "src/styles";

.error-page {
    line-height: 1.5;

    &__wrapper {
        width: 100%;
        max-width: 60rem;
        margin: 0 auto;
        padding: 0 1rem;

        @include for-size(tablet-portrait-up) {
            padding: 0 1.25rem;
        }
    }
}
