.appointment-form {

    &__type-input,
    &__message-input {
        margin-top: 2rem;
    }

    &__action-buttons {
        position: relative;
        z-index: 10;
        margin-top: 4rem;
    }
}
