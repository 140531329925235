.chat-event-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    overflow: auto;

    &__list {
        padding: 1.5rem;
        overflow: inherit;
    }

    &__scroll-button {
        position: absolute;
        left: 50%;
        bottom: 1rem;
        padding: .5rem 1rem;
        white-space: nowrap;
        pointer-events: none;
        transition: opacity .1s ease;
        opacity: 0;
        transform: translateX(-50%);

        &--is-visible {
            pointer-events: all;
            opacity: 1;
        }
    }
}
