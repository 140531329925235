@import "src/styles";

.skills-form-tips {
    padding: 2rem;
    line-height: 1.5;
    color: var(--c-white);
    background-color: var(--c-primary);

    &__title {
        font-size: 1.5rem;
    }

    &__tip-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }

    &__tip {
        position: relative;
        max-width: 18rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
        padding-left: 1rem;
        list-style-type: none;

        &:last-of-type {
            margin-bottom: 0;
        }

        &::before {
            @include circle(.375rem);

            content: "";
            position: absolute;
            top: .5rem;
            left: 0;
            background-color: var(--c-secondary);
        }
    }
}
