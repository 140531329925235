@import "src/styles";

.vacancy-company-card {
    line-height: 1.5;

    &__skeleton-wrapper,
    &__wrapper {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 1.5rem;
    }

    &__skeleton-logo,
    &__logo {
        @include square(4rem);
    }

    &__skeleton-line-wrapper {
        width: 100%;
    }

    &__skeleton-line {

        & + & {
            margin-top: .5rem;
        }
    }

    &__name {
        font-size: 1.125rem;
    }

    &__city {
        font-size: 1rem;
        color: var(--c-grey-dark);
    }

    &__button {
        @extend %button--outline;

        margin-top: 1.5rem;
    }
}
