@import "src/styles";

.vacancy-controls {
    display: flex;
    justify-content: flex-end;
    gap: .5rem;
    padding: 0;

    &__button {
        @extend %button--control;

        &:hover,
        &:focus {
            color: var(--c-primary);
            background-color: var(--c-grey-light);
        }
    }
}
