@import "src/styles";

.adding-card {
    padding: 2rem 2.5rem;
    color: var(--c-primary);
    box-shadow: none;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 2rem;
        border-bottom: 1px solid var(--c-grey-light);
    }

    &__title {
        font-size: 1.125rem;
        font-weight: var(--fw-bold);
        line-height: 1.5;
        color: currentColor;
    }

    &__add-button {
        @extend %button--control;

        position: relative;
        right: -.75rem;
        color: var(--c-primary);
    }

    &__add-icon {
        width: 1rem;
        height: 1rem;
    }
}
