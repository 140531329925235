$component: 'currency-icon';

.#{$component} {
    --c-currency-icon-bill: #57bc81;
    --c-currency-icon-bill-highlight: #90dbaf;
    --c-currency-icon-bill-shadow: #41b371;

    --c-currency-icon-coin: #ffca41;
    --c-currency-icon-coin-highlight: #ffe64e;

    &--is-visible {

        .#{$component}__bill {
            transform: translateX(0);
        }

        .#{$component}__coin {
            transform: rotate(0deg);
        }
    }

    &__overflow-path {
        transform: translateX(-2px);
    }

    &__content {
        clip-path: url(#currency-icon-overflow-clip);
    }

    &__bill {
        transform: translateX(-100%);
        transition: 1s transform ease;
        transition-delay: .3s;

        & + & {
            transition-delay: .5s;
        }
    }

    &__bill-border,
    &__bill-currency {
        fill: var(--c-currency-icon-bill-highlight);
    }

    &__bill-background {
        fill: var(--c-currency-icon-bill);
    }

    &__bill-circle {
        fill: var(--c-currency-icon-bill-shadow);
    }

    &__coin {
        transform: rotate(-180deg);
        transform-origin: bottom left;
        transition: 1s transform ease;
        transition-delay: .3s;

        & + & {
            transition-delay: .5s;
        }
    }

    &__coin-border,
    &__coin-currency {
        fill: var(--c-currency-icon-coin-highlight);
    }

    &__coin-background {
        fill: var(--c-currency-icon-coin);
    }

    &__joint {
        fill: none;
    }

    &__shadow {
        fill: var(--c-black);
        opacity: .05;
    }

    &__background {
        fill: var(--c-grey-light);
    }
}
