.application-overview {

    &__error {
        font-size: .875rem;
        margin: 0 0 2rem;
    }

    &__item {
        list-style-type: none;

        & + & {
            margin-top: 1rem;
        }
    }
}
