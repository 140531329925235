@import "src/styles";

.appointment-input {
    display: flex;
    flex-direction: column;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
    }

    &__date-input {
        width: 100%;
    }

    &__time-wrapper {
        display: flex;
        margin-top: 1rem;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &__time-input {
        width: 100%;

        @include for-size(tablet-portrait-up) {
            width: initial;
        }

        & + & {
            margin-left: 1rem;
        }
    }

    &__input-field {
        font-family: inherit;
    }

    &__remove-button-wrapper {
        @extend %flex-align-center;

        align-self: flex-end;
        height: 3rem;
    }

    &__remove-button {
        @extend %button--control;

        margin-left: .5rem;
    }
}
