@import "src/styles";

$component: 'scroll-hinter';

.#{$component} {
    @extend %flex-align-center;

    position: relative;
    cursor: pointer;
    border: none;
    background: none;

    &:hover,
    &:focus {
        outline: 0;

        .#{$component}__icon-wrapper {
            transform: scale(1.1);
        }

        .#{$component}__pulse {
            animation-play-state: paused;
            background-color: transparent;
        }
    }

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(2.25rem);

        position: absolute;
        z-index: 10;
        background-color: var(--c-white);
        transition: transform .1s ease;

        @include for-size(tablet-landscape-up) {
            @include circle(4rem);
        }
    }

    &__icon {
        height: 1rem;
        color: var(--c-text);

        @include for-size(tablet-landscape-up) {
            height: 1.25rem;
        }
    }

    &__pulse {
        @include circle(3.125rem);

        position: relative;
        z-index: 0;
        animation: pulse 1.35s ease-out infinite;
        background-color: var(--c-grey);
        transition: background-color .3s ease;

        @include for-size(tablet-landscape-up) {
            @include circle(5.625rem);
        }
    }
}
