@import "src/styles";

.candidate-avatar-card {
    padding: 2rem 2.5rem;
    line-height: 1.5;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__avatar-form,
    &__avatar {
        @include circle(6rem);

        font-size: 2rem;

        @include for-size(tablet-portrait-up) {
            @include circle(12rem);

            font-size: 3rem;
        }
    }

    &__personal-info-wrapper {
        margin-top: 1.5rem;
        text-align: center;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 3.5rem;
            text-align: left;
        }
    }

    &__name {
        font-size: 1.5rem;
        font-weight: var(--fw-bold);
    }

    &__city {
        margin-top: .5rem;
        font-size: 1.25rem;
        font-weight: var(--fw-medium);
        color: var(--c-grey-dark);
    }

    &__edit-button {
        @extend %button--control;

        position: absolute;
        top: 1.25rem;
        right: 1.75rem;

        &:hover,
        &:focus {
            color: var(--c-primary);
            background-color: var(--c-grey-light);
        }
    }

    &__tag-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 1.25rem;

        @include for-size(tablet-portrait-up) {
            justify-content: flex-start;
        }
    }

    &__tag {
        font-weight: var(--fw-semi-bold);

        & + & {
            margin-left: 1rem;
        }
    }

    &__error-label {
        margin-top: 1.5rem;
    }
}
