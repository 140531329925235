.mobile-navigation {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    &__controls {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        z-index: 20;
    }

    &__hamburger-button {
        margin-left: 1.5rem;
    }

    &__drawer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        background-color: var(--c-white);
        transition: transform .3s ease;
        transform: translateX(100%);

        &--is-expanded {
            transform: translateX(0);
        }
    }

    &__header {
        display: flex;
        align-items: center;
        height: var(--navigation-menu-height);
        padding: 1rem 1.75rem;
        border-bottom: 1px solid var(--c-grey-light);
    }

    &__navigation-title,
    &__list-title {
        font-size: 1rem;
        font-weight: var(--fw-semi-bold);
        line-height: 1.5;
        color: var(--c-text);
    }

    &__static-hamburger-icon {
        width: 1.125rem;
    }

    &__navigation-title {
        margin-left: 1.25rem;
    }

    &__segment-wrapper {
        margin-bottom: auto;
        padding: 1.75rem;
        overflow: auto;
    }

    &__segment {

        & + & {
            margin-top: 1rem;
        }
    }

    &__list {
        margin-top: 1rem;
    }

    &__footer {
        padding: 1rem 1.75rem;
        border-top: 1px solid var(--c-grey-light);
    }
}
