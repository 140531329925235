@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');

@import "variables";
@import "animations";

:focus:not(:focus-visible) {
    outline: none;
}

* {
    box-sizing: border-box;
}

// TODO: Remove rule once styled components has been removed from the project
html {
    font-size: 16px !important;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--f-primary);
    -webkit-font-smoothing: antialiased;
    color: var(--c-text);
    background-color: var(--c-white);

    &.has-active-modal {
        overflow: hidden !important; // Important, because somewhere document.body.style gets an overflow auto
    }
}

blockquote,
q {
    font-style: italic;

    &::before { content: open-quote; }
    &::after { content: close-quote; }
}

#root,
#portal-root {
    position: relative;
    z-index: 100;
}

#portal-root {
    z-index: 200;
}
