@import "src/styles";

.sbb-sub-competence-collection {

    &__row {
        padding-left: 2.25rem;

        &--skill {
            padding-left: 3.75rem;
        }
    }

    &__cell {

        &--task {
            font-weight: var(--fw-bold);
        }
    }

    &__cell-skill {
        display: flex;
        align-items: center;
        position: relative;
        font-weight: var(--fw-regular);

        &::before {
            @include circle(.375rem);

            content: "";
            display: block;
            margin-right: .5rem;
            background-color: var(--c-grey);
        }
    }
}
