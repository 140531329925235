.vacancy-matches-filter-desktop {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    max-width: calc(100% - 8rem);

    &__drivers-licenses {
        width: 20rem;
    }
}
