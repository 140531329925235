@import "src/styles";

.catchphrase-card {
    padding: 2rem 2.5rem;
    line-height: 1.5;

    &__skeleton {

        & + & {
            margin-top: 1rem;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__tag {
        font-weight: var(--fw-semi-bold);
    }

    &__catchphrase {
        max-width: 28rem;
        margin-top: 1.5rem;
        font-size: 1.25rem;
        font-weight: var(--fw-semi-bold);
        font-style: normal;
        color: var(--c-primary);
    }

    &__controls-wrapper {
        display: flex;
        align-items: center;
        margin-left: auto;
        transform: translateX(1rem);
    }

    &__edit-button {
        @extend %button--control;

        & + & {
            margin-left: .5rem;
        }
    }
}
