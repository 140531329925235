@import "src/styles";

.role-info-benefits {
    position: relative;
    padding: 0 1rem;

    @include for-size(tablet-landscape-up) {
        padding: 0 2rem;
    }

    &__wrapper {
        position: relative;
        z-index: 2;
        max-width: 87.5rem;
        margin: 0 auto;
        padding: 2rem 0;
    }

    &__title {
        font-size: 2rem;
        font-weight: var(--fw-bold);
        text-align: center;
        color: var(--c-primary);
    }

    &__card-list {
        display: flex;
        flex-direction: column;
        margin-top: 3rem;

        @include for-size(tablet-landscape-up) {
            margin-top: 4.5rem;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__card-item {
        width: 100%;
        list-style-type: none;

        & + & {
            margin-top: 3rem;

            @include for-size(tablet-landscape-up) {
                margin-top: 0;
                margin-left: 2rem;
            }
        }
    }

    &__card {
        position: relative;
        height: 100%;
        padding: 2rem;
        padding-top: 2.5rem;
        line-height: 1.5;
    }

    &__check {
        @extend %flex-align-center;
        @include circle(3rem);

        position: absolute;
        top: 0;
        left: 2rem;
        background-color: var(--c-secondary);
        transform: translateY(-50%);
    }

    &__check-icon {
        height: .75rem;
        color: var(--c-primary);
    }

    &__card-title {
        font-size: 1.5rem;
        font-weight: var(--fw-bold);
        color: var(--c-primary);
    }

    &__card-description {
        margin-top: 1rem;
        font-size: 1rem;
        color: var(--c-grey-dark);
    }

    &__backdrop {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 10rem;
        background-color: var(--c-background);
    }
}
