$component: 'menu-list';

.#{$component} {
    display: flex;

    &--template-information {
        flex-direction: column;

        .#{$component}__item {

            & + .#{$component}__item {
                margin: 0;
            }
        }
    }

    &--template-buttons {

        .#{$component}__item {

            & + .#{$component}__item {
                margin-left: 1.5rem;
            }
        }
    }

    &--template-drawer {
        flex-direction: column;

        .#{$component}__item {

            & + .#{$component}__item {
                margin: 0;
            }
        }
    }

    &--template-session {
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    &--template-icon {

        .#{$component}__item {

            & + .#{$component}__item {
                margin: 0;
            }
        }
    }

    &__item {
        list-style-type: none;

        & + & {
            margin-left: .5rem;
        }
    }
}
