@import "src/styles";

.talent-matching-quote {
    display: flex;
    align-items: center;
    color: var(--c-grey-dark);

    &__quote {
        font-family: var(--f-script);
        font-size: 1.5rem;
        font-weight: var(--fw-bold);

        &::before,
        &::after {
            content: "";
        }

        @include for-size(tablet-portrait-up) {
            max-width: 10rem;
        }
    }

    &__arrow {
        display: none;
        width: 2.5rem;
        margin-left: 1rem;
        fill: none;
        stroke: currentColor;
        stroke-width: 6;
        stroke-linecap: round;

        @include for-size(tablet-portrait-up) {
            display: block;
        }
    }

    &__arrow-swirl {
        @include stroke-dash-size(180);

        animation: reset-dashoffset 1s ease forwards;
        animation-delay: 1s;
    }

    &__arrow-head {
        @include stroke-dash-size(64);

        animation: reset-dashoffset .5s ease forwards;
        animation-delay: 2s;
    }
}
