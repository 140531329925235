.related-vacancies {

    &__title {
        font-size: 1.5rem;
    }

    &__list {
        margin-top: 2rem;
    }
}
