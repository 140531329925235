@import "src/styles";

.theatre {
    @extend %flex-align-center;

    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 20;
    padding: 1rem;
    background-color: rgba(var(--c-black-rgb), .75);
    transition: opacity var(--theatre-transition-duration) ease;
    opacity: 0;

    @include for-size(tablet-portrait-up) {
        padding: 4rem;
    }

    @include for-size(tablet-landscape-up) {
        padding: 8rem;
    }

    &--is-revealed {
        opacity: 1;
    }

    &__close-button {
        @extend %button--control;

        position: absolute;
        top: 0;
        right: 0;
        z-index: 30;
        margin: 1.75rem;
        color: var(--c-primary);
    }

    &__video-wrapper {
        width: 100%;
        max-width: 90rem;
    }

    &__video {
        width: 100%;
        background-color: var(--c-grey-light);
    }
}
