.sbb-approved-label {
    display: flex;
    align-items: center;
    padding: .25rem .5rem;
    font-weight: var(--fw-medium);
    border-radius: 2rem;
    border: 1px solid var(--c-secondary);

    &__icon {
        height: 1.25rem;
        color: var(--c-secondary);
    }

    &__icon-accent {
        color: var(--c-primary);
    }

    &__label {
        margin-left: .5rem;
    }
}
