@import "src/styles";

.vacancy-share-card {

    &__button-wrapper {
        margin-top: 2rem;
    }

    &__button {
        @extend %button--outline;

        width: 100%;

        & + & {
            margin-top: 1.25rem;
        }
    }

    &__link-button {
        @extend %button;

        display: flex;
        align-items: center;
        text-decoration: none;
    }

    &__button-icon {
        max-height: 1rem;
        max-width: 1rem;
        margin-right: 1.25rem;
    }

    &__share-wrapper {
        margin-top: 2.5rem;
        border-top: 1px solid var(--c-grey-light);
    }

    &__share-list {
        margin-top: 1.5rem;
    }

    &__share-item {

        & + & {
            margin-left: 1rem;
        }
    }
}
