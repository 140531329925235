@import "src/styles";

.job-vacancy-overview-filter-sidebar {

    &__form {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include touch-screen {
            flex-direction: column-reverse;
            padding-bottom: 5rem;
        }
    }

    &__property-wrapper {
        position: relative;
        overflow: auto;
        overflow-x: hidden;
    }

    &__property {
        padding: 1.5rem 1rem;

        @include for-size(tablet-portrait-up) {
            padding: 1.5rem 2rem;
        }

        &:nth-of-type(odd) {
            background-color: var(--c-background);
        }
    }

    &__footer {
        display: flex;
        flex: 0 0 auto;
        padding: 1rem;
        background-color: var(--c-white);
        border-top: 1px solid var(--c-grey-light);

        @include for-size(tablet-portrait-up) {
            padding: 1rem 2rem;
        }

        @include touch-screen {
            border-top: none;
            border-bottom: 1px solid var(--c-grey-light);
        }
    }

    &__reset-button {
        @extend %button--transparent-background;

        order: -1;
        margin-right: 1rem;
        color: var(--c-text);
    }
}
