@import "src/styles";

.job-vacancy-data-step {
    line-height: 1.5;

    &__card {

        & + & {
            margin-top: 1.5rem;
        }

        @include for-size(tablet-portrait-up) {
            padding: 2.5rem 3rem;
        }
    }

    &__card-title {
        margin-bottom: 1.5rem;
        font-weight: var(--fw-bold);
        color: var(--c-primary);
    }

    &__form-elements {
        margin-top: 2rem;
    }

    &__form-input {
        display: block;
        margin-bottom: 1.25rem;
    }

    &__form-row {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.25rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__employment-hour-range {
        max-width: 18rem;
        margin-bottom: 2rem;
    }

    &__apply-anonymous {
        margin-top: 1.5rem;
    }

    &__apply-anonymous-disclaimer {
        margin-top: 1rem;
    }

    &__form-col {
        width: 100%;
        margin-bottom: 1rem;

        @include for-size(tablet-portrait-up) {

            &:first-child {
                margin-right: .625rem;
            }

            &:last-child {
                margin-left: .625rem;
            }
        }
    }

    &__occupation-esco-disclaimer {
        font-size: .875rem;
    }

    &__occupation-input {
        margin-top: 1.5rem;
    }

    &__occupation-link {
        @extend %button--no-background;

        margin-top: 1rem;
        padding: 0;
        color: var(--c-link);
    }

    &__occupation-disclaimer {
        margin-top: 1.5rem;
        font-size: .875rem;
    }
}
