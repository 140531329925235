@import "src/styles";

.blog-header {
    position: relative;
    padding: 0 1rem 4rem;
    color: var(--c-white);

    @include for-size(tablet-landscape-up) {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    &__poster {
        position: relative;
        z-index: 20;
        max-width: var(--blog-detail-wrapper-width);
        margin: 0 auto;
        background-color: var(--c-grey-light);
        background-image: var(--blog-header-poster-url);
        background-size: cover;
        background-position: center;

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1rem;
            background: linear-gradient(180deg, rgba(var(--c-white-rgb), .5) 0%, var(--c-background) 100%);

            @include for-size(tablet-landscape-up) {
                content: "";
            }
        }
    }

    &__content-wrapper {
        position: relative;
        z-index: 20;
        padding: 1.5rem;

        @include for-size(tablet-landscape-up) {
            padding: 2.5rem;
            padding-bottom: 3.5rem;
        }
    }

    &__category-list {
        display: flex;
    }

    &__category-item {
        list-style-type: none;

        & + & {
            margin-left: 1rem;
        }
    }

    &__title {
        max-width: 28rem;
        margin-top: 2.5rem;
        font-size: 2rem;
        font-weight: var(--fw-bold);
        line-height: 1.25;
    }

    &__read-time {
        margin-top: 1.5rem;
        font-size: 1rem;
    }

    &__updated-date {
        margin-top: 3rem;
        font-size: 1rem;
        font-style: italic;

        @include for-size(tablet-landscape-up) {
            margin-top: 6rem;
        }
    }

    &__poster-gradient {
        @extend %click-extend;

        z-index: 10;
        background: linear-gradient(270deg, transparent 0%, var(--c-primary) 100%);

        @include for-size(tablet-landscape-up) {
            height: calc(100% - 1rem);
            background: linear-gradient(270deg, transparent 25%, var(--c-primary) 100%);
        }
    }

    &__backdrop {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 12rem;
        background-color: var(--c-background);

        @include for-size(tablet-landscape-up) {
            height: 18.75rem;
        }
    }
}
