@import "src/styles";

$component: 'llo-candidate-label';

.#{$component} {
    @extend %flex-align-center;
    @include circle(2.5rem);

    background-color: rgba(var(--c-llo-rgb), .15);

    &--is-small {
        @include circle(2rem);

        .#{$component}__logo {
            height: .875rem;
        }
    }

    &__logo {
        height: 1.125rem;
    }
}
