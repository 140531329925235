@import "src/styles";

$component: vacancy-overview-page;

.#{$component} {
    --matching-card-width: 26rem;

    padding-top: 0;

    &__talent-matching-card-wrapper {
        display: none;

        @include for-size(tablet-landscape-up) {
            display: block;
        }
    }

    &--is-talent-matching {

        .#{$component}__talent-matching-card-wrapper {
            display: block;

            @include for-size(tablet-landscape-up) {
                min-width: calc(var(--matching-card-width) + 2rem);
            }
        }

        .#{$component}__talent-matching-card {

            @include for-size(tablet-landscape-up) {
                transition: transform .5s ease;
                transform: translateX(0);
            }

            @media (prefers-reduced-motion) {
                transition-duration: 0s;
            }
        }
    }

    &__header,
    &__main {
        position: relative;
        padding: 1rem 0;

        @include for-size(tablet-landscape-up) {
            padding: 2rem 0;
        }
    }

    &__header {
        z-index: 20;
        background-color: var(--c-white);
    }

    &__main {
        z-index: 10;
    }

    &__wrapper {
        width: 100%;
        max-width: 90rem;
        margin: 0 auto;
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 0 2rem;
        }
    }

    &__title-wrapper {
        position: relative;
        z-index: 20;
    }

    &__result-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 10;
        margin-top: 1.5rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            align-items: flex-start;
        }
    }

    &__talent-matching-card-wrapper {

        @include for-size(tablet-landscape-up) {
            position: sticky;
            top: calc(var(--navigation-menu-height) + 1.5rem);
            width: 0;
            min-width: 0;
            transition: min-width .3s ease;
        }

        @media (prefers-reduced-motion) {
            transition-duration: 0s;
        }
    }

    &__talent-matching-card {

        @include for-size(tablet-landscape-up) {
            width: var(--matching-card-width);
            transform: translateX(-65rem);
            transition: transform .3s ease-in;
        }

        @include for-size(big-desktop-up) {
            transform: translateX(-50vw);
        }

        @media (prefers-reduced-motion) {
            transition-duration: 0s;
        }
    }

    &__result-list {
        width: 100%;

        &:not(:only-child) {
            margin-top: 1.5rem;

            @include for-size(tablet-landscape-up) {
                margin-top: 0;
            }
        }
    }
}
