.vacancy-matches-overview {

    &__error {
        font-size: .875rem;
        margin-bottom: 1.5rem;
    }

    &__null-state {
        margin-top: 3rem;
        font-size: 1rem;
    }

    &__skeleton {
        padding: 2rem;
    }

    &__candidate-list {
        max-width: 74rem;
        margin-top: 1.5rem;
        padding: 0;
    }

    &__candidate-item {
        list-style-type: none;

        & + & {
            margin-top: 1rem;
        }
    }

    &__pagination {
        margin-top: 5rem;
    }
}
