@import "src/styles";

.skill-results {

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;
        max-height: 24rem;
        overflow-y: auto;
        margin: 0;
        padding: 1rem 0;
    }

    &__item {
        list-style-type: none;
    }

    &__button-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        padding-top: 1rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }

        &::before {
            content: "";
            position: absolute;
            top: -1rem;
            left: 0;
            z-index: 10;
            width: 100%;
            height: 1rem;
            pointer-events: none;
            background: linear-gradient(0deg, rgba(var(--c-white-rgb), 1) 0%,  rgba(var(--c-white-rgb), 0) 100%);
        }
    }

    &__reset-button {
        @extend %button--transparent-background;

        color: var(--c-text);
    }

    &__create-button {
        margin-top: 1rem;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }
}
