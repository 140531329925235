.account-settings {

    &__card {
        padding: 2.5rem 2rem;
        line-height: 1.5;

        & + & {
            margin-top: 1.5rem;
        }

        &-title {
            font-size: 1.5rem;
        }

        &-description {
            margin-top: 1rem;
        }

        &-form {
            margin-top: 2rem;
        }
    }
}
