@import "src/styles";

.vacancy-authentication-card {
    color: var(--c-white);
    background-color: var(--c-primary);

    &__description {
        margin-top: 2rem;
        line-height: 1.5;
    }

    &__button-list {
        margin-top: 2rem;
    }

    &__login-button {
        @extend %button--transparent-background;

        color: var(--c-white);
    }
}
