@import "src/styles";

.sbb-recognition-table-row {

    &--has-sub-recognitions {

        &:hover,
        &:focus-within {
            background-color: var(--c-grey-light);
        }
    }

    &__icon {
        height: .875rem;
        margin-right: 1rem;
        color: var(--c-grey);
    }

    &__cell {
        padding-right: .5rem;

        &:first-of-type {
            font-weight: var(--fw-bold);
        }

        &:last-of-type {
            max-width: 2rem;
            padding: 0;
        }

        &--crebo {
            max-width: var(--sbb-recognition-table-crebo-width);
        }

        &--title {
            max-width: var(--sbb-recognition-table-title-width);
        }

        &--level {
            max-width: var(--sbb-recognition-table-level-width);
        }

        &--date {
            max-width: var(--sbb-recognition-table-date-width);
        }

        &--has-expand-button {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__expand-button {
        @extend %button--no-background;

        font-size: .75rem;

        &::after {
            @extend %click-extend;

            content: "";
        }
    }
}
