@import "src/styles";

.candidate-match-card {

    &__skeleton,
    &__wrapper {
        margin-top: 2rem;
    }

    &__button {
        margin-top: 1.5rem;

        &--for-ignore {
            @extend %button--transparent-background;

            margin-top: 1rem;
            color: var(--c-text);
        }
    }
}
