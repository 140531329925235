@import "src/styles";

$component: 'detail-card';

.detail-card {
    padding: 1.5rem;
    color: var(--c-primary);
    box-shadow: none;

    @include for-size(tablet-portrait-up) {
        padding: 2rem 2.5rem;
    }

    &--has-description {

        .#{$component}__header {
            flex-direction: column;
            align-items: flex-start;
            padding-bottom: 1.5rem;

            @include for-size(tablet-portrait-up) {
                flex-direction: row;
            }
        }

        .#{$component}__control-wrapper {
            margin-top: 1rem;
            margin-left: auto;

            @include for-size(tablet-portrait-up) {
                margin: 0;
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 2rem;
        border-bottom: 1px solid var(--c-grey-light);
    }

    &__title {
        font-size: 1.125rem;
        font-weight: var(--fw-bold);
        line-height: 1.5;
        color: currentColor;
    }

    &__description {
        margin-top: 1rem;
        color: var(--c-grey-dark);
    }

    &__control-wrapper {
        display: flex;
        align-items: center;
        transform: translateX(1rem);
    }

    &__edit-button {
        @extend %button--control;

        & + & {
            margin-left: .5rem;
        }
    }
}
