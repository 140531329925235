@import "src/styles";

.footer-end {
    color: currentColor;
    background-color: var(--c-black);

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 90rem;
        min-height: 6rem;
        margin: 0 auto;
        padding: 2rem 1rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            padding: 1.25rem;
        }
    }

    &__column {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;

        @include for-size(tablet-portrait-up) {
            display: block;
            width: 50%;
            text-align: inherit;
        }

        & + & {
            margin-top: 1.5rem;

            @include for-size(tablet-portrait-up) {
                width: 25%;
                margin-top: 0;
            }
        }
    }

    &__link {
        font-size: 1rem;
        text-decoration: none;
        color: currentColor;

        &--logo {
            display: inline-flex;
            align-items: center;
        }

        @include for-size(tablet-portrait-up) {
            opacity: .7;
            transition: opacity .1s ease-out;

            &:hover,
            &:focus {
                opacity: 1;
            }
        }
    }

    &__logo {
        min-height: initial;
        height: .625rem;
        margin-left: .5rem;
    }
}
