.school-experience-form {

    &__disclaimer {
        margin-bottom: 2rem;
    }

    &__input {

        & + & {
            margin-top: 2rem;
        }

        &--start-date {
            position: relative;
            z-index: 30;
        }

        &--end-date {
            position: relative;
            z-index: 20;
        }
    }

    &__action-buttons {
        position: relative;
        z-index: 10;
        margin-top: 4rem;
    }
}
