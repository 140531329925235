@import "src/styles";

.blog-renderer {
    font-size: 1rem;
    line-height: 1.5;

    &__created-date {
        font-style: italic;
    }

    &__intro {
        margin-top: 2.5rem;
        font-weight: var(--fw-bold);
        color: var(--c-primary);
        white-space: pre-line;
    }

    &__body {
        margin-top: 1.5rem;
    }

    &__slice {

        &--text,
        &--image {

            & + & {
                margin-top: 2.5rem;
            }
        }

        &--text {
            word-break: break-word;

            & > * + * {
                margin-top: 1.5rem;
            }

            h2, h3 {
                margin-top: 2.5rem;
                color: var(--c-primary);

                & + * {
                    margin-top: .5rem;
                }
            }

            ul, ol {
                padding-left: 1.25rem;
            }

            li {

                & + li {
                    margin-top: .5rem;
                }
            }

            pre {
                padding: 1.5rem;
                font-weight: bold;
                color: var(--c-white);
                background-color: var(--c-primary);
            }
        }

        &--image {
            display: flex;
            flex-wrap: wrap;

            img {
                width: 100%;
                margin-bottom: 1.5rem;

                @include for-size(tablet-landscape-up) {
                    width: calc(50% - (1.5rem / 2));

                    &:nth-child(2n + 2) {
                        margin-left: 1.5rem;
                    }

                    &:only-child {
                        width: 100%;
                        margin: 0;
                    }
                }
            }
        }
    }
}
