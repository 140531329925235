.competencies-form {

    &__occupation-wrapper {
        margin-top: 2rem;
        padding-top: 2rem;
        border-top: 1px solid var(--c-grey-light);
    }

    &__occupation-title {
        font-size: 1.125rem;
    }

    &__occupation-description {
        margin-top: .5rem;
    }

    &__occupation-input {
        margin-top: 1rem;
    }

    &__action-buttons {
        position: relative;
        z-index: 10;
        margin-top: 4rem;
    }
}
