@import "src/styles";

.filter-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.5rem;
    padding: 1rem 1.5rem;

    &__icon {
        width: 1.25rem;
        margin-right: 1rem;
    }

    &__active-filter-count {
        @extend %flex-align-center;
        @include circle(1.5rem);

        margin-left: 1rem;
        font-size: .875rem;
        font-weight: var(--fw-bold);
        line-height: 1;
        color: var(--c-always-black);
        background-color: var(--c-always-white);
    }
}
