@import "src/styles";

.avatar {
    @extend %flex-align-center;

    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    overflow: hidden;
    border-radius: 2px;
    border: 1px solid var(--c-grey-light);
    background-color: var(--c-always-white);

    &--has-initials {
        background-color: var(--c-background);
    }

    &--is-anonymous {
        background-color: var(--c-secondary);
    }

    &__anonymous-star {
        height: 50%;
        color: var(--c-always-black);
        transform: translateY(-2.5%);
    }

    &__anonymous-star-accent {
        color: var(--c-always-white);
    }

    &__initials {
        color: var(--c-text);
        user-select: none;

        &::after {
            content: attr(data-initials);
            position: absolute;
            top: 50%;
            left: 50%;
            opacity: .025;
            transform:
                translate(-50%, -50%)
                rotate(-22.5deg)
                scale(3);
        }
    }

    &__image {
        width: 100%;

        &--is-cover {
            width: auto;
            height: 100%;
        }
    }
}
