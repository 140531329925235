.application-status-declined {

    &__description {
        margin-top: 1rem;
    }

    &__message {
        margin-top: 1rem;
        padding: 1rem;
        background-color: var(--c-grey-light);
    }

    &__no-message {
        margin-top: 1rem;
        font-style: italic;
    }
}
