@import "src/styles";

$component: 'info-tag';

.#{$component} {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: .5rem;
    font-size: 1rem;
    font-weight: var(--fw-semi-bold);
    line-height: 1;
    color: var(--c-always-white);
    border-radius: var(--border-radius);
    background-color: var(--c-always-black);

    &:hover {

        .#{$component}__tooltip {
            transform: translateX(-50%) translateY(-.75rem);
            opacity: 1;
        }
    }

    &__icon {
        height: 1.125rem;
        margin-left: .5rem;
    }

    &__tooltip {
        position: absolute;
        left: 50%;
        bottom: 100%;
        width: 86vw;
        max-width: 24rem;
        padding: .5rem 1rem;
        line-height: 1.5;
        pointer-events: none;
        border-radius: var(--border-radius);
        transition: transform .1s ease, opacity .1s ease;
        transform: translateX(-50%) translateY(0);
        opacity: 0;
        background-color: var(--c-grey-dark);

        @include dark-mode {
            background-color: var(--c-always-black);
        }

        &::after {
            @include square(.75rem);

            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 50%) rotate(45deg);
            transform-origin: center;
            background-color: inherit;
        }
    }
}
