@import "src/styles";

.footer {
    position: relative;
    font-size: 1rem;
    color: var(--c-always-white);
    background-color: var(--c-primary);

    &__scroll-hint-wrapper {
        position: relative;
        max-width: 90rem;
        margin: 0 auto;
    }

    &__scroll-hint {
        position: absolute;
        top: 0;
        right: 1rem;
        transform: translateY(-50%);

        @include for-size(tablet-portrait-up) {
            right: 1.25rem;
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        max-width: 90rem;
        margin: 0 auto;
        padding: 4rem 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 4rem 2rem;
        }
    }

    &__column {
        width: 100%;
        margin-bottom: 2.5rem;

        &--info { order: 0 }
        &--quick-links { order: 1 }
        &--contact { order: 2 }
        &--newsletter { order: 3 }

        @include for-size(tablet-portrait-up) {
            width: 50%;
            margin-bottom: 2rem;

            &--quick-links { order: 2 }
            &--contact { order: 1 }

        }

        @include for-size(tablet-landscape-up) {
            width: 25%;
            margin-bottom: 0;

            &--quick-links { order: 1 }
            &--contact { order: 2 }
        }
    }

    &__title {
        font-size: 1.5rem;
        font-weight: var(--fw-bold);
    }

    &__logo {
        height: 1.75rem;
        color: var(--c-always-white);
    }

    &__social-link-list,
    &__info-link-list,
    &__quick-link-list,
    &__address-wrapper,
    &__newsletter-form {
        margin-top: 2rem;
        line-height: 1.5;
    }

    &__address-wrapper {
        margin-top: 2rem;
        line-height: 2;
    }

    &__address {
        font-style: normal;
    }

    &__contact-details {
        margin-top: 2rem;
    }
}
