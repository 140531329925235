@import "src/styles";

.vacancy-index {
    --vacancy-index-line-height: 2;

    &__breadcrumbs {
        margin-bottom: .5rem;
    }

    &__category {

        & + & {
            margin-top: 2.5rem;
        }
    }

    &__all-vacancies-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 3.5rem;
    }

    &__all-vacancies-button {
        @extend %button--no-background;

        margin: 0 auto;
        border: 1px solid var(--c-grey-light);
    }
}
