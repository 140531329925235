.vacancy-index-field-of-study-city {

    &__city-list {
        list-style-type: none;
    }

    &__city {

        & + & {
            margin-top: 1rem;
        }
    }
}
