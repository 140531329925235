.company-kvk-finder {

    & + & {
        margin-top: 2rem;
    }

    &__title {
        font-size: 1.25rem;
    }

    &__input-wrapper {
        margin-top: 1.5rem;
    }

    &__input {
        display: block;
        width: 100%;

        & + & {
            margin-top: 1.5rem;
        }
    }

    &__submit-button {
        margin-top: 2rem;
    }

    &__loader {
        margin-top: 2rem;
    }

    &__results-list {
        margin-top: 2rem;
    }
}
