.vacancy-index-occupation-province {

    &__province-list {
        list-style-type: none;
    }

    &__province {

        & + & {
            margin-top: 1rem;
        }
    }

    &__city-link {
        margin-top: .25rem;
    }
}
