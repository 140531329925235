@import "src/styles";

.files-to-upload-list-item {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1rem;
    color: var(--c-grey-dark);

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__icon {
        align-self: flex-start;

        @include for-size(tablet-portrait-up) {
            align-self: center;
        }
    }

    &__meta-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        overflow: hidden;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: center;
            margin-top: 0;
        }
    }

    &__name {
        max-width: 24rem;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--c-primary);

        @include for-size(tablet-portrait-up) {
            margin-left: 1rem;
        }
    }

    &__size {

        @include for-size(tablet-portrait-up) {
            margin-left: auto;
        }
    }

    &__delete-button {
        @extend %button--control;

        position: absolute;
        top: .25rem;
        right: .25rem;

        @include for-size(tablet-portrait-up) {
            position: static;
            margin-left: 1rem;
        }
    }
}
