@import "src/styles";

.home-page {
    padding: 0;
    background-color: var(--c-white);

    &__wrapper {
        width: 100%;
        max-width: 60rem;
        margin: 0 auto;
        padding: 0 1rem;

        @include for-size(tablet-portrait-up) {
            padding: 0 1.25rem;
        }
    }

    &__section {
        padding: 3rem 0;

        @include for-size(tablet-landscape-up) {
            padding: 4.5rem 0;
        }

        &:nth-child(even) {
            background-color: var(--c-background);
        }
    }

    &__hero {
        padding-bottom: 0;
    }
}
