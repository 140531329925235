.block-radio-item {
    width: 100%;
    padding: 0 1rem;
    font-size: 1em;
    line-height: 2.75rem;
    text-align: center;
    color: var(--c-primary);
    cursor: pointer;
    border: 1px solid var(--c-grey-light);
    background-color: var(--c-white);
    transition: background-color .1s ease-in-out, color .1s ease-in-out, border-color .1s ease-in-out;

    &:hover {
        color: var(--c-white);
        border-color: var(--c-primary);
        background-color: var(--c-primary);
    }

    &--is-selected {
        color: var(--c-white);
        border-color: var(--c-primary);
        background-color: var(--c-primary);
    }

    &__input {
        display: none;
    }
}
