@import "src/styles";

.vacancy-overview {

    &__error {
        font-size: .875rem;
        margin: 0 0 2rem;
    }

    &__null-state {
        width: 100%;
    }

    &__vacancy-card-list {
        max-width: 58rem;

        &--is-grid {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            grid-column-gap: 1.25rem;
            grid-row-gap: 1.25rem;

            @include for-size(tablet-portrait-up) {
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            }
        }

        &--is-from-author {
            max-width: 72rem;
        }
    }

    &__main-cta {
        margin-bottom: 2rem;
    }

    &__vacancy-card {

        & + & {
            margin-top: 1rem;
        }

        &--is-grid {

            & + & {
                margin-top: 0;
            }
        }
    }

    &__pagination {
        margin-top: 3rem;
    }
}
