.talent-matching-input-wrapper {

    &__title {
        font-size: 1rem;
    }

    &__description {
        margin-top: 1rem;
    }

    &__input {
        margin-top: 1.5rem;
    }
}
