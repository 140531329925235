// TODO: Rename to 'block-radio-item', once the old component has been eradicated
.new-block-radio-item {
    padding: 1rem 1.5rem;
    color: var(--c-grey-dark);
    cursor: pointer;
    background-color: var(--c-white);

    &:focus-within {
        text-decoration: underline;
    }

    &--is-selected {
        color: var(--c-white);
        background-color: var(--c-primary);
    }

    &__input {
        position: absolute;
        opacity: 0;
    }

    &__label {
        font-weight: var(--fw-medium);
    }
}
