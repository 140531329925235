@import "src/styles";

.vacancy-apply-card {
    line-height: 1.5;

    &__description {
        margin-top: 2rem;
        color: var(--c-grey-dark);
    }

    &__button {
        margin-top: 1.5rem;

        &--for-chat {
            @extend %button--outline;

            margin-top: 1rem;
        }
    }

    &__fixed-footer-button {
        width: 100%;
    }

    &__modal-card {
        display: flex;
        flex-direction: column;
        max-width: 64rem;
        padding: 0;

        @include for-size(tablet-landscape-up) {
            flex-direction: row-reverse;
        }
    }

    &__form {
        width: 100%;
        padding: 2rem;
    }

    &__form-tips {
        width: 100%;

        @include for-size(tablet-landscape-up) {
            max-width: 20rem;
        }
    }
}
