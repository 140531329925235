.blog-category-filter-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    &__item {
        list-style-type: none;
    }

    &__button {
        padding: .75rem;

        &::first-letter {
            text-transform: uppercase;
        }

        &--is-active {
            color: var(--c-white);
            background-color: var(--c-primary);
        }
    }
}
