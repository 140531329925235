.complete-registration-toast {
    max-width: 32rem;
    line-height: 1.5;

    &__button {
        margin-top: 1rem;
        border: 1px solid var(--c-grey-light);
        background-color: var(--c-white);
    }
}
