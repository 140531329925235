@import "src/styles";

.image-drop-form {
    position: relative;
    object-fit: contain;
    box-sizing: content-box;
    border: 2px dashed var(--c-primary);

    &:hover &__control-list,
    &:focus-within &__control-list {
        opacity: 1;
    }

    &__drop-indicator {
        @extend %flex-align-center;
        @extend %click-extend;

        width: inherit;
        height: inherit;
        z-index: 10;
        border-radius: inherit;
        background-color: rgba(var(--c-white-rgb), .5);
        opacity: 0;
        transition: .1s ease opacity;

        &--is-visible {
            opacity: 1;
        }
    }

    &__drop-indicator-icon {
        height: 1.5rem;
    }

    &__image-wrapper {
        width: inherit;
        height: inherit;
        border-radius: inherit;
        overflow: hidden;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: inherit;
        background-color: var(--c-white);
    }

    &__label {
        @extend %click-extend;

        z-index: 10;
        cursor: pointer;
    }

    &__input {
        position: absolute;
        width: 0;
        opacity: 0;
    }

    &__control-list {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: -1.25rem;
        left: -1.25rem;
        z-index: 30;
        padding: 0;
        opacity: 0;
        transition: opacity .1s ease;

        @media (hover: none) {
            opacity: 1;
        }
    }

    &__control-item {
        list-style-type: none;

        & + & {
            margin-top: .5rem;
        }
    }

    &__control-button {
        @extend %button--control;

        color: var(--c-primary);
        box-shadow: var(--shadow-medium);
    }

    &__crop-modal-card {
        max-width: 64rem;
    }

    &__crop-modal-instructions {
        font-size: 1rem;
        color: var(--c-grey-dark);
    }

    &__cropper {
        margin-top: 1rem;
    }
}
