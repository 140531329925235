@import "src/styles";

$component: 'checkbox';

.#{$component} {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: var(--fw-medium);
    color: var(--c-grey-dark);
    cursor: pointer;

    &--is-disabled {
        opacity: .5;
        pointer-events: none;
    }

    &__input {
        position: absolute;
        opacity: 0;

        &:focus:not(:focus-visible) ~ .#{$component}__label {
            text-decoration: none;
        }

        &:focus ~ .#{$component}__label {
            text-decoration: underline;
        }

        &:checked ~ .#{$component}__box {

            &::after {
                transform: translate(-50%, -50%) scale(1);
            }

            .#{$component}__icon {
                opacity: 1;
            }
        }
    }

    &__box {
        @extend %flex-align-center;
        @include square(1.25rem);

        position: relative;
        border-radius: var(--border-radius);
        border: 1px solid var(--c-grey-dark);

        &::after {
            @include square(1.25rem);

            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;
            border-radius: var(--border-radius);
            background-color: var(--c-text);
            transform: translate(-50%, -50%) scale(0);
            transition: transform .1s ease;
        }
    }

    &__icon {
        position: relative;
        z-index: 20;
        width: .75rem;
        color: var(--c-white);
        opacity: 0;
        transition: opacity .1s ease;
    }

    &__label {
        margin-left: .75rem;
    }
}
