@import "src/styles";

$component: 'chat-event-list-skeletons';

.#{$component} {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: auto;

    &__list {
        padding: 1.5rem;
        overflow: inherit;
    }

    &__item {
        display: flex;
        gap: 1rem;

        &:nth-child(even) {
            flex-direction: row-reverse;

            .#{$component}__chat-balloon-wrapper {
                align-items: flex-end;
            }
        }

        & + & {
            margin-top: 1.5rem;
        }
    }

    &__avatar {
        @include circle(2.5rem);
    }

    &__chat-balloon-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 36rem;
    }

    &__chat-balloon {
        font-size: 3rem;
    }

    &__date {
        width: 7.5rem;
        margin-top: .5rem;
        font-size: .875rem;
    }
}
