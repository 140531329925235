.drivers-licenses-form {

    &__title {
        font-size: 1rem;
        font-weight: var(--fw-bold);
        color: var(--c-grey-dark);
        margin-bottom: .75rem;
    }

    &__checkbox-list {
        margin-top: 1.5rem;
    }

    &__action-buttons {
        position: relative;
        z-index: 10;
        margin-top: 4rem;
    }
}
