@import "src/styles";

.recent-vacancy-share-modal {

    &__vacancy-title {
        margin-top: 1rem;
        font-weight: var(--fw-semi-bold);
    }

    &__share-list {
        margin-top: 1rem;
    }

    &__share-item {

        & + & {
            margin-left: 1rem;
        }
    }

    &__button-list {
        margin-top: 2.5rem;
    }

    &__cancel-button {
        @extend %button--transparent-background;

        color: var(--c-primary);
    }
}
