@import "src/styles";

.file-list {
    max-height: 12.5rem;
    overflow: auto;

    &__item {
        list-style-type: none;
    }

    &__button {
        @extend %button--no-background;

        display: flex;
        align-items: center;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        text-align: left;
        color: var(--c-grey-dark);

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__icon {
        height: 1.25rem;
    }

    &__name {
        flex: 1;
        margin-left: 1rem;
        color: var(--c-link);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include for-size(tablet-portrait-up) {
            flex: initial;
        }
    }

    &__size {
        margin-left: .5rem;

        &::before { content: "(" }
        &::after { content: ")" }
    }
}
