.hidden-phone-number {

    &__link {
        display: inline-flex;
        align-items: center;
        padding: .75rem 0;
        font-weight: var(--fw-semi-bold);
        color: var(--c-link);
        background: none;
    }

    &__link-number {
        margin-left: .625rem;
    }
}
