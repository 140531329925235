@import "src/styles";

.application-status-invite-rejected {

    &__description {
        margin-top: 1rem;
    }

    &__appointment-list {
        margin-top: 1.5rem;
        padding: 0;
    }

    &__appointment-item {
        display: flex;
        align-items: center;

        & + & {
            margin-top: 1rem;
        }
    }

    &__appointment-icon {
        color: var(--c-negative);
    }

    &__appointment-label {
        flex: 1;
        margin-left: .75rem;
        font-style: italic;
    }

    &__button-wrapper {
        margin-top: 1.5rem;
    }

    &__button {

        &--for-rejection {
            @extend %button--transparent-background;

            color: var(--c-text);
        }

        & + & {
            margin-top: 1rem;
        }
    }
}
