.company-kvk-list {
    line-height: 1.5;
    animation: fadeIn ease-out .5s forwards;

    &__title {
        position: relative;
        font-size: 1.125rem;
        font-weight: var(--fw-bold);

        &::after {
            content: "*";

            display: inline;
            color: var(--c-negative);
        }
    }

    &__results-count {
        margin-top: .875rem;
        color: var(--c-grey-dark);

        & + & {
            margin-top: 2rem;
        }
    }

    &__list {
        max-height: 18.75rem;
        margin-top: 1rem;
        overflow-y: scroll;
        border: 1px solid var(--c-grey-light);
        box-shadow: var(--shadow-medium);
    }
}
