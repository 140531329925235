.vacancy-index-education-level {

    &__level-list {
        list-style-type: none;
    }

    &__level {
        color: var(--c-grey-dark);
        line-height: var(--vacancy-index-line-height);
    }
}
