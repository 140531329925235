@import "src/styles";

.sub-menu-item {
    padding: .75rem 0;
    font-weight: var(--fw-bold);
    color: var(--c-grey);

    @include for-size(tablet-landscape-up) {
        padding: 1rem 0;
    }

    &:last-of-type &__link {
        padding-right: 1rem;

        @include for-size(tablet-landscape-up) {
            padding-right: 0;
        }
    }

    &--is-active {
        color: var(--c-text);
    }

    &__link {
        display: block;
        padding: inherit;
        color: inherit;
        text-decoration: none;
    }
}
