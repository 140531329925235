@import "src/styles";

.application-selector-option {
    display: flex;
    align-items: center;
    padding: 1rem;
    cursor: pointer;

    &__avatar {
        @include square(3rem);

        font-size: .875rem;
    }

    &__input {
        margin-left: 1rem;
        font-weight: var(--fw-semi-bold);
        color: var(--c-grey-dark);
    }
}
