@keyframes spin {
    to { transform: rotate(1turn); }
}

@keyframes pulse {
    from {
        transform: scale(.8);
        opacity: .9;
    }

    to {
        transform: scale(1.07);
        opacity: 0;
    }
}

@keyframes pulse-live {
    from {
        transform: scale(1);
        opacity: .9;
    }

    to {
        transform: scale(1.25);
        opacity: 0;
    }
}

@keyframes gelatine {
    from, to { transform: scale(1, 1); }
    25% { transform: scale(0.8, 1.2); }
    50% { transform: scale(1.15, 0.85); }
    75% { transform: scale(0.95, 1.05); }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes skeleton-load {
    from { transform: translateX(-100%); }
    to { transform: translateX(100%); }
}

@keyframes reset-dashoffset {
    to { stroke-dashoffset: 0; }
}

@keyframes fill-progress {
    to { transform: translateX(0); }
}

@keyframes scale-in {
    to { transform: scale(1); }
}
