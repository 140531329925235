.vacancy-breadcrumbs {

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: .75rem;
    }

    &__item {
        display: flex;
        align-items: center;
        list-style-type: none;
        font-weight: var(--fw-medium);
        color: var(--c-grey);
    }

    &__link {
        text-decoration: none;
        color: var(--c-grey-dark);

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__label {

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__icon {
        height: .75em;
        margin-left: .75rem;
    }
}
