.email-address-change-form {

    &__validation-disclaimer {
        margin-top: 1rem;
    }

    &__email-input {
        display: block;
        margin-top: 1.5rem;
    }

    &__submit-button {
        margin-top: 1.5rem;
    }

    &__password-input {
        display: block;
        margin-top: 1.5rem;
    }

    &__modal-action-buttons {
        margin-top: 2.5rem;
    }
}
