.environment-logo {
    display: flex;
    position: relative;

    &__logo {
        height: 1.75rem;
        color: currentColor;
    }

    &__underline {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: .625rem;
        font-weight: var(--fw-medium);
        line-height: 1;
        transform: translateY(.875rem);
    }
}
