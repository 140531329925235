.job-experiences-card-skeletons {

    &__item {
        list-style-type: none;

        & + & {
            margin-top: 2rem;
            padding-top: 2rem;
            border-top: 1px solid var(--c-grey-light);
        }
    }

    &__text-wrapper {
        width: 100%;
        max-width: 32rem;
    }

    &__title {
        height: 1.25rem;
    }

    &__dates {
        height: .75rem;
        max-width: 10rem;
        margin-top: .75rem;
    }

    &__description {
        max-width: 15rem;
        margin-top: 1.25rem;
    }
}
