@import "src/styles";

.vacancy-overview-complete-registration {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    line-height: 1.5;
    color: var(--c-white);
    background-color: var(--c-negative);

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__description {
        margin-top: .5rem;
        font-weight: var(--fw-medium);
    }

    &__button {
        margin-top: 2rem;
        margin-right: auto;
        background-color: var(--c-white);

        @include for-size(tablet-portrait-up) {
            margin: 0;
            margin-left: 2rem;
        }
    }
}
