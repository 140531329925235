@import "src/styles";

$component: 'sidebar';

.#{$component} {
    position: fixed;
    top: var(--navigation-menu-height);
    right: 0;
    z-index: 100;
    width: 100%;
    max-width: 32rem;
    overflow: hidden;
    pointer-events: none;

    &--is-expanded {
        pointer-events: all;

        .#{$component}__wrapper {
            transform: translateX(0);
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - var(--navigation-menu-height));
        overflow-x: hidden;
        overflow-y: auto;
        border-left: 1px solid var(--c-grey-light);
        background-color: var(--c-white);
        transform: translateX(100%);
        transition: transform .3s ease;
    }

    &__header {
        display: flex;
        flex: 0 0 auto;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 0;
        z-index: 20;
        padding-left: 1rem;
        color: var(--c-text);
        background-color: var(--c-white);
        box-shadow: var(--shadow-medium);

        @include for-size(tablet-landscape-up) {
            padding-left: 2rem;
        }
    }

    &__title {
        font-size: 1.5rem;
        font-weight: var(--fw-bold);
    }

    &__loader {
        margin-left: auto;
    }

    &__loader-spinner {
        @include circle(1.125rem);

        color: var(--c-text);
    }

    &__close-button {
        @extend %button--no-background;
        @extend %flex-align-center;

        color: var(--c-text);
        padding: 1.5rem 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 1.5rem 2rem;
        }
    }

    &__close-button-icon {
        height: 1rem;
    }

    &__child-wrapper {
        display: flex;
        position: relative;
        z-index: 10;
        overflow: hidden;
    }
}
