@import "src/styles";

.talent-matching-skills {
    line-height: 1.5;

    &__title {
        font-size: 1rem;
    }

    &__button {
        width: 100%;
        margin-top: 1rem;
    }

    &__modal-title {
        font-size: 1.5rem;
    }

    &__modal-description {
        margin-top: 1rem;
    }

    &__input-wrapper {
        margin-top: 2rem;
        padding-top: 2rem;
        border-top: 1px solid var(--c-grey-light);
    }

    &__button-wrapper {
        margin-top: 2.5rem;
    }

    &__cancel-button {
        @extend %button--transparent-background;

        color: var(--c-text);
    }
}
