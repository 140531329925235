@import "src/styles";

.vacancy-overview-header {
    display: flex;
    flex-direction: column;
    line-height: 1.5;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        justify-content: space-between;
    }

    &__title-wrapper {
        flex: 1;
    }

    &__skeleton {
        width: 14rem;
        font-size: 1.75rem;
    }

    &__title {
        font-size: 1.75rem;
        font-weight: var(--fw-bold);
        color: var(--c-text);
    }

    &__query {
        margin-top: .5rem;
    }

    &__button-wrapper {
        margin-top: 1rem;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &__talent-matching-button-wrapper {
        @extend %flex-align-center;

        padding: 1.5rem;
        border-radius: var(--border-radius);
        background-color: var(--c-white);

        @include for-size(tablet-portrait-up) {
            padding: 0;
            background: none;
        }
    }

    &__back-button {
        @extend %button--transparent-background;

        align-self: flex-start;
        height: 3.5rem;
        color: var(--c-text);
    }
}
