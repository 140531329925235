.undo-chat-report-form {

    &__report-reason {
        margin-top: 1.5rem;
        padding: 1rem;
        background-color: var(--c-grey-light);
    }

    &__action-buttons {
        margin-top: 4rem;
    }
}
