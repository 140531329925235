@import "src/styles";

.featured-blog-articles {
    padding: 3.5rem 0;
    background-color: var(--c-background);

    &__content-wrapper {
        max-width: 74rem;
        margin: 0 auto;
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 0 2rem;
        }
    }

    &__title {
        font-size: 2rem;
        font-weight: var(--fw-bold);
        line-height: 1.5;
    }

    &__list {
        margin-top: 3.5rem;

        @include for-size(tablet-portrait-up) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include for-size(tablet-landscape-up) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
