@import "src/styles";

.home-hero {
    --hero-impression-size: 14rem;

    @include for-size(tablet-landscape-up) {
        --hero-impression-size: 16rem;
    }

    @include for-size(desktop-up) {
        --hero-impression-size: 20.25rem;
    }

    &__header {
        position: relative;
        z-index: 10;
    }

    &__title {
        font-size: 2rem;
        font-weight: var(--fw-bold);
        line-height: 1.5;
        color: var(--c-primary);

        @include for-size(tablet-portrait-up) {
            max-width: 28rem;
            font-size: 2.5rem;
        }

        @include for-size(tablet-landscape-up) {
            max-width: 31rem;
            font-size: 3rem;
        }
    }

    &__title-underline {
        @extend %span-underline;

        position: relative;
        margin: 0 .75rem;
    }

    &__impression {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        width: var(--hero-impression-size);
        height: calc(var(--hero-impression-size) * 1.25);
        transform: translateY(-15%);

        @include for-size(tablet-portrait-up) {
            display: block;
        }

        @include for-size(tablet-landscape-up) {
            transform: translate(15%, -20%);
        }

        @include for-size(desktop-up) {
            transform: translate(50%, -18%);
        }
    }

    &__image-wrapper,
    &__circle {
        position: absolute;
        bottom: 0;
        left: 0;
        overflow: hidden;
    }

    &__image-wrapper {
        z-index: 20;
        width: 100%;
        height: 100%;
        border-radius: var(--hero-impression-size);
    }

    &__image {
        position: absolute;
        bottom: -2rem;
        width: 100%;
    }

    &__circle {
        @include circle(var(--hero-impression-size));

        z-index: 10;
        background: linear-gradient(-90deg, var(--c-white) -25%, var(--c-secondary));

        &::after {
            @extend %click-extend;
            @include grain-background;

            content: "";
        }
    }

    &__search-form {
        position: relative;
        z-index: 20;
        margin-top: 3rem;
        padding: 1.5rem;
        border-radius: var(--border-radius);
        border: 1px solid var(--c-grey-light);
        background-color: var(--c-white);

        @include for-size(tablet-landscape-up) {
            margin-top: 4rem;
            padding: 1.75rem 2.25rem;

            & > *:nth-child(2) {
                width: 80%;
            }
        }
    }
}
