@import "src/styles";

.publication-and-expiration-dates {

    &__inputs {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__date-input {
        margin-bottom: .625rem;

        &:first-child {
            @include for-size(tablet-portrait-up) {
                padding-right: .5rem;
            }
        }

        &:last-child {
            @include for-size(tablet-portrait-up) {
                padding-left: .5rem;
            }
        }
    }

    &__life-time {
        font-size: .875rem;
        color: var(--c-grey-dark);
    }
}
