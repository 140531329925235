.vacancy-awaiting-response-card {

    &__description {
        margin-top: 2rem;
        line-height: 1.5;
        color: var(--c-grey-dark);
    }

    &__button {
        margin-top: 2rem;
    }
}
