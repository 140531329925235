@import "src/styles";

.expandable-information-navigation {
    position: relative;
    height: 100%;

    &__toggle-button {
        @extend %button--no-background;

        padding: 1.25rem 1rem;
        font-size: 1rem;
        font-weight: var(--fw-semi-bold);
        text-decoration: none;
        color: var(--c-grey-dark);
    }

    &__toggle-icon {
        width: .75rem;
    }

    &__navigation {
        position: absolute;
        top: var(--navigation-menu-height);
        width: 17rem;
        padding: 1rem 0;
        pointer-events: none;
        border-radius: var(--border-radius);
        border: 1px solid var(--c-grey-light);
        background-color: var(--c-white);
        box-shadow: var(--shadow-medium);
        transition: transform .1s ease, opacity .2s ease;
        transform: translateY(-1rem);
        opacity: 0;

        &--is-expanded {
            pointer-events: all;
            transform: translateY(0);
            opacity: 1;
        }
    }

    &__list {

        & + & {
            margin-top: .5rem;
            padding-top: .5rem;
            border-top: 1px solid var(--c-grey-light);
        }
    }
}
