.chat-message-form {
    display: flex;
    align-items: center;

    &__input {
        width: 100%;
    }

    &__input-field,
    &__send-button {
        height: 3rem;
    }

    &__send-button {
        padding: 1rem;
        margin-left: 1rem;
    }
}
