.vacancy-index-tab {

    &__title {
        font-size: 1.75rem;
        font-weight: var(--fw-bold);
        color: var(--c-primary);
    }

    &__content-wrapper {
        margin-top: 1.5rem;
    }
}
