@import "src/styles";

$component: 'notification-item';

.notification-item {
    display: flex;
    align-items: center;
    list-style-type: none;
    color: var(--c-text);
    position: relative;
    border-bottom: 1px solid var(--c-grey-light);

    &:hover,
    &:focus-within {

        .#{$component}__content-wrapper {

            @include for-size(tablet-landscape-up) {
                background-color: var(--c-background);
                transform: translateX(-4rem);
            }
        }
    }

    &--is-open {

        .#{$component}__content-wrapper {
            transform: translateX(-4rem);
        }
    }

    &--is-new,
    &--is-unread {

        .#{$component}__content-wrapper {
            background-color: var(--c-background);
        }

        .#{$component}__image-wrapper::after {
            content: "";
        }
    }

    &__swipe-detector {
        width: 100%;
    }

    &__content-wrapper {
        display: flex;
        position: relative;
        z-index: 20;
        width: 100%;
        padding: 1rem;
        transition: transform .3s ease;
        background-color: var(--c-white);

        @include for-size(tablet-landscape-up) {
            padding: 1rem 2rem;
        }
    }

    &__image-wrapper {
        position: relative;

        &::after {
            @include circle(.875rem);

            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-50%, -50%);
            background-color: var(--c-secondary);
        }
    }

    &__image {
        width: 3rem;
        height: 3rem;

        @include for-size(tablet-landscape-up) {
            width: 4.75rem;
            height: 4.75rem;
        }
    }

    &__text-wrapper {
        margin-left: 1rem;
        overflow: hidden;
    }

    &__link {
        text-decoration: none;
        color: currentColor;

        &::after {
            @extend %click-extend;

            content: "";
        }
    }

    &__title {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1rem;
        line-height: 1.5;
    }

    &__description {
        margin-top: .25rem;
        font-size: .875rem;
    }

    &__age {
        margin-left: 1rem;
        font-size: .75rem;
        color: var(--c-grey);
    }

    &__delete-button {
        @extend %flex-align-center;

        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        width: 4rem;
        height: 100%;
        padding: 0;
        color: var(--c-always-white);
        border-radius: 0;
        background-color: var(--c-primary);

        &:enabled:hover,
        &:enabled:focus {
            background-color: var(--c-negative);
        }
    }

    &__delete-icon {
        height: 1.25rem;
    }
}
