@import "src/styles";

.modal-content {

    &--is-positive &__icon-wrapper {
        background-color: var(--c-positive);
    }

    &--is-warning &__icon-wrapper {
        background-color: var(--c-warning);
    }

    &--is-negative &__icon-wrapper {
        background-color: var(--c-negative);
    }

    &__header {
        display: flex;
        align-items: center;
        padding-right: 2rem;
    }

    &__title {
        font-size: 1.5rem;
        line-height: 1.5;
        color: var(--c-primary);
    }

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(2.5rem);

        margin-right: 1rem;
        color: var(--c-white);
    }

    &__icon {
        max-width: 1rem;
        max-height: 1rem;
    }

    &__content-wrapper {
        margin-top: 2rem;
    }
}
