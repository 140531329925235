%html-format {
    line-height: 1.5;

    & > * + * {
        margin-top: 1rem;
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1.125rem;
    }

    h2 + p,
    h3 + p {
        margin-top: 0;
    }

    ul, ol {
        padding-left: 1.125rem;
    }

    a {
        color: var(--c-link);
    }
}
