@import "src/styles";

.favourite-button {
    @extend %button--no-background;

    width: 1em;
    height: 1em;
    padding: 0;
    font-size: 1.25rem;
    color: var(--c-grey-dark);
    transition: opacity .1s ease;

    &:enabled:hover {
        color: var(--c-favourite);
    }

    &--is-active {
        color: var(--c-favourite);

        &:disabled {
            color: var(--c-favourite);
        }

        &:enabled:hover {
            opacity: .75;
        }

        svg {
            animation: gelatine .33s forwards ease-out;
        }
    }

    &--is-loading {
        opacity: .5;
    }

    &__icon {
        height: 1em;
    }
}
