@import "src/styles";

.company-profile-page {
    position: relative;
    padding-top: 0;

    &__wrapper {
        width: 100%;
        max-width: 90rem;
        margin: 0 auto;
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 0 2rem;
        }
    }

    &__progress {
        margin-top: 2rem;
    }

    &__card {

        & + & {
            margin-top: 2rem;
        }
    }

    &__card-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__main {
        width: 100%;
    }

    &__sidebar {
        width: 100%;
        margin-top: 2rem;

        @include for-size(tablet-landscape-up) {
            max-width: 24rem;
            margin-top: 0;
            margin-left: 2rem;
        }
    }

    &__vacancy-overview {
        margin-top: 4rem;
    }
}
