@import "src/styles";

.vacancy-search-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include for-size(tablet-landscape-up) {
        flex-direction: row;
        align-items: flex-end;
    }

    &__input + &__input,
    &__radio,
    &__submit-button {
        margin-top: 1.5rem;

        @include for-size(tablet-landscape-up) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &__input-field,
    &__radio-list,
    &__submit-button {

        @include for-size(tablet-landscape-up) {
            height: 3.5rem;
        }
    }

    &__input {
        width: 100%;
    }

    &__input-field {
        overflow: hidden;
        border-radius: var(--border-radius);
        box-shadow: none;
    }

    &__input-list {
        position: absolute;
        z-index: 10;
        width: 100%;
        border-color: var(--c-grey-light);
        background-color: var(--c-white);
        box-shadow: var(--shadow-medium);
    }
}
