.vacancy-index-skeletons {

    &__skeleton-list {
        list-style-type: none;
    }

    &__skeleton {

        & + & {
            margin-top: 1rem;
        }
    }
}
