.vacancy-index-category {
    --vacancy-index-category-list-width: 54rem;

    &__title {
        font-size: 1rem;
        color: var(--c-grey-dark);
    }

    &__skeletons,
    &__list {
        margin-top: 1.5rem;
        max-width: var(--vacancy-index-category-list-width);
    }
}
