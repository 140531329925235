@import "src/styles";

.job-experiences-card {

    &__skeletons,
    &__null-state,
    &__job-list {
        margin-top: 2rem;
    }

    &__null-state-description {
        font-size: 1rem;
        color: var(--c-grey-dark);
    }

    &__null-state-button {
        @extend %button--add;

        margin-top: 1.5rem;
    }

    &__job-item {
        padding: 1.5rem 0;
        border-top: 1px solid var(--c-grey-light);

        &:first-child {
            border: none;
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}
