.company-sbb-info {

    &__card {
        box-shadow: none;

        & + & {
            margin-top: 2rem;
        }
    }
}
