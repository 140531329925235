@import "src/styles";

.page {
    min-height: calc(100vh - var(--navigation-menu-height));
    margin-top: var(--navigation-menu-height);
    padding-top: 1.25rem;
    padding-bottom: 2.875rem;
    background-color: var(--c-background);

    @include for-size(tablet-landscape-up) {
        padding-top: 2.875rem;
        padding-bottom: 4.375rem;
    }
}
