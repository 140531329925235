@import "src/styles";

$component: 'role-info-steps';

.#{$component} {
    padding: 0 1rem;
    line-height: 1.5;

    @include for-size(tablet-landscape-up) {
        padding: 0 2rem;
    }

    &__wrapper {
        max-width: 74rem;
        margin: 0 auto;
    }

    &__header {
        text-align: center;
    }

    &__title {
        font-size: 2rem;
        font-weight: var(--fw-bold);
        color: var(--c-primary);
    }

    &__subtitle {
        margin-top: .625rem;
        font-size: 1.125rem;
        color: var(--c-grey-dark);
    }

    &__step-list {
        margin-top: 2rem;

        @include for-size(tablet-landscape-up) {
            margin-top: 3rem;
        }
    }

    &__step {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        list-style-type: none;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;

            &:nth-child(even) {
                flex-direction: row-reverse;

                .#{$component}__step-wrapper {

                    &:last-child {
                        margin-left: 0;
                        margin-right: 4rem;
                    }
                }
            }

            .#{$component}__step-wrapper {

                &:last-child {
                    margin-left: 4rem;
                }
            }
        }

        & + & {
            margin-top: 4rem;
        }
    }

    &__step-wrapper {
        position: relative;
        width: 100%;

        &:first-child {
            margin-top: 2rem;

            @include for-size(tablet-landscape-up) {
                margin-top: 0;
            }
        }
    }

    &__step-category {
        font-weight: var(--fw-bold);
        text-transform: uppercase;
        color: var(--c-grey);
    }

    &__step-title {
        margin-top: .5rem;
        font-size: 1.5rem;
        font-weight: var(--fw-bold);
        color: var(--c-primary);
    }

    &__step-description {
        margin-top: 1rem;
        color: var(--c-grey-dark);
    }

    &__step-button {
        margin-top: 1.5rem;
    }

    &__image {
        position: relative;
        z-index: 2;
        box-shadow: var(--shadow-medium);

        &--mobile {
            position: absolute;
            z-index: 3;
            bottom: 0;
            right: 10%;
            width: 30%;
        }

        &--desktop {
            width: 80%;
            margin-bottom: 2rem;
        }
    }

    &__image-backdrop {
        position: absolute;
        top: 5%;
        right: 0;
        z-index: 1;
        width: 8rem;
        height: 8rem;
        background-color: var(--c-secondary);
    }
}
