@import "src/styles";

.my-vacancies {

    &__title {
        font-size: 1.75rem;
        font-weight: var(--fw-bold);
        color: var(--c-text);
    }

    &__control-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        position: relative;
        z-index: 20;
        margin-top: 1.5rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__create-vacancy-controls {
        top: calc(100% + .5rem);
    }

    &__create-vacancy-button,
    &__archive-button {
        height: 3rem;
    }

    &__archive-button {
        @extend %button--transparent-background;

        color: var(--c-text);
    }

    &__wrapper {
        position: relative;
        z-index: 10;
        margin-top: 2rem;
    }

    &__vacancy-list {
        padding: 0;
    }

    &__modal-card {
        max-width: 35rem;
    }
}
