.candidate-preferences-card-skeletons {

    &__item {
        list-style-type: none;

        & + & {
            margin-top: .5rem;
        }
    }

    &__text-wrapper {
        width: 100%;
        max-width: 16rem;
    }

    &__text {
        height: 1rem;
    }
}
