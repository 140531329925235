.chat-room-header-skeletons {
    width: 100%;

    &__title {
        max-width: 18rem;
        font-size: 1.125rem;
    }

    &__link {
        max-width: 8rem;
        margin-top: .5rem;
    }
}
