.vacancy-to-learn-card {

    &__description {
        margin-top: 2rem;
        color: var(--c-grey-dark);
    }

    &__category-wrapper {
        margin-top: 2.5rem;
    }

    &__category-title {
        font-size: 1rem;
        font-weight: var(--fw-bold);
        color: var(--c-grey-dark);
    }

    &__category-description {
        margin-top: .5rem;
        color: var(--c-grey-dark);
    }

    &__category-null-state {
        margin-top: 1rem;
        color: var(--c-grey-dark);
    }

    &__tag-list {
        display: flex;
        flex-wrap: wrap;
        gap: var(--flex-tag-gap);
        margin-top: 1rem;
    }

    &__tag-item {
        list-style-type: none;
    }

    &__tag {
        color: var(--c-primary);
    }

    &__skeleton-wrapper {
        margin-top: 1.5rem;
    }
}
