@import "src/styles";

.vacancy-matches-overview-skeletons {

    &__item {
        list-style-type: none;

        & + & {
            margin-top: 1rem;
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        padding: 1.5rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__avatar {
        @include square(7.5rem);
    }

    &__text-wrapper {
        width: 100%;
        margin-top: 1.25rem;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &__text {

        & + & {
            margin-top: 1rem;
        }
    }
}
