@import "src/styles";

.youtube-embed {
    @extend %flex-align-center;

    position: relative;
    height: 0;
    margin-top: 2rem;
    padding-bottom: calc((100% / 16) * 9);
    background-color: var(--c-grey-light);

    @supports (aspect-ratio: 16 / 9) {
        height: initial;
        padding-bottom: 0;
    }

    &__iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;

        @supports (aspect-ratio: 16 / 9) {
            position: static;
            height: initial;
            max-height: 80vh;
            aspect-ratio: 16 / 9;
            padding-bottom: 0;
        }
    }
}
