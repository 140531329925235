.register-company-container {
    line-height: 1.5;

    &__title {
        font-size: 1.75rem;
    }

    &__intro {
        margin-top: 1rem;
        color: var(--c-grey-dark);
    }

    &__kvk-selector {
        margin-top: 2.5rem;
    }

    &__company-data-form {
        margin-top: 2rem;
    }

    &__submit-button {
        margin-top: 2rem;
    }
}
