@import "src/styles";

.notifications-sidebar {

    &__list {
        width: 100%;
        margin: 0;
        padding: 0;
        overflow: auto;
    }

    &__no-results {
        padding: 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 2rem;
        }
    }

    &__no-results-description {
        font-size: 1rem;
        line-height: 1.5;
    }
}
