.number-input {
    display: block;

    &__label {
        margin-bottom: .625rem;
    }

    &__input-wrapper {
        display: flex;
        flex-direction: row;
    }

    &__metric {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .5rem 1rem;
        font-size: 1rem;
        background-color: var(--c-grey-light);
    }

    &__error-label {
        margin-top: .5rem;
    }
}
