@import "src/styles";

.core-values-card {

    &__skeleton-wrapper {
        margin-top: 2rem;
    }

    &__skeleton {

        & + & {
            margin-top: 1rem;
        }
    }

    &__null-state {
        margin-top: 2rem;
    }

    &__null-state-button {
        @extend %button--add;

        margin-top: 2rem;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: var(--flex-tag-gap);
        margin-top: 2rem;
    }

    &__item {
        list-style-type: none;
    }
}
