@import "src/styles";

$component: 'min-max-input';

.#{$component} {
    display: block;

    &--align-right {

        .#{$component}__range-section {
            flex-direction: row-reverse;
        }

        .#{$component}__secondary-label {
            border: none;
            border-left: 1px solid var(--c-grey-light);
        }
    }

    &--has-error {

        .#{$component}__input-wrapper {
            border: 2px solid var(--c-negative);
        }
    }

    &__label {
        margin-bottom: .625rem;
    }

    &__input-wrapper {
        display: inline-flex;
        flex-direction: column;
        border: 1px solid var(--c-grey-light);
        background-color: var(--c-white);

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__range-section {
        display: flex;
        align-items: center;

        & + & {
            border-top: 1px solid var(--c-grey-light);

            @include for-size(tablet-portrait-up) {
                border-top: none;
                border-left: 1px solid var(--c-grey-light);
            }
        }
    }

    &__input {
        width: 10rem;

        input {
            height: 3.5rem;
            border: none;
            box-shadow: none;
        }
    }

    &__secondary-label {
        @extend %flex-align-center;

        align-self: stretch;
        width: 3rem;
        font-weight: var(--fw-bold);
        color: var(--c-grey-dark);
        border-right: 1px solid var(--c-grey-light);

        @include for-size(tablet-portrait-up) {
            width: auto;
            padding: 0 1.5rem;
        }
    }

    &__error-label {
        margin-top: .5rem;
    }
}
