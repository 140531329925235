@import "src/styles";

.checkbox-list {

    &__label {
        margin-bottom: .75rem;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: .75rem;

        &--is-horizontal {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 1rem;
        }

        &--has-error {
            padding-left: .5rem;
            border-left: 2px solid var(--c-negative);
        }
    }

    &__checkbox {

        &--is-hidden {
            display: none;
        }
    }

    &__error-label {
        margin-top: .5rem;
    }

    &__toggle-button {
        @extend %button--no-background;

        margin-top: 1rem;
        padding: 0;
        color: var(--c-grey-dark);
    }
}
