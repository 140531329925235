:root {
    --navigation-menu-height:   4.375rem;

    --f-primary:                'Inter', system-ui, sans-serif;
    --f-script:                 'Caveat', cursive;

    --fw-regular:               400;
    --fw-medium:                500;
    --fw-semi-bold:             600;
    --fw-bold:                  700;

    --c-primary-rgb:            17, 23, 29;
    --c-primary:                rgb(var(--c-primary-rgb));

    --c-secondary-rgb:          255, 219, 2;
    --c-secondary:              rgb(var(--c-secondary-rgb));

    --c-positive-rgb:           6, 203, 112;
    --c-positive:               rgb(var(--c-positive-rgb));
    --c-warning-rgb:            255, 152, 33;
    --c-warning:                rgb(var(--c-warning-rgb));
    --c-negative-rgb:           255, 51, 52;
    --c-negative:               rgb(var(--c-negative-rgb));

    --c-link-rgb:               32, 139, 200;
    --c-link:                   rgb(var(--c-link-rgb));
    --c-llo-rgb:                1, 188, 156;
    --c-llo:                    rgb(var(--c-llo-rgb));
    --c-favourite:              rgb(var(--c-negative-rgb));

    --c-text-rgb:               var(--c-primary-rgb);
    --c-text:                   rgb(var(--c-text-rgb));
    --c-background-rgb:         247, 248, 250;
    --c-background:             rgb(var(--c-background-rgb));

    --c-black-rgb:              0, 0, 0;
    --c-black:                  rgb(var(--c-black-rgb));
    --c-always-black-rgb:       0, 0, 0;
    --c-always-black:           rgb(var(--c-always-black-rgb));

    --c-white-rgb:              255, 255, 255;
    --c-white:                  rgb(var(--c-white-rgb));
    --c-always-white-rgb:       255, 255, 255;
    --c-always-white:           rgb(var(--c-always-white-rgb));

    --c-grey-light-rgb:         230, 232, 234;
    --c-grey-light:             rgb(var(--c-grey-light-rgb));
    --c-grey-rgb:               162, 169, 176;
    --c-grey:                   rgb(var(--c-grey-rgb));
    --c-grey-dark-rgb:          95, 105, 116;
    --c-grey-dark:              rgb(var(--c-grey-dark-rgb));

    --border-radius:            3px;
    --flex-tag-gap:             .75rem .5rem;

    --shadow-medium:            0px 2px 4px 0px rgba(var(--c-black-rgb), .1);
    --shadow-medium-up:         0px -2px 4px 0px rgba(var(--c-black-rgb), .1);

    &[dark-mode="true"] {
        --c-text-rgb:           255, 255, 255;
        --c-background-rgb:     33, 43, 54;

        --c-white-rgb:          41, 55, 69;

        --c-grey-light-rgb:     105, 115, 125;
        --c-grey-rgb:           148, 155, 163;
        --c-grey-dark-rgb:      166, 170, 175;
    }
}
