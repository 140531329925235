.logo {
    min-height: 1rem;

    circle,
    path,
    polygon,
    rect {
        fill: currentColor;
    }

    .stroke {
        fill: none;
        stroke: currentColor;
    }
}
