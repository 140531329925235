.vacancy-matches-null-state {
    max-width: 40rem;

    &__title {
        font-size: 1.25rem;
    }

    &__description {
        margin-top: 1rem;
    }

    &__button {
        margin-top: 2.5rem;
    }
}
