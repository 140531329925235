.vacancy-property {

    &__title {
        font-size: 1rem;
        font-weight: var(--fw-semi-bold);
        color: var(--c-grey-dark);
    }

    &__tag-list {
        display: flex;
        flex-wrap: wrap;
        gap: var(--flex-tag-gap);
        margin-top: 1rem;
    }

    &__tag-item {
        display: flex;
        align-items: center;
        list-style-type: none;
    }

    &__tag {
        color: var(--c-primary);
    }

    &__sbb-approved-tag {
        display: inline-flex;
        align-items: center;
        padding: .5rem 1rem;
        font-size: .875rem;
        border-radius: 2rem;
        border: 1px solid var(--c-grey-light);
    }

    &__sbb-approved-tag-text {
        font-weight: var(--fw-medium);
    }

    &__sbb-approved-tag-label {
        margin-left: 1rem;
        padding: 0;
        border: none;
    }
}
