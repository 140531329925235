@import "src/styles";

$component: 'menu-list-item';

.#{$component} {
    --c-menu-item: var(--c-text);

    position: relative;
    color: var(--c-grey-dark);

    &--template-regular {

        .#{$component}__link {

            &--is-active::after {
                content: "";
                position: absolute;
                top: calc(((var(--navigation-menu-height) - 100%) / 2) + 100%);
                left: 0;
                width: 100%;
                height: 3px;
                background-color: var(--c-secondary);
            }
        }

        .#{$component}__button {
            @extend %button--no-background;
        }
    }

    &--template-information {

        .#{$component}__link,
        .#{$component}__button {
            padding: .75rem 1rem;
        }

        .#{$component}__button {
            @extend %button--no-background;

            width: 100%;
        }

        .#{$component}__icon-wrapper {
            @extend %flex-align-center;
            @include circle(2.5rem);

            margin-right: 1rem;
            background-color: var(--c-menu-item);
        }

        .#{$component}__icon {
            height: 1rem;
            color: var(--c-always-white);
        }
    }

    &--template-buttons,
    &--template-session {

        &.#{$component}--is-textual {

            .#{$component}__link,
            .#{$component}__button {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &.#{$component}--is-important {

            .#{$component}__link,
            .#{$component}__button {
                padding: .75rem 1rem;
            }
        }

        .#{$component}__link,
        .#{$component}__button {
            @extend %button;

            display: flex;
            text-align: center;
            padding: .75rem 1rem;
        }
    }

    &--template-drawer {

        .#{$component}__link,
        .#{$component}__button {
            padding-left: 0;
            padding-right: 0;
        }

        .#{$component}__icon-wrapper {
            @extend %flex-align-center;

            width: 1.25rem;
        }

        .#{$component}__skeleton,
        .#{$component}__label {
            margin-left: 1.25rem;
            font-weight: var(--fw-medium);
        }

        .#{$component}__counter {
            @include circle(1.75rem);

            position: static;
            margin-left: 1rem;
            font-size: .875rem;
        }
    }

    &--template-session {

        &.#{$component}--is-textual {

            .#{$component}__link,
            .#{$component}__button {
                color: var(--c-grey-dark);
            }
        }

        .#{$component}__icon-wrapper {
            display: flex;
            margin-right: 1rem;
        }
    }

    &--template-icon {
        color: var(--c-primary);

        .#{$component}__link,
        .#{$component}__button {
            @extend %button--no-background;

            position: relative;
            padding: 1rem;
        }

        .#{$component}__skeleton,
        .#{$component}__label {
            display: none;
        }

        .#{$component}__icon-wrapper {
            display: flex;
        }

        .#{$component}__icon {
            height: 1.5rem;
        }
    }

    &--is-textual {

        .#{$component}__link,
        .#{$component}__button {
            @extend %button--no-background;

            color: var(--c-text);
        }
    }

    &--is-important {

        .#{$component}__link,
        .#{$component}__button {
            @extend %button--negative;
        }
    }

    &__link,
    &__button {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 1rem 1rem;
        text-decoration: none;
        color: currentColor;

        &:disabled {
            opacity: .5;
        }
    }

    &__link {

        &--is-active {
            color: var(--c-text);
        }

        &--is-disabled {
            opacity: .5;
            pointer-events: none;
        }
    }

    &__icon-wrapper {
        display: none;
    }

    &__icon {
        height: 1.25rem;
        color: var(--c-text);
    }

    &__skeleton {
        width: 8rem;
    }

    &__label {
        font-size: 1rem;
        font-weight: var(--fw-semi-bold);
    }

    &__counter {
        @extend %flex-align-center;
        @include circle(1.25rem);

        position: absolute;
        top: 0;
        right: 0;
        font-size: .75rem;
        font-weight: var(--fw-semi-bold);
        line-height: 1;
        color: var(--c-always-black);
        background-color: var(--c-secondary);
    }
}
