.social-links {
    display: flex;
    padding: 0;

    &__item {
        list-style-type: none;

        & + & {
            margin-left: 1rem;
        }
    }
}
