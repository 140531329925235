@import "src/styles";

.address-input {
    margin-bottom: 2rem;

    &__wrapper {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__input {
        & + & {
            margin-top: 1rem;

            @include for-size(tablet-landscape-up) {
                margin-top: 0;
                margin-left: 1rem;
            }
        }
    }

    &__error-label {
        @extend %paragraph;
        font-size: .875rem;
        color: var(--c-negative)
    }

    &__loader, &__error-label, &__result-wrapper {
        margin-top: 1.5rem;
    }

    &__result-title {
        @extend %paragraph;

        margin-bottom: 1rem;
        color: var(--c-grey-dark);
    }

    &__result-list {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 1rem;
        border: 1px solid var(--c-grey-light);
        box-shadow: var(--shadow-medium);

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__result {
        list-style-type: none;

        & + & {
            margin-top: 1rem;

            @include for-size(tablet-landscape-up) {
                margin-top: 0;
                margin-left: 1.5rem;
            }
        }
    }

    &__result-label {
        margin-bottom: .5rem;
        font-size: 1rem;
        text-transform: uppercase;
        color: var(--c-grey-dark);
    }

    &__result-value {
        font-size: .875rem;
    }
}
