@import "src/styles";

.jitsi {
    line-height: 1.5;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__live-indicator-wrapper {
        position: relative;
    }

    &__live-indicator {
        position: relative;
        z-index: 20;
        font-size: 1rem;
        font-weight: var(--fw-semi-bold);
        color: var(--c-always-white);
        border-color: var(--c-negative);
        background-color: var(--c-negative);
    }

    &__live-pulse {
        @extend %click-extend;

        z-index: 10;
        border-radius: 2rem;
        background-color: var(--c-negative);
        animation: pulse-live 2.5s linear infinite;
    }

    &__title {
        font-size: 1.5rem;
    }

    &__appointment-time {
        margin-top: 1.5rem;
    }

    &__stream-container {
        width: 100%;
        height: 70vh;
        margin-top: 1.5rem;
        background-color: var(--c-grey-light);
    }

    &__end-button {
        @extend %button--negative;

        margin-top: 2.5rem;
    }
}
