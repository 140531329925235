@import "src/styles";

$component: 'link-icon-button';

.#{$component} {
    @extend %button;

    display: inline-flex;
    align-items: center;

    &--align-right {
        flex-direction: row-reverse;

        .#{$component}__icon {
            margin-right: 0;
            margin-left: .625rem;
        }
    }

    &--is-disabled {
        color: var(--c-grey);
        pointer-events: none;
        background-color: var(--c-grey-light);
        opacity: .5;
    }

    &--hidden-label {

        .#{$component}__icon {
            margin: 0;
        }
    }

    &__icon {
        display: block;
        max-width: 1em;
        max-height: 1em;
        margin-right: .625rem;
    }
}
