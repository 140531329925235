.desktop-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__information {
        margin-left: .5rem;
    }

    &__profile-controls {
        margin-left: 1rem;
    }
}
