@import "src/styles";

.vacancy-overview-skeletons {

    &__item {
        list-style-type: none;

        & + & {
            margin-top: 1rem;
        }

        &--is-grid {

            & + & {
                margin-top: 0;
            }
        }
    }

    &__card {
        display: flex;
        padding: 1.5rem;
    }

    &__avatar {
        @include square(4.5rem);
    }

    &__text-wrapper {
        width: 80%;
        margin-left: 1rem;
    }

    &__title {
        height: 1rem;
    }

    &__meta-wrapper {
        max-width: 14rem;
    }

    &__company,
    &__date {
        height: .875rem;
        margin-top: .5rem;
    }
}
