@import "src/styles";

.job-vacancy-overview-filter {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include for-size(tablet-landscape-up) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__button-wrapper {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 1rem;

        @include for-size(tablet-landscape-up) {
            margin-top: 0;
            margin-left: 2rem;
        }
    }

    &__search-form {

        @include for-size(tablet-landscape-up) {
            max-width: 44rem;
        }
    }

    &__reset-button {
        @extend %button--transparent-background;

        margin-right: 1rem;
        color: var(--c-text);
    }
}
