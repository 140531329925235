@import "src/styles";

.profile-controls-skeletons {
    display: flex;
    align-items: center;

    &__avatar {
        @include circle(3rem);

        background-color: var(--c-grey-light);
    }

    &__name {
        display: none;
        visibility: hidden;
        width: 8rem;
        margin-left: 1rem;

        @include for-size(desktop-up) {
            display: block;
            visibility: visible;
        }
    }

    &__toggle-icon {
        height: 1.25rem;
        margin-left: 1.625rem;
        color: var(--c-grey-light);
    }
}
