@import "src/styles";

.contact-detail-list {
    color: currentColor;

    &__item {
        display: flex;
        align-items: center;
        list-style-type: none;

        & + & {
            margin-top: 1rem;
        }
    }

    &__icon {
        width: 1.5rem;
    }

    &__link {
        margin-left: 1rem;
        text-decoration: none;
        color: currentColor;

        @include for-size(tablet-portrait-up) {
            opacity: .7;
            transition: opacity .1s ease-out;

            &:hover,
            &:focus {
                opacity: 1;
            }
        }
    }
}
