@import "src/styles";

.message-overview {
    --message-overview-header-height: 6rem;

    &__header {
        display: flex;
        align-items: center;
    }

    &__title {
        font-size: 1.75rem;
        font-weight: var(--fw-bold);
        color: var(--c-text);
    }

    &__box {
        margin-top: 1.5rem;
        overflow: hidden;
        border: 1px solid var(--c-grey-light);
        background-color: var(--c-white);

        @include for-size(tablet-portrait-up) {
            overflow: initial;
        }
    }

    &__wrapper {
        display: flex;
        width: 200%;
        height: calc(100vh - var(--navigation-menu-height));
        max-height: 50rem;
        transform: translateX(0);
        transition: transform .3s ease;

        @include for-size(tablet-portrait-up) {
            width: 100%;
        }

        &--chat-is-open {
            transform: translateX(-50%);

            @include for-size(tablet-portrait-up) {
                transform: translateX(0);
            }
        }
    }

    &__chat-list {
        width: 50%;
        border-right: 1px solid var(--c-grey-light);

        @include for-size(tablet-portrait-up) {
            width: 22.5rem;
        }
    }

    &__chat-room {
        width: 50%;

        @include for-size(tablet-portrait-up) {
            width: 100%;
        }
    }
}
