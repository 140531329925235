@import "src/styles";

.company-header-cover {
    @include profile-placeholder-background;

    position: relative;
    height: 20rem;
    background-color: var(--c-grey-light);
    background-size: clamp(4rem, 10vw, 16rem);
    background-position: -50%;

    &--is-editable {

        &::after {
            @extend %click-extend;

            content: "";
            z-index: 10;
            pointer-events: none;
            background: linear-gradient(110deg, rgba(var(--c-black-rgb), .25), transparent 25%);
        }
    }

    &__controls-wrapper {
        position: absolute;
        top: 1rem;
        left: 2.5rem;
        z-index: 30;
    }

    &__control-button {
        @extend %button--control;

        box-shadow: var(--shadow-medium);

        & + & {
            margin-top: 1rem;
        }
    }

    &__control-play-icon {
        transform: translateX(.15rem);
    }

    &__controls-list {
        top: calc(100% + 1rem);
        width: 20rem;
    }

    &__cover-image-form {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top: none;
    }

    &__play-button-wrapper {
        @extend %flex-align-center;
        @extend %click-extend;

        z-index: 1;
    }

    &__play-button {
        @extend %button--control;
        @include circle(5rem);

        color: var(--c-primary);
        box-shadow: var(--shadow-medium);
    }

    &__play-icon {
        transform: scale(1.25) translateX(.15rem);
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__error-wrapper {
        position: absolute;
        bottom: 2.5rem;
        left: 2.5rem;
        right: 2.5rem;
        z-index: 20;
        padding: 1rem;
        border-radius: var(--border-radius);
        background-color: var(--c-negative);

        @include for-size(tablet-landscape-up) {
            right: initial;
        }
    }

    &__error-label {
        margin: 0;
        font-size: 1rem;
        font-weight: var(--fw-bold);
        color: var(--c-white);
    }
}
