@import "src/styles";

.registration-call-to-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 2rem 1.25rem 2rem;
    line-height: 1.5;
    color: var(--c-white);
    background-color: var(--c-black);
    background-image: var(--registration-call-to-action-background-image);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include for-size(tablet-portrait-up) {
        padding: 6.25rem 1.25rem 6.25rem;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 10;
        width: 100%;
    }

    &__title-wrapper {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    &__title,
    &__subtitle {
        font-weight: var(--fw-bold);
        text-align: center;
        color: currentColor;
    }

    &__title {
        margin: 1.25rem 0 0;
        font-size: 2rem;
        line-height: 1.2;
    }

    &__subtitle {
        margin: 0;
        font-size: 1rem;
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: .075em;
    }

    &__description {
        margin: 2rem 0 0;
        font-size: 1.125rem;
        text-align: center;
        color: currentColor;
    }

    &__button {
        margin: 2rem auto 0;

        @include for-size(tablet-portrait-up) {
            margin-top: 4rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }
}
