@import "button";
@import "forms";
@import "html";

%flex-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

%click-extend {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
}

%divider {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--c-grey-dark);

    &::before,
    &::after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: var(--c-grey-light);
    }
}

%skeleton-fade {
    position: relative;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            to right,
            rgba(var(--c-white-rgb), 0) 0%,
            rgba(var(--c-white-rgb), .5) 40%,
            rgba(var(--c-white-rgb), 0) 60%
        );
        animation: skeleton-load 2s ease infinite;
    }
}

%span-underline {

    &::before,
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: calc(1em / 6);
        border-radius: var(--border-radius);
        transform: translateY(calc(1em / 3));
    }

    &::before {
        z-index: 10;
        background: linear-gradient(90deg, var(--c-white) -25%, var(--c-secondary));
    }

    &::after {
        @include grain-background;

        z-index: 20;
    }
}

// TO BE REMOVED
%paragraph {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--c-grey-dark);
}
