@import "src/styles";

.blog-header-skeletons {
    position: relative;
    padding: 0 1rem 4rem;

    @include for-size(tablet-landscape-up) {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    &__poster {
        z-index: 20;
        width: 100%;
        max-width: var(--blog-detail-wrapper-width);
        height: 35vh;
        margin: 0 auto;
    }

    &__backdrop {
         position: absolute;
         bottom: 0;
         left: 0;
         z-index: 10;
         width: 100%;
         height: 12rem;
         background-color: var(--c-background);

         @include for-size(tablet-landscape-up) {
             height: 18.75rem;
         }
    }
}
