@import "src/styles";

.cookie-consent-toast {
    max-width: 38.75rem;
    padding-right: 2rem;

    &__body {
        margin-top: 1rem;
    }

    &__link {
        display: inline-block;
        margin-top: .5rem;
        color: var(--c-link);
    }

    &__list-and-button-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__accept-button {
        align-self: flex-start;
        margin-top: 1rem;
        color: var(--c-always-white);
        background-color: var(--c-always-black);

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: auto;
        }
    }
}
