.radio-list {
    display: block;

    &--has-error {
        padding-left: .5rem;
        border-left: 2px solid var(--c-negative);
    }

    &__label {
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    &__item {
        padding: .375rem 0;

        &:first-of-type {
            padding-top: 0;
        }

        &:last-of-type {
            padding-bottom: 0;
        }
    }

    &__error {
        margin-top: .5rem;
    }
}
