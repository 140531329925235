@import "src/styles";

.vacancy-card-meta {
    padding: 1rem 0;
    border-top: 1px solid var(--c-grey-light);

    @include for-size(tablet-landscape-up) {
        padding: 0 0 0 2rem;
        border-top: none;
        border-left: 1px solid var(--c-grey-light);
    }

    &__list {
        display: flex;
        justify-content: space-around;

        @include for-size(tablet-landscape-up) {
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;

            & + & {
                margin-top: 1rem;
            }
        }
    }

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(2.5rem);

        color: var(--c-white);
        background-color: var(--c-grey-light);

        &--applicants {
            background-color: #02C615;
        }

        &--views {
            background-color: #2F80ED;
        }

        &--favourites {
            background-color: #F27D11;
        }
    }

    &__icon {
        max-width: 1rem;
        max-height: 1rem;
    }

    &__text-wrapper {
        margin-top: .5rem;
        margin-left: 0;
        text-align: center;

        @include for-size(tablet-landscape-up) {
            margin-top: 0;
            margin-left: 1rem;
            text-align: left;
        }
    }

    &__title {
        display: none;
        font-size: 1rem;
        font-weight: var(--fw-semi-bold);
        color: var(--c-grey-dark);

        @include for-size(tablet-portrait-up) {
            display: block;
        }
    }

    &__value {
        font-size: 1rem;
        font-weight: var(--fw-bold);
        color: var(--c-primary);
    }
}
