@import "src/styles";

.confirm-email-address-change-page {

    &__wrapper {
        max-width: 90rem;
        margin: 0 auto;
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 0 2rem;
        }
    }

    &__card {
        max-width: 40rem;
    }
}
