.info-student-page {
    padding-bottom: 0;
    background-color: var(--c-white);

    &__benefits,
    &__testimonials,
    &__steps {
        margin-top: 4rem;
    }

    &__registration-call-to-action {
        margin-top: 6rem;
    }
}
