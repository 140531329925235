.carousel-bullet-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &__item {
        list-style-type: none;

        & + & {
            margin-left: .75rem;
        }
    }

    &__button {
        width: .75rem;
        height: .75rem;
        padding: 0;
        border-radius: 0;
        background-color: var(--c-grey-light);

        &--is-active {
            background-color: var(--c-secondary);
        }
    }
}
