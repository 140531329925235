@import "src/styles";

.vacancy-unarchive-modal {

    &__description {
        line-height: 1.5;
    }

    &__disclaimer {
        margin-top: 1.5rem;
    }

    &__button-list {
        margin-top: 2.5rem;
    }

    &__cancel-button {
        @extend %button--transparent-background;

        color: var(--c-text);
    }
}
