@import "src/styles";

.vacancy-applicants-matches-cta {
    padding: 1.5rem;
    color: var(--c-text);

    @include for-size(tablet-portrait-up) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 2.5rem;
    }

    &__text-wrapper {
        max-width: 52rem;
    }

    &__description {
        margin-top: 1.5rem;
    }

    &__button {
        margin-top: 1rem;
        text-align: center;
        white-space: nowrap;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 1.5rem;
        }
    }
}
