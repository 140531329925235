@import "src/styles";

.role-info-header {
    padding: 0 1rem;
    line-height: 1.5;

    @include for-size(tablet-landscape-up) {
        padding: 0 2rem;
    }

    &__wrapper {
        display: flex;
        flex-direction: column-reverse;
        max-width: 74rem;
        min-height: 32.5rem;
        margin: 0 auto;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
            min-height: 46vh;
        }
    }

    &__text-wrapper,
    &__image-wrapper {
        width: 100%;

        @include for-size(tablet-landscape-up) {
            width: 50%;
            margin-top: 0;
        }
    }

    &__text-wrapper {
        margin-top: 1.5rem;
    }

    &__image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        @include for-size(tablet-landscape-up) {
            justify-content: flex-end;
            padding-left: 1.5rem;
        }
    }

    &__title {
        max-width: 30rem;
        font-size: 2rem;
        color: var(--c-primary);
    }

    &__description {
        @extend %html-format;

        margin-top: 2rem;
        font-size: 1.125rem;
        color: var(--c-grey-dark);
    }

    &__button {
        margin-top: 3rem;
    }

    &__image {
        width: 100%;
        max-width: 28rem;
    }
}
