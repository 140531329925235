@import "src/styles";

.cookie-settings-page {
    position: relative;

    &__description,
    &__form {
        margin-top: 1rem;
    }

    &__link {
        display: inline-block;
        margin-top: .5rem;
        color: var(--c-link);
    }

    &__wrapper {
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 0 2rem;
        }
    }

    &__card {
        width: 100%;
        max-width: 45rem;
        margin: 0 auto;
        padding: 1.5rem;

        @include for-size(tablet-landscape-up) {
            padding: 2rem;
        }
    }

    &__save-button,
    &__save-text {
        margin-top: 1rem;
    }
}
