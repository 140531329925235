@import "src/styles";

.application-motivation-card {

    &__skeleton-wrapper {
        margin-top: 2rem;
    }

    &__skeleton {

        & + & {
            margin-top: 1rem;
        }
    }

    &__motivation {
        @extend %html-format;

        margin-top: 2rem;
    }

    &__files {
        margin-top: 2rem;
    }
}
