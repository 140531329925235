.talent-matching-card {
    padding: 2rem;
    line-height: 1.5;

    &__title {
        font-size: 1rem;
    }

    &__description {
        margin-top: 1rem;
    }

    &__wrapper {
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        border-top: 1px solid var(--c-grey-light);
    }

    &__button {

        & + & {
            margin-top: 1.5rem;
        }
    }
}
