@import "src/styles";

.candidate-card {
    position: relative;
    line-height: 1.5;

    &::after {
        @extend %click-extend;

        content: "";
        z-index: 10;
        background-color: rgba(var(--c-black-rgb), .1);
        box-shadow: var(--shadow-medium);
        transition: opacity .1s ease;
        opacity: 0;
    }

    &:hover::after,
    &:focus-within::after {
        opacity: .5;
    }

    &:hover &__card,
    &:focus-within &__card {
        transform: translateY(-.25rem);
    }

    &__card {
        display: flex;
        flex-direction: column;
        z-index: 20;
        height: 100%;
        padding: 1.5rem;
        transform: translateY(0);
        transition: transform .1s ease;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__avatar {
        @include square(4.5rem);

        @include for-size(tablet-landscape-up) {
            @include square(7.5rem);

            font-size: 1.75rem;
        }
    }

    &__text-wrapper {
        flex: 1;
        margin-top: 1.25rem;

        @include for-size(tablet-landscape-up) {
            position: relative;
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &__title-wrapper {
        display: flex;
        align-items: center;
    }

    &__link {
        text-decoration: none;

        &::after {
            @extend %click-extend;

            content: "";
            z-index: 30;
        }
    }

    &__full-name {
        font-size: 1.25rem;
        font-weight: var(--fw-bold);
        color: var(--c-primary);
    }

    &__interested-circle {
        @extend %flex-align-center;
        @include circle(1.5rem);

        margin-left: .625rem;
        background-color: var(--c-secondary);
    }

    &__interested-icon {
        height: .625rem;
    }

    &__personal-catch-phrase {
        margin-top: .25rem;
        margin-right: 2.5rem;
    }

    &__role-from-city {
        margin-top: .375rem;
        font-weight: var(--fw-medium);
        color: var(--c-grey-dark);
    }

    &__property-tags {
        margin-top: 1rem;
    }

    &__llo-candidate-label {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;

        @include for-size(tablet-landscape-up) {
            top: 0;
            right: 0;
        }
    }

    &__button-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin-top: 2rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-top: 0;
            margin-left: 2rem;
            padding-left: 2rem;
            border-left: 1px solid var(--c-grey-light);
        }
    }

    &__button {
        padding: .5rem 1rem;

        &--for-chat {
            @extend %button--outline;

            position: relative;
            z-index: 40;
        }
    }
}
