@import "src/styles";

$component: 'blog-list-item';

.#{$component} {
    display: flex;
    position: relative;
    list-style-type: none;

    &--is-featured {
        flex-direction: column;

        .#{$component}__thumbnail {
            width: 100%;
            height: initial;
            padding-bottom: calc(100% / 16 * 9);
        }

        .#{$component}__text-wrapper {
            margin-top: 1rem;
            margin-left: 0;
        }

        .#{$component}__meta {
            display: block;
        }

        .#{$component}__intro {

            @include for-size(tablet-landscape-up) {
                display: block;
            }
        }
    }

    @include for-size(tablet-landscape-up) {
        flex-direction: column;
    }

    &__thumbnail {
        position: relative;
        width: 25vw;
        min-width: 25vw;
        height: 20vw;
        background-size: cover;
        background-position: center;
        background-color: var(--c-grey-light);

        @include for-size(tablet-landscape-up) {
            width: 100%;
            min-width: initial;
            height: initial;
            padding-bottom: calc(100% / 16 * 9);
        }
    }

    &__thumbnail-alt {
        position: absolute;
        color: transparent;
    }

    &__text-wrapper {
        margin-left: 1rem;

        @include for-size(tablet-landscape-up) {
            margin-top: 1rem;
            margin-left: 0;
        }
    }

    &__categories {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;
    }

    &__category {
        list-style-type: none;
    }

    &__link {
        text-decoration: none;
        color: inherit;
    }

    &__title {
        display: inline-block;
        margin-top: .5rem;
        font-size: 1rem;
        font-weight: var(--fw-bold);
        line-height: 1.5;
        color: var(--c-primary);

        @include for-size(tablet-landscape-up) {
            font-size: 1.5rem;
        }

        &::after {
            @extend %click-extend;

            content: "";
        }
    }

    &__meta,
    &__intro {
        display: none;

        @include for-size(tablet-landscape-up) {
            display: block;
        }
    }

    &__meta {
        margin-top: .5rem;
        font-size: 1rem;
        font-style: italic;
        color: var(--c-grey);
    }

    &__intro {
        margin-top: 1.5rem;
        font-size: 1rem;
        line-height: 1.5;
    }
}
