@import "src/styles";

.blog-list-skeletons {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 2rem;
    row-gap: 3.5rem;

    @include for-size(tablet-landscape-up) {
        grid-template-columns: repeat(2, 1fr);
    }

    &__item {
        display: flex;
        list-style-type: none;

        @include for-size(tablet-landscape-up) {
            flex-direction: column;
        }
    }

    &__thumbnail {
        width: 25vw;
        min-width: 25vw;
        height: 20vw;

        @include for-size(tablet-landscape-up) {
            width: 100%;
            min-width: initial;
            height: initial;
            padding-bottom: calc(100% / 16 * 9);
        }
    }

    &__text-wrapper {
        width: 100%;
        margin-left: 1rem;

        @include for-size(tablet-landscape-up) {
            margin-top: 1rem;
            margin-left: 0;
        }
    }

    &__title {

        @include for-size(tablet-landscape-up) {
            height: 1.5rem;
        }

        & + & {
            margin-top: 1rem;
        }
    }

    &__text {
        display: none;
        margin-top: 1.5rem;

        @include for-size(tablet-landscape-up) {
            display: block;
        }

        & + & {
            margin-top: .5rem;
        }
    }
}
