%form-subtitle {
    margin: 0 0 .875rem;
    line-height: 1.2;
    font-size: 1.125rem;
    font-weight: 800;
    color: var(--c-primary);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

%form-row {
    display: flex;
    width: 100%;
    margin-bottom: 1.5rem;

    > label {
        width: 100%;
    }
}
