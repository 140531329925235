@import "src/styles";

.application-detail-page {
    position: relative;

    &__wrapper {
        width: 100%;
        max-width: 90rem;
        margin: 0 auto;
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 0 2rem;
        }
    }

    &__main {
        width: 100%;
    }

    &__sidebar {
        width: 100%;
        margin-top: 2rem;

        @include for-size(tablet-landscape-up) {
            max-width: 24rem;
            margin-top: 0;
            margin-left: 2rem;
        }
    }

    &__card {

        & + & {
            margin-top: 2rem;
        }
    }
}
