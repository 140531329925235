@import "src/styles";

.vacancy-description-card {

    &__skeleton-wrapper {
        margin-top: 2rem;
    }

    &__skeleton {

        & + & {
            margin-top: 1rem;
        }
    }

    &__description {
        @extend %html-format;

        margin-top: 2rem;
    }

    &__internship-period-wrapper {
        margin-top: 2rem;
        color: var(--c-grey-dark);
    }

    &__internship-period-title {
        font-size: 1rem;
        font-weight: var(--fw-bold);
    }

    &__internship-period-range {
        margin-top: .5rem;
    }
}
