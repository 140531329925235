.new-select {
    font-size: 1rem;
    cursor: pointer;

    &--is-disabled {
        cursor: not-allowed !important;
        opacity: .5;
    }

    &--has-error {
        border: 2px solid var(--c-negative);
    }

    &__control {
        padding: .375rem 0 !important;
        color: var(--c-primary) !important;
        border-radius: 0 !important;
        border-color: var(--c-grey-light) !important;
        background-color: var(--c-white) !important;
        box-shadow: var(--shadow-medium);
    }

    &__input {
        padding: .5rem 0;
    }

    &__indicator-separator {
        background-color: var(--c-grey-light) !important;
    }

    &__single-value {
        color: var(--c-primary) !important;
    }

    &__menu {
        background-color: var(--c-white) !important;
    }

    &__menu-list {
        max-height: 14rem !important;
    }

    &__option {
        padding: .75rem 1rem !important;
        color: var(--c-primary) !important;

        &--is-focused {
            color: var(--c-white) !important;
            background-color: var(--c-grey) !important;
        }

        &--is-selected {
            color: var(--c-white) !important;
            background-color: var(--c-primary) !important;
        }
    }
}
