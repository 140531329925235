@import "src/styles";

.current-study-card {
    padding: 2rem 2.5rem;
    line-height: 1.5;

    &__skeleton {

        & + & {
            margin-top: 1rem;
        }
    }

    &__tag {
        font-weight: var(--fw-semi-bold);
    }

    &__wrapper {
        margin-top: 1.5rem;
    }

    &__field-of-study {
        font-size: 1.25rem;
        font-weight: var(--fw-bold);
    }

    &__school,
    &__period {
        font-weight: var(--fw-medium);
        color: var(--c-grey-dark);
    }

    &__school {
        margin-top: .25rem;
        font-size: 1.125rem;
    }

    &__period {
        margin-top: 1rem;
        font-size: 1rem;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__edit-button {
        @extend %button--control;

        position: absolute;
        top: 1.25rem;
        right: 1.75rem;

        &:hover,
        &:focus {
            color: var(--c-primary);
            background-color: var(--c-grey-light);
        }
    }
}
