@import "src/styles";

.job-experience-item {
    display: flex;
    list-style-type: none;
    font-size: 1rem;
    line-height: 1.5;

    &__content-wrapper {
        flex: 1;
    }

    &__controls-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: auto;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__position {
        font-size: 1rem;
        font-weight: var(--fw-bold);
        color: var(--c-primary);
    }

    &__company {
        margin-left: .5rem;
        font-weight: var(--fw-medium);
        text-transform: none;
        color: var(--c-grey-dark);
    }

    &__dates {
        margin-top: .5rem;
        font-size: .875rem;
        color: var(--c-grey-dark);
    }

    &__description {
        margin-top: 1rem;
        color: var(--c-grey-dark);
    }

    &__control-button {
        @extend %button--control;

        & + & {
            margin-top: .5rem;

            @include for-size(tablet-portrait-up) {
                margin-top: 0;
                margin-left: .5rem;
            }
        }
    }
}
