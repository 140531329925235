@import "src/styles";

.internship-vacancy-data-step {
    line-height: 1.5;

    &__card {

        & + & {
            margin-top: 1.5rem;
        }

        @include for-size(tablet-portrait-up) {
            padding: 2.5rem 3rem;
        }
    }

    &__sub-title {
        margin-bottom: 2.5rem;
        font-weight: var(--fw-bold);
        color: var(--c-primary);
    }

    &__form-elements {
        margin-top: 2rem;
    }

    &__form-input {
        display: block;
        margin-bottom: 1.25rem;
    }

    &__form-row {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.25rem;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
        }
    }

    &__education-levels {
        width: 100%;

        @include for-size(tablet-portrait-up) {
            width: 49%;
        }
    }

    &__apply-anonymous {
        margin-top: 1.5rem;
    }

    &__apply-anonymous-disclaimer {
        margin-top: 1rem;
    }

    &__form-col {
        width: 100%;
        margin-bottom: 1rem;

        @include for-size(tablet-portrait-up) {
            &:first-child {
                margin-right: .625rem;
            }
        }

        @include for-size(tablet-portrait-up) {
            &:last-child {
                margin-left: .625rem;
            }
        }
    }
}
