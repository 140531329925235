.table-cell {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    color: var(--c-grey-dark);
    word-break: break-word;
    background-color: inherit;

    &--align-left {
        justify-content: flex-start;
        text-align: left;
    }

    &--align-center {
        justify-content: center;
        text-align: center;
    }

    &--align-right {
        justify-content: flex-end;
        text-align: right;
    }
}
