@import "src/styles";

.application-status-invited {

    &__description {
        margin-top: 1rem;
    }

    &__message {
        margin-top: 1rem;
        padding: 1rem;
        background-color: var(--c-grey-light);
    }

    &__no-message {
        font-style: italic;
    }

    &__appointment-description {
        margin-top: 1.5rem;
    }

    &__appointment-input {
        margin-top: 1rem;
    }

    &__appointment-type-title {
        font-size: 1rem;
        margin-top: 1.5rem;
    }

    &__appointment-type-description {
        margin-top: .5rem;
    }

    &__button-wrapper {
        margin-top: 1.5rem;
    }

    &__button {
        @extend %button--transparent-background;

        margin-top: 1rem;
        color: var(--c-text);
    }

    &__or-divider {
        @extend %divider;

        margin-top: 1rem;
    }
}
