@import "src/styles";

.testimonial-card {
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-bottom: 2rem;

    &__header {
        position: relative;
        height: 16rem;
        background-image: var(--testimonial-card-poster);
        background-size: cover;
        background-position: center;
    }

    &__header-content {
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 2rem;
        transform: translateY(50%);
    }

    &__logo-wrapper {
        @extend %flex-align-center;
        @include circle(4.5rem);

        overflow: hidden;
    }

    &__logo {
        width: 100%;
    }

    &__poster {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__text-wrapper {
        margin-top: 3.5rem;
        padding: 0 2rem;
    }

    &__quote {
        font-size: 1.25rem;
        font-weight: var(--fw-regular);
        color: var(--c-grey-dark);
    }

    &__author {
        margin-top: 1.25rem;
        font-size: 1.125rem;
        font-weight: var(--fw-bold);
        color: var(--c-primary);
    }
}
