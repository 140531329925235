@import "src/styles";

.step-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include for-size(tablet-portrait-up) {
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    &__back-button {
        @extend %button--transparent-background;

        margin-top: 1rem;
        color: var(--c-primary);

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
        }
    }
}
