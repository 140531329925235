@import "src/styles";

.file-drop-zone {
    position: relative;

    &__drop-indicator {
        display: block;
        width: 100%;
        padding: 1.5rem;
        border: 2px dashed var(--c-grey-light);

        &--is-entering,
        &--is-hovering {
            border-color: var(--c-primary);
        }
    }

    &__file-list {
        margin-bottom: 1.5rem;
    }

    &__input {
        position: absolute;
        opacity: 0;
    }

    &__add-button {
        @extend %button--add;
    }

    &__restriction {
        margin-top: 1rem;
        font-size: .875rem;
        text-align: right;
        color: var(--c-grey-dark);

        & + & {
            margin-top: .5rem;
        }
    }

    &__error-label {
        margin-top: 1rem;
    }
}
