@import "src/styles";

.blog-breadcrumbs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: var(--fw-bold);
    font-size: .875rem;
    text-transform: uppercase;
    line-height: 1.5;
    letter-spacing: .075em;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
        align-items: center;
    }

    &__back-button {
        @extend %button--no-background;

        padding: 0;
        font-size: inherit;
        font-weight: var(--fw-bold);
        color: var(--c-primary);
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;

        @include for-size(tablet-portrait-up) {
            margin-top: 0;
            margin-left: 1.5rem;
        }
    }

    &__item {
        list-style-type: none;
        margin-right: .5rem;
        color: var(--c-grey-dark);

        &::after {
            content: "/";
            margin-left: .5rem;
        }

        &:last-of-type {
            margin-right: 0;

            &::after {
                content: "";
            }
        }
    }

    &__link {
        font-weight: var(--fw-bold);
        color: var(--c-primary);
        text-decoration: none;
    }
}
