@import "src/styles";

.candidate-video-card {

    &__skeleton {
        margin-top: 2rem;
    }

    &__null-state {
        margin-top: 2rem;
    }

    &__null-state-button {
        @extend %button--add;

        margin-top: 2rem;
    }

    &__play-button {
        margin-top: 2rem;
    }
}
