.searchable-multi-value-input {

    &__label {
        margin-bottom: .5rem;
    }

    &__result-list {
        display: flex;
        flex-wrap: wrap;
        gap: var(--flex-tag-gap);
        padding: 1rem;
        margin: 0;
        border: 1px solid var(--c-grey-light);
        border-bottom: none;
        border-radius: var(--border-radius) var(--border-radius) 0 0;
        background-color: var(--c-background);
    }

    &__result-item {
        list-style-type: none;
    }
}
