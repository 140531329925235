.block-radio-list {

    &__label {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__items {
        display: flex;
        flex-direction: row;

        &--error {
            border: 2px solid var(--c-negative);
        }
    }
}
