@import "src/styles";

.skeleton {
    @extend %skeleton-fade;

    --skeleton-width: 100%;

    display: block;
    width: var(--skeleton-width);
    height: 1em;
    background-color: var(--c-grey-light);
}
