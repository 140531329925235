@import "src/styles";

.profile-controls {
    position: relative;

    &__toggle-button {
        @extend %button--no-background;

        display: flex;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        color: currentColor;
    }

    &__avatar {
        @include circle(3rem);

        font-size: .875rem;
    }

    &__name {
        display: none;
        visibility: hidden;
        max-width: 16rem;
        margin-left: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include for-size(desktop-up) {
            display: block;
            visibility: visible;
        }
    }

    &__toggle-button {
        padding: 0;
    }

    &__toggle-icon {
        height: 1.25rem;
        margin-left: 1.625rem;
    }

    &__expandable-nav {
        display: none;
        visibility: hidden;
        top: var(--navigation-menu-height);
        right: 0;

        @include for-size(tablet-landscape-up) {
            display: block;
            visibility: visible;
        }
    }
}
