.vacancy-recruitment-card {
    line-height: 1.5;

    &__description {
        margin-top: 1.5rem;
    }

    &__button-wrapper {
        margin-top: 1.5rem;
    }

    &__button {

        & + & {
            margin-top: 1rem;
        }
    }
}
