@import "src/styles";

.progress-step-indicator {
    display: flex;
    height: .625rem;

    &__step {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: var(--border-radius);
        background-color: var(--c-grey-light);

        &::after {
            @extend %click-extend;

            content: "";
            background-color: var(--c-progress-theme);
            transform: translateX(calc(-100% - .1rem));
        }

        &--is-finished::after {
            transform: translateX(0);
        }

        &--just-finished::after {
            animation: fill-progress 1s ease forwards;
        }

        & + & {
            margin-left: .5rem;
        }
    }
}
