.carousel-arrow-controls {
    display: flex;
    margin: 0;
    padding: 0;

    &__item {
        list-style-type: none;

        & + & {
            margin-left: .5rem;
        }
    }

    &__button {
        padding: 1rem;
    }
}
