@import "src/styles";

.blog-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 2rem;
    row-gap: 3.5rem;

    @include for-size(tablet-landscape-up) {
        grid-template-columns: repeat(3, 1fr);
    }
}
