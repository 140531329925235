.textarea-input {
    width: 100%;
    min-height: 6rem;
    resize: vertical;
    padding: 1rem;
    font: inherit;
    line-height: 1.5;
    border: 1px solid var(--c-grey-light);
    box-shadow: var(--shadow-medium);
    transition: border .1s ease-out;

    &--has-error {
        border: 2px solid var(--c-negative);
    }
}
