@import "src/styles";

.work-remote-preference {
    position: relative;
    color: var(--c-grey-dark);

    &__title {
        font-size: 1rem;
        color: var(--c-grey-dark);
    }

    &__skeletons {
        margin-top: 1rem;
    }

    &__null-state {
        margin-top: .5rem;
    }

    &__null-state-button {
        @extend %button--add;

        margin-top: 1rem;
    }

    &__preference {
        margin-top: .5rem;
    }

    &__edit-button {
        @extend %button--control;

        position: absolute;
        top: -.5rem;
        right: -.5rem;
    }
}
