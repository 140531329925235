@import "src/styles";

.progress-call-to-action {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border: 1px solid var(--c-grey-light);

    @include for-size(tablet-landscape-up) {
        flex-direction: row;
        align-items: center;
        padding: 1.5rem 2.5rem;
    }

    &__emoticon {
        margin: 0 auto;

        @include for-size(tablet-landscape-up) {
            margin: 0;
        }
    }

    &__text-wrapper {
        margin-top: 2rem;
        line-height: 1.5;

        @include for-size(tablet-landscape-up) {
            margin-top: 0;
            margin-right: 2rem;
            margin-left: 3rem;
        }
    }

    &__title {
        font-size: 1rem;
        font-weight: var(--fw-bold);
    }

    &__description {
        margin-top: .5rem;
        font-weight: var(--fw-regular);
        color: var(--c-grey-dark);
    }

    &__button {
        margin-top: 2rem;
        white-space: nowrap;

        @include for-size(tablet-landscape-up) {
            margin-top: 0;
            margin-left: auto;
        }
    }
}
