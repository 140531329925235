@import "src/styles";

.application-detail-archived {
    line-height: 1.5;

    &__wrapper {
        max-width: 48rem;
    }

    &__title {
        font-size: 2.5rem;
    }

    &__description {
        margin-top: 1.5rem;
        font-size: 1.125rem;
        font-weight: var(--fw-medium);
        color: var(--c-grey-dark);
    }

    &__motivation-card {
        margin-top: 2rem;
    }

    &__button-list {
        margin-top: 2.5rem;
        padding: 1.5rem;
        background-color: var(--c-white);
    }

    &__button {

        &--for-chat {
            @extend %button--outline;
        }
    }
}
