@import "src/styles";

.sub-menu {
    display: flex;
    align-items: center;
    overflow-x: auto;
    background-color: var(--c-white);

    &__list {
        display: flex;
        gap: 1.5rem;
        width: 100%;
        max-width: 90rem;
        margin: 0 auto;
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            gap: 2.5rem;
            padding: 0 2rem;
        }
    }

    &__item {
        list-style-type: none;
        white-space: nowrap;
    }
}
