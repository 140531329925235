@import "src/styles";

$component: tag;

.#{$component} {
    display: inline-flex;
    align-items: center;
    gap: .5rem;
    padding: .5rem 1rem;
    font-size: .875rem;
    font-weight: var(--fw-medium);
    color: var(--c-grey-dark);
    border-radius: 2rem;
    border: 1px solid var(--c-grey-light);

    &--is-interactive {
        font-size: 1rem;
        font-weight: var(--fw-semi-bold);
        color: var(--c-white);
        background-color: var(--c-grey-dark);

        &:enabled:hover,
        &:enabled:focus {

            .#{$component}__icon-wrapper {
                background-color: rgba(var(--c-always-white-rgb), .5);
            }
        }

        .#{$component}__icon {
            height: .75rem;
        }
    }

    &--is-active {
        color: var(--c-primary);
        border-color: var(--c-secondary);
        background-color: var(--c-secondary);
    }

    &--is-positive {
        color: var(--c-always-black);
        border-color: var(--c-positive);
        background-color: var(--c-positive);
    }

    &--is-warning {
        color: var(--c-always-black);
        border-color: var(--c-warning);
        background-color: var(--c-warning);
    }

    &--is-negative {
        color: var(--c-always-black);
        border-color: var(--c-negative);
        background-color: var(--c-negative);
    }

    &__icon-wrapper {
        @extend %flex-align-center;
        @include circle(1.5rem);

        padding: .25rem;
    }

    &__icon {
        width: 1rem;
    }
}
