@import "src/styles";

.fixed-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    background-color: var(--c-white);
    box-shadow: var(--shadow-medium-up);

    @include for-size(tablet-landscape-up) {
        padding: 1rem 2rem;
    }
}
