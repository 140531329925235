@import "src/styles";

.company-usps {
    position: relative;
    padding: 0 1rem;

    @include for-size(tablet-landscape-up) {
        padding: 0 2rem;
    }

    &__wrapper {
        position: relative;
        z-index: 2;
        max-width: 74rem;
        margin: 0 auto;
        padding: 2rem 0;
    }

    &__title {
        font-size: 2rem;
        font-weight: var(--fw-bold);
        text-align: center;
        color: var(--c-primary);
    }

    &__usp-list {
        display: flex;
        flex-direction: column;
        margin-top: 3rem;

        @include for-size(tablet-landscape-up) {
            flex-direction: row;
        }
    }

    &__usp-item {
        width: 100%;
        list-style-type: none;
        color: var(--c-grey-dark);

        & + & {
            margin-top: 2rem;

            @include for-size(tablet-landscape-up) {
                margin-top: 0;
                margin-left: 2rem;
            }
        }
    }

    &__usp-card {
        height: 100%;
    }

    &__backdrop {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 10rem;
        background-color: var(--c-background);
    }
}
