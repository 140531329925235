.vacancy-index-sector {

    &__sector-list {
        list-style-type: none;
    }

    &__sector {
        color: var(--c-grey-dark);
        line-height: var(--vacancy-index-line-height);
    }
}
