.step-header {

    &__step-indicator {
        font-size: .875rem;
        font-weight: var(--fw-bold);
        text-transform: uppercase;
        color: var(--c-grey-dark);
    }

    &__title {
        margin-top: .625rem;
        font-size: 1.875rem;
        line-height: 2rem;
        color: var(--c-primary);
    }
}
