// TODO: refactor this file! Remove all random color codes and 'px' values
$label-top: 1.375rem;
$label-left: 2rem;

.company-kvk-radio-input {
    position: relative;

    &__input {
        position: absolute;
        left: -9999px;

        & + label {
            position: relative;
            padding: 1.0625rem 1.5rem 1.3125rem 4.125rem;
            cursor: pointer;
            line-height: 20px;
            display: inline-block;
            color: #666;
        }

        & + label:before {
            content: '';
            position: absolute;
            top: $label-top;
            left: $label-left;
            width: 1.125rem;
            height: 1.125rem;
            border: 1px solid #A7A7A7;
            border-radius: 100%;
            background: #fff;
        }

        & + label:after {
            content: '';
            width: 0.625rem;
            height: 0.625rem;
            background: var(--c-black);
            position: absolute;
            top: calc(#{$label-top} + 0.3125rem);
            left: calc(#{$label-left} + 0.3125rem);
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }

        &:not(:checked) + label:after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
        }

        &:checked + label:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    &__label {
        width: 100%;
        font-size: 0.9375rem;

        &:hover {
            background: var(--c-background);
        }
    }

    &__title {
        display: flex;
        flex-wrap: wrap;
        font-weight: var(--fw-semi-bold);
        line-height: 1.5;
        color: var(--c-primary);

        & > span {
            display: inline-flex;
            align-items: center;
            margin: 0 .125rem;
        }
    }

    &__is-main-branch {
        padding: 0 0.375rem;
        height: 1.875rem;
        background: var(--c-grey-light);
    }

    &__properties {
        display: flex;
        flex-wrap: wrap;
        margin-top: .5rem;
        line-height: 1.5rem;
        color: var(--c-grey-dark);
    }

    &__property {
        margin: 0 .125rem;
    }

    &:not(:last-of-type):after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 2rem;
        width: calc(100% - 4rem);
        height: 1px;
        background: var(--c-grey-light);
    }
}
