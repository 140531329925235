@import "src/styles";

.application-company-information-skeletons {

    &__company-wrapper {
        display: flex;
        align-items: center;
    }

    &__company-logo {
        @include square(4.5rem);

        background-color: var(--c-grey-light);
    }

    &__company-text-wrapper {
        width: 100%;
        margin-left: 1rem;
    }

    &__company-name {
        height: 1.25rem;
    }

    &__company-city {
        margin-top: .5rem;
    }

    &__employee-description {
        margin-top: 1.5rem;
        padding-top: 1rem;
        border-top: 1px solid var(--c-grey-light);
    }

    &__employee-line {

        & + & {
            margin-top: .5rem;
        }
    }
}
