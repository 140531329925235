@import "src/styles";

.vacancy-complete-registration-card {

    &__description {
        margin-top: 2rem;
        line-height: 1.5;
        color: var(--c-grey-dark);
    }

    &__button,
    &__fixed-footer-button {
        @extend %button--negative;

        margin-top: 2rem;
    }

    &__fixed-footer-button {
        width: 100%;
        text-align: center;
        margin: 0;
    }
}
