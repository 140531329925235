.input-label {
    display: flex;
    margin-bottom: .25rem;
    font-weight: var(--fw-bold);
    color: var(--c-grey-dark);

    &__required {
        color: var(--c-negative);
    }

    &__optional {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        font-size: .75rem;
        font-weight: var(--fw-regular);
        color: var(--c-grey);
    }
}
