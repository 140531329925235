@import "src/styles";

.candidate-avatar-card-skeletons {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include for-size(tablet-portrait-up) {
        flex-direction: row;
    }

    &__avatar {
        @include circle(6rem);

        background-color: var(--c-grey-light);

        @include for-size(tablet-portrait-up) {
            @include circle(12rem);
        }
    }

    &__personal-info-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 2rem;

        @include for-size(tablet-portrait-up) {
            align-items: flex-start;
            margin-top: 0;
            margin-left: 3.5rem;
        }
    }

    &__name {
        height: 1.25rem;
    }

    &__city {
        margin-top: 1rem;
    }
}
