@import "src/styles";

.application-overview-skeletons {

    &__item {
        list-style-type: none;

        & + & {
            margin-top: 1rem;
        }
    }

    &__card {
        display: flex;
        padding: 1.5rem;
    }

    &__logo {
        @include square(4.5rem);
    }

    &__text-wrapper {
        width: 80%;
        margin-left: 1rem;
    }

    &__title {
        height: 1rem;
    }

    &__at-company {
        max-width: 14rem;
        height: .875rem;
        margin-top: .5rem;
    }

    &__motivation {
        max-width: 40rem;
        margin-top: 1.5rem;

        &-line {

            & + & {
                margin-top: .5rem;
            }
        }
    }

    &__status-wrapper {
        display: none;

        @include for-size(tablet-portrait-up) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 20rem;
            margin-top: 0;
            margin-left: 2rem;
            padding-left: 2rem;
            border-left: 1px solid var(--c-grey-light);
        }
    }
}
