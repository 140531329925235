@import "src/styles";

.menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    width: 100%;
    color: var(--c-text);
    border-bottom: 1px solid var(--c-grey-light);
    background-color: var(--c-white);

    &__wrapper {
        display: flex;
        align-items: center;
        height: var(--navigation-menu-height);
        padding: 0 1rem;

        @include for-size(tablet-landscape-up) {
            padding: 0 2rem;
        }
    }

    &__logo-wrapper {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: currentColor;
    }

    &__mobile-navigation {
        margin-left: 1rem;
    }

    &__desktop-navigation {
        margin-left: 1rem;

        @include for-size(desktop-up) {
            margin-left: 8rem;
        }
    }
}
