@import "src/styles";

.sbb-info-card {
    padding: 2rem 2.5rem;

    &__top-section {
        display: flex;
        flex-direction: column;

        @include for-size(tablet-portrait-up) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__text-wrapper {
        max-width: 36rem;
    }

    &__title {
        margin-bottom: 2rem;
        font-size: 1.1875rem;
        font-weight: var(--fw-bold);
        line-height: 1.5;
    }

    &__description {
        @extend %paragraph;

        margin-bottom: 1rem;
        color: var(--c-grey-dark);

        @include for-size(tablet-portrait-up) {
            margin-bottom: 4rem;
        }
    }

    &__update-button {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin-bottom: 1rem;

        @include for-size(tablet-portrait-up) {
            width: auto;
            min-width: 13.5rem;
            margin-left: 1rem;
        }
    }

    &__list {
        list-style: none;
    }

    &__list-item {
        display: flex;
        margin-bottom: 1rem;
    }

    &__list-item-label,
    &__list-item-value {
        line-height: 1.5;
        color: var(--c-grey-dark);
        word-break: break-word;
    }

    &__list-item-label {
        width: 33%;
        min-width: 5rem;
        max-width: 12.5rem;
        margin-right: 2rem;
        font-weight: var(--fw-bold);

        @include for-size(phone-only) {
            width: 50%;
            margin-right: 1rem;
        }
    }

    &__list-item-value {
        width: calc((100% / 3) * 2);

        @include for-size(phone-only) {
            width: 50%;
        }
    }

    &--user-is-company &__title {
        margin-bottom: 1rem;
    }
}
