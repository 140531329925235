@import "src/styles";

$component: 'chat-event-message';

.#{$component} {
    --c-chat-message: var(--c-grey-light);

    display: flex;
    gap: 1rem;
    margin-bottom: .5rem;

    &:last-of-type {
        margin-bottom: 0;
    }

    &--is-first-of-group &__avatar {
        opacity: 1;
    }

    &--is-last-of-group {
        margin-bottom: 1.5rem;

        .#{$component}__date {
            display: block;
        }
    }

    &--is-author {
        --c-chat-message: rgba(var(--c-secondary-rgb), .2);

        flex-direction: row-reverse;

        .#{$component}__chat-balloon-wrapper {
            align-items: flex-end;
        }
    }

    &__avatar {
        @include circle(2.5rem);

        font-size: .875rem;
        opacity: 0;
    }

    &__chat-balloon-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 36rem;
    }

    &__chat-balloon {
        display: inline-flex;
        padding: 1rem;
        color: var(--c-primary);
        background-color: var(--c-chat-message);
    }

    &__date {
        display: none;
        margin-top: .5rem;
        font-size: .875rem;
        color: var(--c-grey);
    }
}
