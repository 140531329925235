@import "src/styles";

.company-about-card {
    padding-bottom: 2.5rem;

    &__skeleton-wrapper {
        margin-top: 2rem;
    }

    &__skeleton {

        & + & {
            margin-top: 1rem;
        }
    }

    &__null-state {
        margin-top: 2rem;
    }

    &__null-state-button {
        @extend %button--add;

        margin-top: 2rem;
    }

    &__description {
        @extend %html-format;

        max-width: 48rem;
        margin-top: 2rem;
    }
}
