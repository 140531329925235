@import "src/styles";

.favourite-item {
    display: flex;
    align-items: center;
    list-style-type: none;
    border-bottom: 1px solid var(--c-grey-light);

    &:hover,
    &:focus-within {
        background-color: var(--c-background);
    }

    @include for-size(tablet-landscape-up) {
        padding-right: 1rem;
    }

    &__content-wrapper {
        display: flex;
        position: relative;
        width: 100%;
        padding: 1rem;
        padding-right: 0;

        @include for-size(tablet-landscape-up) {
            padding-left: 2rem;
        }
    }

    &__logo {
        width: 3rem;
        height: 3rem;
        font-size: .875rem;

        @include for-size(tablet-landscape-up) {
            width: 4.75rem;
            height: 4.75rem;
            font-size: 1.125rem;
        }
    }

    &__text-wrapper {
        flex: 1;
        margin-left: 1rem;
        word-break: break-word;
    }

    &__link {
        text-decoration: none;
        color: currentColor;

        &::after {
            @extend %click-extend;

            content: "";
        }
    }

    &__title {
        font-size: 1rem;
        line-height: 1.5;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__company {
        margin-top: .25rem;
        font-size: .875rem;
        font-weight: var(--fw-bold);
        color: var(--c-grey);
    }

    &__remove-button {
        @extend %button--no-background;

        margin-left: auto;
        padding: 1rem;
        color: var(--c-favourite);
        transition: opacity .1s ease;

        &:enabled:hover,
        &:enabled:focus {
            opacity: .75;
        }

        &:disabled,
        &--is-loading {
            color: var(--c-favourite);
            opacity: .5;
        }
    }

    &__remove-icon {
        height: 1.5rem;
    }
}
