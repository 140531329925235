@import "src/styles";

.llo-button {
    @extend %button--outline;

    position: relative;
    width: 100%;

    &__icon {
        position: absolute;
        top: 50%;
        left: 0;
        width: 3.125rem;
        padding: 0 .875rem;
        border-right: 1px solid var(--c-grey);
        transform: translateY(-50%);
    }
}
