@import "src/styles";

.submit-button {
    @extend %button;

    display: flex;
    align-items: center;
    gap: .625rem;

    &__icon {
        display: block;
        min-width: 1em;
        max-width: 1em;
        min-height: 1em;
        max-height: 1em;
        color: currentColor;
    }
}
